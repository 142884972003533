import React from "react";
import { Link } from "react-router-dom";
import IMG1 from "../../assets/images/img2-1.jpg";
import IMG2 from "../../assets/images/img2-2.png";
import IMG4 from "../../assets/images/img2-4.jpg";
import IMG5 from "../../assets/images/img2-5.jpg";
import IMG6 from "../../assets/images/img2-6.jpg";
import IMG7 from "../../assets/images/img2-7.jpg";

const Feature = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row g-0">
          <div className="col-md-4 col-12 m-0 p-0 d-flex align-items-center">
            <div>
              <div className="fw-bold d-flex align-items-center gap-2 p-0 m-0">
                01 <div className="feature_hr_line"></div>
              </div>
              <h1 className="feature_h1 text-uppercase m-0 p-0">UNHEARD OF</h1>
              <h2 className="feature_h2 text-uppercase">BOLD OPPURTUNITIES</h2>
            </div>
          </div>
          <div className="col-md-5 col-12 m-0 p-0">
            <Link
              to="/guaranteecompany"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG1}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    Institute of Billionaires Guarantee Ltd.
                  </h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-3 col-12 m-0 p-0">
            <Link
              to="/unicorn-stock-exchange"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG2}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    Unicorn Stock Exchange
                  </h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-4 col-12 m-0 p-0">
            <Link
              to="/institute-of-public-companies"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG4}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    Institute of Public Companies
                  </h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-4 col-12 m-0 p-0">
            <Link
              to="/public-companies-command-office"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG5}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    Public Companies Command Office
                  </h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-4 col-12 m-0 p-0">
            <Link
              to="/unicorn-united"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG6}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    UNICORNS UNITED
                  </h3>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-5 col-12 m-0 p-0">
            <Link
              to="/venus-syndicate"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG7}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    The Venus Syndicate
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-5 col-12 m-0 p-0">
            <Link
              to="/freelancer-visa-training-program"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="position-relative h-100">
                <img
                  src={IMG4}
                  alt="featureimage"
                  className="img-fluid w-100 feature_images"
                />
                <div className="overlay-text position-absolute bottom-0 start-0 w-100">
                  <h3 className="feature_hover m-0 p-2 text-uppercase feature_box_h3">
                    Nomad Billionaire
                  </h3>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-2 col-12 m-0 p-0 d-flex align-items-center justify-content-center"></div>
        </div>
      </div>
    </>
  );
};

export default Feature;
