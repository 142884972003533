import React from "react";
import Accordion from "../../../constant/accordion";
import IMG from "../../../assets/images/OPRAH_IMG.jpg";

const OprahWinfrey = () => {
  const accordionData = [
    {
      question:
        "What is the focus of the Oprah Winfrey School of Media & Public Relations?",
      answer:
        "The school focuses on teaching future billionaires how to effectively manage their public image, leverage media platforms, and build a positive public reputation.",
    },
    {
      question: "Why is the school named after Oprah Winfrey?",
      answer:
        "Oprah Winfrey is one of the most influential media personalities in history. Her ability to shape public perception and build an authentic connection with audiences makes her the perfect figure to inspire students in mastering media and public relations.",
    },
    {
      question: "What will students learn at the Oprah Winfrey School?",
      answer:
        "Students will learn media training, crisis management, brand building, and strategic communications, along with the skills to manage their public image and leverage media for influence.",
    },
    {
      question:
        "How does the school fit into the broader curriculum of the Institute of Billionaires?",
      answer:
        "The school complements the Institute’s mission by teaching future billionaires how to manage the critical aspect of public relations, which is essential for leadership in the global arena.",
    },
    {
      question: "What is strategic communications?",
      answer:
        "Strategic communications involves planning and executing communication efforts to achieve specific objectives, including shaping public perception, managing media relations, and controlling narratives.",
    },
    {
      question: "Will students learn how to handle public relations crises?",
      answer:
        "Yes, crisis management is a key part of the curriculum, where students will learn how to handle negative publicity and maintain their public image during challenging times.",
    },
    {
      question: "What role does media play in a billionaire’s success?",
      answer:
        "Media plays a crucial role in shaping public perception, building trust, and establishing a leader’s brand. Mastery of media helps billionaires maintain a positive image and expand their influence.",
    },
    {
      question:
        "Who will teach at the Oprah Winfrey School of Media & Public Relations?",
      answer:
        "The school will feature experts in media, public relations, and communications, including seasoned professionals who have managed high-profile public figures and brands.",
    },
    {
      question: "How does media mastery benefit future billionaires?",
      answer:
        "Media mastery allows billionaires to build and maintain strong personal brands, control narratives, and influence public opinion, all of which are essential for sustaining leadership and success.",
    },
    {
      question:
        "Can the skills learned here be applied to managing business brands?",
      answer:
        "Yes, the skills learned in this school apply to both personal and corporate branding, helping leaders manage media relations for their businesses as well as their personal image.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 text-uppercase m-0 d-flex BackgroundSlideshow_homepage_h1_1">
              OPRAH WINFREY SCHOOL OF MEDIA &
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                 PUBLIC RELATIONS
                </h1>
                {/* <p className="text-white BackgroundSlideshow_homepage_TM">TM</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              OPRAH WINFREY SCHOOL OF MEDIA & PUBLIC RELATIONS
            </h1>

            <p className="feature_homepage_p pt-4">
            <img
                src={IMG}
                alt="img"
                className="rounded-3"
                style={{
                  float: "right",
                  marginLeft: "15px",
                  width: "500px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              The Oprah Winfrey School of Media & Public Relations at the
              Institute of Billionaires is dedicated to training future
              billionaires in mastering their public image and media presence.
              Named after the legendary media mogul and global influencer, Oprah
              Winfrey, this school focuses on the strategic use of media and
              public relations to build powerful personal and business brands.
              Oprah’s ability to shape narratives, build authentic connections,
              and maintain a positive public image across decades of her career
              sets the perfect example for aspiring leaders and billionaires.
              <br />
              <br />
              At this school, students will learn how to leverage media
              platforms to control their personal brand, manage public
              perception, and navigate the complex world of public relations.
              The curriculum emphasizes media training, crisis management, and
              strategic communications—all essential skills for individuals who
              will one day be in the spotlight as leaders of billion-dollar
              enterprises.
              <br />
              <br />
              By studying case studies from Oprah’s illustrious career and
              learning from PR and media experts, students will develop the
              tools necessary to maintain a positive, powerful public image,
              even in times of controversy. The school also teaches students how
              to foster authentic connections with their audiences, manage
              public relations crises, and influence public opinion through mass
              media and digital platforms.
              <br />
              <br />
              The Oprah Winfrey School of Media & Public Relations is where
              future billionaires will learn to shape narratives, command the
              media, and build lasting legacies through strategic public
              relations.
            </p>
            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OprahWinfrey;
