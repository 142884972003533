import React from "react";
import Accordion from "../../../constant/accordion";
import IMG from "../../../assets/images/YvesSaint.jpeg";

const YvesSaint = () => {
  const accordionData = [
    {
      question:
        "What is the purpose of the Yves Saint Laurent School of Fashion and Style?",
      answer:
        "The Yves Saint Laurent School of Fashion and Style is designed to teach future billionaires how to use personal style as a powerful tool for influence, leadership, and self-expression. The school focuses on how fashion can project authority, confidence, and a personal brand that resonates on a global stage.",
    },
    {
      question: "Why is the school named after Yves Saint Laurent?",
      answer:
        "Yves Saint Laurent was a pioneer in the fashion industry, revolutionizing the concept of power dressing and individual expression through style. His legacy of using fashion to communicate confidence and authority makes him the perfect namesake for a school that teaches future leaders how to project influence through their appearance.",
    },
    {
      question:
        "What skills will students learn at the Yves Saint Laurent School of Fashion and Style?",
      answer:
        "Students will learn key skills such as the art of power dressing, understanding luxury brands, cultural etiquette, and how to develop a signature look that commands attention. The curriculum emphasizes how to use fashion as a strategic tool in business and social settings.",
    },
    {
      question:
        "How does the Yves Saint Laurent School of Fashion and Style fit into the overall curriculum of the Institute of Billionaires?",
      answer:
        "The Yves Saint Laurent School complements the Institute of Billionaires by teaching future leaders the soft skills of elegance and personal presentation, which are crucial when operating in elite social and business circles. It helps students refine their image to reflect authority and confidence, enhancing their leadership potential.",
    },
    {
      question: "Is this school only about fashion and style?",
      answer:
        "No, the Yves Saint Laurent School of Fashion and Style is about much more than clothing. It is about understanding how style and personal presentation can influence perception, build a personal brand, and create leadership opportunities. It also teaches the importance of cultural diplomacy and appropriate attire for different global settings.",
    },
    {
      question: "How does power dressing impact leadership?",
      answer:
        "Power dressing is the art of using clothing to project authority, confidence, and influence. Leaders who dress strategically are able to command respect and attention, which can impact negotiations, business meetings, and public appearances. The school teaches how to align fashion with leadership objectives.",
    },
    {
      question:
        "What makes Yves Saint Laurent an ideal figure for future billionaires to learn from?",
      answer:
        "Yves Saint Laurent transformed fashion by blending bold creativity with functionality, influencing the way both men and women present themselves in power settings. His philosophy of self-expression and creating a personal brand through style aligns with the school’s mission of teaching leaders to stand out and assert themselves.",
    },
    {
      question: "Is the school focused on a specific style of fashion?",
      answer:
        "The school does not focus on one specific style but teaches students how to develop their own signature look that reflects their personality and leadership qualities. It covers a range of luxury fashion elements, from formal business attire to more relaxed but influential styles for social settings.",
    },
    {
      question:
        "Who should attend the Yves Saint Laurent School of Fashion and Style?",
      answer:
        "This school is for students at the Institute of Billionaires who wish to refine their leadership presence through fashion and style. It is particularly useful for those who want to understand how personal presentation can affect business outcomes and relationships.",
    },
    {
      question:
        "How will the skills from this school help in the business world?",
      answer:
        "The skills taught at the Yves Saint Laurent School will help future billionaires command respect, build trust, and leave lasting impressions in both business and social environments. By mastering the art of personal presentation, graduates will be better equipped to lead with confidence and influence, making them stand out in global markets.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 text-uppercase m-0 d-flex BackgroundSlideshow_homepage_h1_1">
              Yves Saint Laurent 
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                School of Fashion
                </h1>
                {/* <p className="text-white BackgroundSlideshow_homepage_TM">TM</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Yves Saint Laurent School of Fashion and Style, Institute of
              Billionaires
            </h1>

            <p className="feature_homepage_p pt-4">
              <img
                src={IMG}
                alt="img"
                className="rounded-3"
                style={{
                  float: "right",
                  marginLeft: "15px",
                  width: "500px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              The Yves Saint Laurent School of Fashion and Style at the
              Institute of Billionaires is dedicated to teaching future
              billionaires how to use fashion as a tool for power, influence,
              and self-expression. Named after the legendary fashion designer
              Yves Saint Laurent, this school focuses on developing personal
              style that goes beyond trends and becomes a statement of authority
              and individuality.
              <br />
              <br />
              Yves Saint Laurent revolutionized the fashion world with his
              approach to power dressing, famously introducing the tuxedo suit
              for women. He believed that style was more than just clothing—it
              was a way to project confidence and influence. At this school,
              students will learn how to create their own signature look that
              not only reflects their personal brand but also commands attention
              and respect in any setting, from boardrooms to high society
              events.
              <br />
              <br />
              The curriculum is designed to provide a deep understanding of
              fashion, style, and luxury brands, ensuring students are
              knowledgeable in how to dress for success on the global stage. The
              school also emphasizes the importance of elegance, precision, and
              cultural awareness, helping students navigate the nuances of
              dressing for different environments and occasions around the
              world.
              <br />
              <br />
              More than just a fashion school, the Yves Saint Laurent School of
              Fashion and Style aims to cultivate the ability to use personal
              style as a tool for leadership. Whether it’s choosing the right
              outfit for a high-stakes business meeting or presenting oneself at
              an elite social event, graduates of this school will leave with
              the skills to make an impactful statement wherever they go.
              <br />
              <br />
              At the Institute of Billionaires, we believe that power and
              influence start with how you present yourself, and no one embodied
              this idea better than Yves Saint Laurent. This school is where
              future billionaires learn to dress not just for success, but for
              domination.
            </p>

            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YvesSaint;
