import { useLocation, useParams } from "react-router-dom";
import BACK from "../../assets/images/ecommerce_portal.webp";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "lucide-react";
import PortalForm from "../../forms/PortalForm";

function EcommerceCountryPage() {
  const { portalType, countryName } = useParams();
  const location = useLocation();
  const flag = location.state?.flag || "";

  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-12">
            <img width="100%" height={500} src={BACK} alt="background" />
          </div>
        </div>
      </div>
      <div
        className="container pb-5 mb-5"
        style={{
          marginTop: "-14%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-md-6 col-12 bg-white">
            <div className="d-flex align-items-center ps-4 py-3">
              <h1 className="portal_homepage_h1 text-uppercase">
                ecommerce portal
              </h1>
            </div>
            <div className="ps-4">
              <h2 className="fellowship text-uppercase">{countryName}</h2>
              <h2 className="feature_homepage_h2 mt-3">
                Launch Your Online Store with CSCPS E-commerce Portal
              </h2>
              <p className="feature_homepage_p mt-4">
                At the Command & Staff College of Physicians & Surgeons (CSCPS),
                we are excited to offer a comprehensive e-commerce platform that
                empowers you to launch your own online store seamlessly. Whether
                you are a seasoned business owner or an aspiring entrepreneur,
                our portal provides the tools and resources you need to succeed
                in the digital marketplace.
              </p>
            </div>
          </div>
          <div className="col-md-6 col-12 bg-white pt-4">
            <div className="d-flex justify-content-center">
              {flag && (
                <img
                  src={flag}
                  alt={countryName}
                  width="60%"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                />
              )}
            </div>
            <h5
              className="text-center mt-4 me-2"
              style={{ color: "var(--main-red-color)" }}
            >
              Share This Program
            </h5>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div
                className="d-inline-flex align-items-center gap-3 p-2 rounded-2"
                style={{ background: "#002F6C" }}
              >
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Facebook color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Twitter color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Youtube color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Linkedin color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Instagram color="#1B1B1B" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5" />
        <div className="row mt-5 pt-4 d-flex justify-content-center">
          <div className="col-md-6 col-12">
            <h2 className="fellowship text-uppercase">Ecommerce form</h2>
            <PortalForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default EcommerceCountryPage;
