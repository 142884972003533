import React from "react";
import Logo from "../../assets/images/IOB4.jpeg";
import IMG4 from "../../assets/images/img2-4.jpg";
import IMG3 from "../../assets/images/img2-4.jpg";
import IMG6 from "../../assets/images/img2-6.jpg";
import IMG7 from "../../assets/images/img2-7.jpg";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const IobChamberCommerceIndustry = () => {
  return (
    <div>
      <div className="about_back">
        <img alt="logo" src={Logo} width={300} />
      </div>
      <div className="container">
        <div className="row g-0 mt-5">
          <div className="col-12">
            <FirstHeading count="01" />
            <h1 className="heading_h1 text-uppercase m-0 p-0">
              IOB Chamber of Commerce & Industry
            </h1>
            <h2 className="text-danger text_head_feat mt-4">
              Commanding the Global Business Arena
            </h2>
            <p className="mt-2 about_p">
              Welcome to the IOB Chamber of Commerce & Industry, where we don’t just participate in the global economy—we lead it. In a world where businesses are constantly evolving and industries are reshaping, the IOB Chamber of Commerce & Industry is your gateway to navigating and dominating the international business landscape. We empower our members with the tools, connections, and strategies needed to outmaneuver competitors and seize opportunities on a global scale.
            </p>
          </div>
        </div>
      </div>
      <div className="about_back1 mt-5">
        <div>
          <h1
            className="fw-bold text-uppercase text-center text-white mt-5"
            style={{ marginTop: "-1%", fontSize: "85px" }}
          >
            OUR
          </h1>
          <h2
            className="fw-light text-danger text-center text-uppercase"
            style={{ marginTop: "", fontSize: "35px" }}
          >
            Companies
          </h2>
          <div className="container-fluid about_pictures mt-5 pt-4">
            <div className="row">
              <div className="col-md-4 col-12 m-0 p-0">
                <img
                  src={IMG4}
                  alt="featureimage"
                  className="img-fluid w-100 h-75 feature_images"
                />
              </div>
              <div className="col-md-3 col-12 m-0 p-0">
                <img
                  src={IMG6}
                  alt="featureimage"
                  className="img-fluid w-100 h-75 feature_images"
                />
              </div>
              <div className="col-md-5 col-12 m-0 p-0">
                <img
                  src={IMG7}
                  alt="featureimage"
                  className="img-fluid w-100 h-75 feature_images"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <p className="about_p">
              <img
                alt="logo"
                src={IMG3}
                width={650}
                style={{ float: "right" }}
                className="ms-5"
              />
              <h2 className="text-danger text_head_feat mt-4">Splashing the Future of Global Commerce</h2>
              At the IOB Chamber of Commerce & Industry, we believe that success in today’s interconnected world requires more than just participation; it requires command. Our chamber is designed to be a powerful force that not only facilitates business growth but actively shapes the future of global commerce. We are at the forefront of innovation, fostering collaboration among the world's leading enterprises and ensuring our members thrive in the ever-changing global market.
              <br />
              <h2 className="text-danger text_head_feat mt-4">
                For the Titans of Industry – The New Global Powerhouses
              </h2>
              This chamber is tailored for the industry titans who understand that global leadership is about influence, strategy, and innovation. The IOB Chamber of Commerce & Industry is not just a membership—it’s an alliance of the world’s most powerful business leaders, united by a shared vision of growth, dominance, and unprecedented success. Our members are the new global powerhouses, driving the future of commerce with unmatched foresight and expertise.
              <br />
              <h2 className="text-danger text_head_feat mt-4">
                Empowered by Strategic Insights and Global Networks
              </h2>
              The IOB Chamber of Commerce & Industry provides unparalleled access to strategic insights, global networks, and industry-leading resources. We equip our members with the knowledge and connections needed to stay ahead of the curve, outmaneuver competition, and capitalize on emerging opportunities worldwide. With our chamber, you don’t just respond to market changes—you shape them, leveraging our collective strength to redefine the rules of global business.
              <br />
              <h2 className="text-danger text_head_feat mt-4">
                Global Influence, Unmatched Leadership
              </h2>
              But we don’t stop at offering resources and connections. The IOB Chamber of Commerce & Industry is about leadership—about creating the future of global commerce and industry. We bring together the world’s leading minds, innovators, and strategists to collaborate, innovate, and drive change. Our members are not just part of the global business landscape—they are the architects of its future.
              <h2 className="text-danger text_head_feat mt-4">
                Join the Vanguard of Global Business Leadership
              </h2>
              The IOB Chamber of Commerce & Industry is more than just a chamber—it’s a command center for global business leadership. It’s for those who understand that in today’s world, influence is the currency of power. It’s for those who are ready to lead, to innovate, and to shape the future of commerce. If you’re ready to command the global business arena and become part of an elite network of industry leaders, then the IOB Chamber of Commerce & Industry is your path.
              <br />
              <br />
              Welcome to the IOB Chamber of Commerce & Industry—where global influence is forged, and the future of commerce is shaped by those bold enough to lead it.
            </p>
            <hr className="mt-5" />
            <div className="row mt-5">
              <div className="col-md-4 col-12">
                <p className="about_p">Lorem</p>
                <p className="text-danger mt-4">Lorem</p>
              </div>
              <div className="col-md-4 col-12">
                <p className="about_p">Lorem</p>
                <p className="text-danger mt-4">Lorem</p>
              </div>
              <div className="col-md-4 col-12">
                <p className="about_p">Lorem</p>
                <p className="text-danger mt-4">Lorem</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IobChamberCommerceIndustry
