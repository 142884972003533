import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const SupplyChainInstitute = () => {
  const accordionData = [
    {
      question: "What is the Supply Chain Institute?",
      answer:
        "The Supply Chain Institute is an elite institution focused on mastering the art and science of supply chain management. It equips leaders with the skills to control and optimize the flow of goods, services, and information across global markets, ensuring their enterprises maintain a strategic advantage.",
    },
    {
      question: "Who should apply to the Supply Chain Institute?",
      answer:
        "This institute is designed for those who understand the critical role of supply chains in global business and who seek to command these networks with precision and strategic insight. It’s ideal for individuals who aspire to lead complex logistical operations in high-stakes environments.",
    },
    {
      question: "What will I learn at the Supply Chain Institute?",
      answer:
        "You will learn how to design, manage, and optimize supply chains to achieve maximum efficiency and resilience. The curriculum covers everything from risk management and logistics to procurement strategies and technological integration, all with a focus on maintaining control and dominance in the market.",
    },
    {
      question:
        "How does the Supply Chain Institute differ from other logistics programs?",
      answer:
        "Unlike conventional logistics programs, the Supply Chain Institute teaches you to view supply chains as strategic weapons. We emphasize control, efficiency, and the ability to outmaneuver competitors in a world where the supply chain is the lifeblood of any enterprise.",
    },
    {
      question:
        "What is the importance of supply chains in today’s business environment?",
      answer:
        "Supply chains are the backbone of global commerce. They determine how quickly and efficiently products can move from conception to consumer. Mastering supply chains gives you the power to influence markets, outpace competitors, and ensure your business’s survival in a volatile world.",
    },
    {
      question:
        "What kind of leader does the Supply Chain Institute aim to produce?",
      answer:
        "We aim to produce leaders who can command every aspect of their supply chain with strategic foresight and operational excellence. Our graduates are equipped to manage complex global networks, ensuring that their enterprises remain resilient and competitive.",
    },
    {
      question: "What role does technology play in the curriculum?",
      answer:
        "Technology is a critical component of our curriculum. You will learn how to leverage the latest innovations in supply chain management, from automation and AI to advanced analytics, ensuring that your supply chain operates at peak efficiency and responsiveness.",
    },
    {
      question: "How selective is the Supply Chain Institute?",
      answer:
        "The Institute is highly selective, admitting only those who demonstrate a deep understanding of the strategic importance of supply chains and the potential to lead large-scale operations. Admission is based on a rigorous evaluation of your capabilities and vision.",
    },
    {
      question: "What is the Institute’s approach to supply chain disruptions?",
      answer:
        "We teach proactive strategies for anticipating and mitigating supply chain disruptions. You will learn how to build resilient supply chains that can withstand global shocks and continue to deliver value even in the face of adversity.",
    },
    {
      question: "How do I apply to the Supply Chain Institute?",
      answer:
        "Admission to the Supply Chain Institute is by invitation only. Prospective candidates must express their interest through our School of Admissions, where their ability to lead and optimize supply chains will be thoroughly assessed.",
    },
  ];

  return (
    <div>
      <div className="Univeristy_back">
        <div className="w-100">
          <div className="Univeristy_back_opacity p-4">
            <div>
              <h1 className="text-white text-uppercase p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                SUPPLY CHAIN
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  INSTITUTE
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              SUPPLY CHAIN INSTITUTE ™
            </h1>

            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              Hunting as a Pack
            </h2>

            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $1,185
              <br />
              <strong>Duration:</strong> 2 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> Institute of Public Companies
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Supply Chain Institute, where the unseen forces
              that drive global commerce are mastered. This is not merely an
              academic institution; it is the command center for those who
              understand that true power in business lies not just in what you
              produce, but in how you control the flow of goods, services, and
              information across the globe.
              <br /> <br />
              At the Supply Chain Institute, we teach that the supply chain is
              the lifeblood of any empire, the invisible hand that can either
              uplift or cripple even the mightiest of enterprises. Here, you
              will learn to wield this power with precision, ensuring that every
              link in your chain is not just strong but unbreakable.
              <br /> <br />
              Our approach is rooted in the mentality of a cartel—where
              strategic control, efficiency, and secrecy are paramount. You will
              learn to anticipate disruptions, outmaneuver competitors, and
              optimize every aspect of your supply network to maintain dominance
              in the market. From raw materials to finished products, you will
              understand how to orchestrate the entire process to your
              advantage, ensuring that your enterprise remains agile, resilient,
              and unstoppable.
              <br /> <br />
              The Supply Chain Institute is where future leaders of industry
              come to gain the knowledge and skills needed to command the
              world’s most complex logistical operations. This is where you will
              learn to turn supply chains into strategic weapons, ensuring that
              your business not only survives but thrives in the most
              competitive environments.
              <br /> <br />
              Welcome to the Supply Chain Institute—where the flow of power
              begins.
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
            <hr className="mt-3 mb-3" />

            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>

            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplyChainInstitute;
