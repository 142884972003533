import React from "react";
import { useLocation, useParams } from "react-router-dom";
import BACK from "../../assets/images/university2.jpg";
import PortalForm from "../../forms/PortalForm";
import { CountryContent } from "./CountryContent";

const ScholarshipCountryPage = () => {
  const { countryName } = useParams();
  const location = useLocation();
  const { flag } = location.state || {};

  // Get the content for the selected country
  const content = CountryContent[countryName] || {};
  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-12">
            <img width="100%" height={500} src={BACK} alt="background" />
          </div>
        </div>
      </div>
      <div
        className="container pb-5 mb-5"
        style={{
          marginTop: "-14%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-md-6 col-12 bg-white">
            <div className="d-flex align-items-center ps-4 py-3">
              <h1 className="portal_homepage_h1 text-uppercase">
                scholarship portal
              </h1>
            </div>
            <div className="ps-4">
              <h2 className="fellowship text-uppercase">{countryName}</h2>
              <div className="mt-4">
                <h2 className="feature_homepage_h2 mt-5">
                  Official name of the Scholarship:
                </h2>
                <p className="feature_homepage_p">
                  {content.officialName || "Content not available"}
                </p>

                <h2 className="feature_homepage_h2 mt-2">Completion Title:</h2>
                <p className="feature_homepage_p">
                  {content.completionTitle || "Content not available"}
                </p>

                <h2 className="feature_homepage_h2 mt-2">Criteria</h2>
                <p className="feature_homepage_p">
                  {content.criteria || "Content not available"}
                </p>

                <h2 className="feature_homepage_h2 mt-2">Business Desk</h2>
                <p className="feature_homepage_p">
                  {content.businessDesk || "Content not available"}
                </p>

                <h2 className="feature_homepage_h2 mt-2">Dedicated to</h2>
                <p className="feature_homepage_p">
                  {content.dedicatedTo || "Content not available"}
                </p>

                <h2 className="feature_homepage_h2 mt-2">
                  Graduating Institutions
                </h2>
                <p className="feature_homepage_p">
                  {content.graduatingInstitutions || "Content not available"}
                </p>

                <h2 className="feature_homepage_h2 mt-2">
                  Training & Education Regulators & Commission
                </h2>
                <p className="feature_homepage_p">
                  {content.trainingEducationRegulators ||
                    "Content not available"}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 bg-white pt-4">
            {/* <div className="col-md-6 col-12 bg-white pt-4">
            <div className="d-flex justify-content-center">
              <img
                width="70%"
                className="border border-dark border-3 founder_images"
                src={capital}
                alt="founderimg"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                width="70%"
                className="border border-dark border-3 mt-3 founder_images"
                src={flag}
                alt="founderimg"
              />
            </div>
          </div> */}
            <div className="d-flex justify-content-center">
              {flag && (
                <img
                  width="70%"
                  className="mt-5 founder_images"
                  src={flag}
                  alt="founderimg"
                  style={{ border: "4px solid var(--main-blue-color)" }}
                />
              )}
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5" />
        <div className="row mt-5 pt-4 d-flex justify-content-center">
          <div className="col-md-6 col-12">
            <h2 className="fellowship text-uppercase">scholarship form</h2>
            <PortalForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default ScholarshipCountryPage;
