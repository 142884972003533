import AfghanistanFlag from "../assets/images/flags/af.svg";
import AlbaniaFlag from "../assets/images/flags/al.svg";
import AlgeriaFlag from "../assets/images/flags/dz.svg";
import AndorraFlag from "../assets/images/flags/ad.svg";
import AngolaFlag from "../assets/images/flags/ao.svg";
import AntiguaAndBarbudaFlag from "../assets/images/flags/ag.svg";
import ArgentinaFlag from "../assets/images/flags/ar.svg";
import ArmeniaFlag from "../assets/images/flags/am.svg";
import AustraliaFlag from "../assets/images/flags/au.svg";
import AustriaFlag from "../assets/images/flags/at.svg";
import AzerbaijanFlag from "../assets/images/flags/az.svg";
import BahamasFlag from "../assets/images/flags/bs.svg";
import BahrainFlag from "../assets/images/flags/bh.svg";
import BangladeshFlag from "../assets/images/flags/bd.svg";
import BarbadosFlag from "../assets/images/flags/bb.svg";
import BelarusFlag from "../assets/images/flags/by.svg";
import BelgiumFlag from "../assets/images/flags/be.svg";
import BelizeFlag from "../assets/images/flags/bz.svg";
import BeninFlag from "../assets/images/flags/bj.svg";
import BhutanFlag from "../assets/images/flags/bt.svg";
import BoliviaFlag from "../assets/images/flags/bo.svg";
import BosniaAndHerzegovinaFlag from "../assets/images/flags/ba.svg";
import BotswanaFlag from "../assets/images/flags/bw.svg";
import BrazilFlag from "../assets/images/flags/br.svg";
import BruneiFlag from "../assets/images/flags/bn.svg";
import BulgariaFlag from "../assets/images/flags/bg.svg";
import BurkinaFasoFlag from "../assets/images/flags/bf.svg";
import BurundiFlag from "../assets/images/flags/bi.svg";
import CaboVerdeFlag from "../assets/images/flags/cv.svg";
import CambodiaFlag from "../assets/images/flags/km.svg";
import CameroonFlag from "../assets/images/flags/cm.svg";
import CanadaFlag from "../assets/images/flags/ca.svg";
import CentralAfricanRepublicFlag from "../assets/images/flags/cf.svg";
import ChadFlag from "../assets/images/flags/td.svg";
import ChileFlag from "../assets/images/flags/cl.svg";
import ChinaFlag from "../assets/images/flags/cn.svg";
import ColombiaFlag from "../assets/images/flags/co.svg";
import ComorosFlag from "../assets/images/flags/km.svg";
import CongoFlag from "../assets/images/flags/cg.svg";
import CostaRicaFlag from "../assets/images/flags/cr.svg";
import CroatiaFlag from "../assets/images/flags/hr.svg";
import CubaFlag from "../assets/images/flags/cu.svg";
import CyprusFlag from "../assets/images/flags/cy.svg";
import CzechRepublicFlag from "../assets/images/flags/cz.svg";
import DenmarkFlag from "../assets/images/flags/dk.svg";
import DjiboutiFlag from "../assets/images/flags/dj.svg";
import DominicaFlag from "../assets/images/flags/dm.svg";
import DominicanRepublicFlag from "../assets/images/flags/do.svg";
import EastTimorFlag from "../assets/images/flags/tl.svg";
import EcuadorFlag from "../assets/images/flags/ec.svg";
import EgyptFlag from "../assets/images/flags/eg.svg";
import ElSalvadorFlag from "../assets/images/flags/sv.svg";
import EquatorialGuineaFlag from "../assets/images/flags/gq.svg";
import EritreaFlag from "../assets/images/flags/er.svg";
import EstoniaFlag from "../assets/images/flags/ee.svg";
import EswatiniFlag from "../assets/images/flags/sz.svg";
import EthiopiaFlag from "../assets/images/flags/et.svg";
import FijiFlag from "../assets/images/flags/fj.svg";
import FinlandFlag from "../assets/images/flags/fi.svg";
import FranceFlag from "../assets/images/flags/fr.svg";
import GabonFlag from "../assets/images/flags/ga.svg";
import GambiaFlag from "../assets/images/flags/gb.svg";
import GeorgiaFlag from "../assets/images/flags/ge.svg";
import GermanyFlag from "../assets/images/flags/de.svg";
import GhanaFlag from "../assets/images/flags/gh.svg";
import GreeceFlag from "../assets/images/flags/gr.svg";
import GrenadaFlag from "../assets/images/flags/gd.svg";
import GuatemalaFlag from "../assets/images/flags/gt.svg";
import GuineaFlag from "../assets/images/flags/gn.svg";
import GuineaBissauFlag from "../assets/images/flags/gw.svg";
import GuyanaFlag from "../assets/images/flags/gy.svg";
import HaitiFlag from "../assets/images/flags/ht.svg";
import HondurasFlag from "../assets/images/flags/hn.svg";
import HungaryFlag from "../assets/images/flags/hu.svg";
import IcelandFlag from "../assets/images/flags/is.svg";
import IndiaFlag from "../assets/images/flags/in.svg";
import IndonesiaFlag from "../assets/images/flags/id.svg";
import IranFlag from "../assets/images/flags/ir.svg";
import IraqFlag from "../assets/images/flags/iq.svg";
import IrelandFlag from "../assets/images/flags/ie.svg";
import IsraelFlag from "../assets/images/flags/il.svg";
import ItalyFlag from "../assets/images/flags/it.svg";
import JamaicaFlag from "../assets/images/flags/jm.svg";
import JapanFlag from "../assets/images/flags/jp.svg";
import JordanFlag from "../assets/images/flags/jo.svg";
import KazakhstanFlag from "../assets/images/flags/kz.svg";
import KenyaFlag from "../assets/images/flags/ke.svg";
import KiribatiFlag from "../assets/images/flags/ki.svg";
import KoreaNorthFlag from "../assets/images/flags/kp.svg";
import KoreaSouthFlag from "../assets/images/flags/kr.svg";
import KosovoFlag from "../assets/images/flags/xk.svg";
import KuwaitFlag from "../assets/images/flags/kw.svg";
import KyrgyzstanFlag from "../assets/images/flags/kg.svg";
import LaosFlag from "../assets/images/flags/la.svg";
import LatviaFlag from "../assets/images/flags/lv.svg";
import LebanonFlag from "../assets/images/flags/lb.svg";
import LesothoFlag from "../assets/images/flags/ls.svg";
import LiberiaFlag from "../assets/images/flags/lr.svg";
import LibyaFlag from "../assets/images/flags/ly.svg";
import LiechtensteinFlag from "../assets/images/flags/li.svg";
import LithuaniaFlag from "../assets/images/flags/lt.svg";
import LuxembourgFlag from "../assets/images/flags/lu.svg";
import MadagascarFlag from "../assets/images/flags/mg.svg";
import MalawiFlag from "../assets/images/flags/mw.svg";
import MalaysiaFlag from "../assets/images/flags/my.svg";
import MaldivesFlag from "../assets/images/flags/mv.svg";
import MaliFlag from "../assets/images/flags/ml.svg";
import MaltaFlag from "../assets/images/flags/mt.svg";
import MarshallIslandsFlag from "../assets/images/flags/mh.svg";
import MauritaniaFlag from "../assets/images/flags/mr.svg";
import MauritiusFlag from "../assets/images/flags/mu.svg";
import MexicoFlag from "../assets/images/flags/mx.svg";
import MicronesiaFlag from "../assets/images/flags/fm.svg";
import MoldovaFlag from "../assets/images/flags/md.svg";
import MonacoFlag from "../assets/images/flags/mc.svg";
import MongoliaFlag from "../assets/images/flags/mn.svg";
import MontenegroFlag from "../assets/images/flags/me.svg";
import MoroccoFlag from "../assets/images/flags/ma.svg";
import MozambiqueFlag from "../assets/images/flags/mz.svg";
import MyanmarFlag from "../assets/images/flags/mm.svg";
import NamibiaFlag from "../assets/images/flags/na.svg";
import NauruFlag from "../assets/images/flags/nr.svg";
import NepalFlag from "../assets/images/flags/np.svg";
import NetherlandsFlag from "../assets/images/flags/nl.svg";
import NewZealandFlag from "../assets/images/flags/nz.svg";
import NicaraguaFlag from "../assets/images/flags/ni.svg";
import NigerFlag from "../assets/images/flags/ne.svg";
import NigeriaFlag from "../assets/images/flags/ng.svg";
import NorthMacedoniaFlag from "../assets/images/flags/mk.svg";
import NorwayFlag from "../assets/images/flags/no.svg";
import OmanFlag from "../assets/images/flags/om.svg";
import PakistanFlag from "../assets/images/flags/pk.svg";
import PalauFlag from "../assets/images/flags/pw.svg";
import PanamaFlag from "../assets/images/flags/pa.svg";
import PapuaNewGuineaFlag from "../assets/images/flags/pg.svg";
import ParaguayFlag from "../assets/images/flags/py.svg";
import PeruFlag from "../assets/images/flags/pe.svg";
import PhilippinesFlag from "../assets/images/flags/ph.svg";
import PolandFlag from "../assets/images/flags/pl.svg";
import PortugalFlag from "../assets/images/flags/pt.svg";
import QatarFlag from "../assets/images/flags/qa.svg";
import RomaniaFlag from "../assets/images/flags/ro.svg";
import RussiaFlag from "../assets/images/flags/ru.svg";
import RwandaFlag from "../assets/images/flags/rw.svg";
import SaintKittsAndNevisFlag from "../assets/images/flags/kn.svg";
import SaintLuciaFlag from "../assets/images/flags/lc.svg";
import SaintVincentAndTheGrenadinesFlag from "../assets/images/flags/vc.svg";
import SamoaFlag from "../assets/images/flags/ws.svg";
import SanMarinoFlag from "../assets/images/flags/sm.svg";
import SaoTomeAndPrincipeFlag from "../assets/images/flags/st.svg";
import SaudiArabiaFlag from "../assets/images/flags/sa.svg";
import SenegalFlag from "../assets/images/flags/sn.svg";
import SerbiaFlag from "../assets/images/flags/rs.svg";
import SeychellesFlag from "../assets/images/flags/sc.svg";
import SierraLeoneFlag from "../assets/images/flags/sl.svg";
import SingaporeFlag from "../assets/images/flags/sg.svg";
import SlovakiaFlag from "../assets/images/flags/sk.svg";
import SloveniaFlag from "../assets/images/flags/si.svg";
import SolomonIslandsFlag from "../assets/images/flags/sb.svg";
import SomaliaFlag from "../assets/images/flags/so.svg";
import SouthAfricaFlag from "../assets/images/flags/za.svg";
import SouthSudanFlag from "../assets/images/flags/ss.svg";
import SpainFlag from "../assets/images/flags/es.svg";
import SriLankaFlag from "../assets/images/flags/lk.svg";
import SudanFlag from "../assets/images/flags/sd.svg";
import SurinameFlag from "../assets/images/flags/sr.svg";
import SwedenFlag from "../assets/images/flags/se.svg";
import SwitzerlandFlag from "../assets/images/flags/ch.svg";
import SyriaFlag from "../assets/images/flags/sy.svg";
import TaiwanFlag from "../assets/images/flags/tw.svg";
import TajikistanFlag from "../assets/images/flags/tj.svg";
import TanzaniaFlag from "../assets/images/flags/tz.svg";
import ThailandFlag from "../assets/images/flags/th.svg";
import TogoFlag from "../assets/images/flags/tg.svg";
import TongaFlag from "../assets/images/flags/to.svg";
import TrinidadAndTobagoFlag from "../assets/images/flags/tt.svg";
import TunisiaFlag from "../assets/images/flags/tn.svg";
import TurkeyFlag from "../assets/images/flags/tr.svg";
import TurkmenistanFlag from "../assets/images/flags/tm.svg";
import TuvaluFlag from "../assets/images/flags/tv.svg";
import UgandaFlag from "../assets/images/flags/ug.svg";
import UkraineFlag from "../assets/images/flags/ua.svg";
import UnitedArabEmiratesFlag from "../assets/images/flags/ae.svg";
import UnitedKingdomFlag from "../assets/images/flags/gb.svg";
import UnitedStatesFlag from "../assets/images/flags/us.svg";
import UruguayFlag from "../assets/images/flags/uy.svg";
import UzbekistanFlag from "../assets/images/flags/uz.svg";
import VanuatuFlag from "../assets/images/flags/vu.svg";
import VaticanCityFlag from "../assets/images/flags/va.svg";
import VenezuelaFlag from "../assets/images/flags/ve.svg";
import VietnamFlag from "../assets/images/flags/vn.svg";
import YemenFlag from "../assets/images/flags/ye.svg";
import ZambiaFlag from "../assets/images/flags/zm.svg";
import ZimbabweFlag from "../assets/images/flags/zw.svg";
import PakistanCapital from "../assets/images/capitals/islamabad.jpg"
import PakistanFounder from "../assets/images/founders/qauid.jpg"

const countries = [
  { name: "Afghanistan", letter: "A", flag: AfghanistanFlag },
  { name: "Albania", letter: "A", flag: AlbaniaFlag },
  { name: "Algeria", letter: "A", flag: AlgeriaFlag },
  { name: "Andorra", letter: "A", flag: AndorraFlag },
  { name: "Angola", letter: "A", flag: AngolaFlag },
  { name: "Antigua and Barbuda", letter: "A", flag: AntiguaAndBarbudaFlag },
  { name: "Argentina", letter: "A", flag: ArgentinaFlag },
  { name: "Armenia", letter: "A", flag: ArmeniaFlag },
  { name: "Australia", letter: "A", flag: AustraliaFlag },
  { name: "Austria", letter: "A", flag: AustriaFlag },
  { name: "Azerbaijan", letter: "A", flag: AzerbaijanFlag },
  // Add the flag for each remaining country similarly
  { name: "Bahamas", letter: "B", flag: BahamasFlag },
  { name: "Bahrain", letter: "B", flag: BahrainFlag },
  { name: "Bangladesh", letter: "B", flag: BangladeshFlag },
  { name: "Barbados", letter: "B", flag: BarbadosFlag },
  { name: "Belarus", letter: "B", flag: BelarusFlag },
  { name: "Belgium", letter: "B", flag: BelgiumFlag },
  { name: "Belize", letter: "B", flag: BelizeFlag },
  { name: "Benin", letter: "B", flag: BeninFlag },
  { name: "Bhutan", letter: "B", flag: BhutanFlag },
  { name: "Bolivia", letter: "B", flag: BoliviaFlag },
  {
    name: "Bosnia and Herzegovina",
    letter: "B",
    flag: BosniaAndHerzegovinaFlag,
  },
  { name: "Botswana", letter: "B", flag: BotswanaFlag },
  { name: "Brazil", letter: "B", flag: BrazilFlag },
  { name: "Brunei", letter: "B", flag: BruneiFlag },
  { name: "Bulgaria", letter: "B", flag: BulgariaFlag },
  { name: "Burkina Faso", letter: "B", flag: BurkinaFasoFlag },
  { name: "Burundi", letter: "B", flag: BurundiFlag },
  { name: "Cabo Verde", letter: "C", flag: CaboVerdeFlag },
  { name: "Cambodia", letter: "C", flag: CambodiaFlag },
  { name: "Cameroon", letter: "C", flag: CameroonFlag },
  { name: "Canada", letter: "C", flag: CanadaFlag },
  {
    name: "Central African Republic",
    letter: "C",
    flag: CentralAfricanRepublicFlag,
  },
  { name: "Chad", letter: "C", flag: ChadFlag },
  { name: "Chile", letter: "C", flag: ChileFlag },
  { name: "China", letter: "C", flag: ChinaFlag },
  { name: "Colombia", letter: "C", flag: ColombiaFlag },
  { name: "Comoros", letter: "C", flag: ComorosFlag },
  { name: "Congo", letter: "C", flag: CongoFlag },
  { name: "Costa Rica", letter: "C", flag: CostaRicaFlag },
  { name: "Croatia", letter: "C", flag: CroatiaFlag },
  { name: "Cuba", letter: "C", flag: CubaFlag },
  { name: "Cyprus", letter: "C", flag: CyprusFlag },
  { name: "Czech Republic", letter: "C", flag: CzechRepublicFlag },
  { name: "Denmark", letter: "D", flag: DenmarkFlag },
  { name: "Djibouti", letter: "D", flag: DjiboutiFlag },
  { name: "Dominica", letter: "D", flag: DominicaFlag },
  { name: "Dominican Republic", letter: "D", flag: DominicanRepublicFlag },
  { name: "East Timor", letter: "E", flag: EastTimorFlag },
  { name: "Ecuador", letter: "E", flag: EcuadorFlag },
  { name: "Egypt", letter: "E", flag: EgyptFlag },
  { name: "El Salvador", letter: "E", flag: ElSalvadorFlag },
  { name: "Equatorial Guinea", letter: "E", flag: EquatorialGuineaFlag },
  { name: "Eritrea", letter: "E", flag: EritreaFlag },
  { name: "Estonia", letter: "E", flag: EstoniaFlag },
  { name: "Eswatini", letter: "E", flag: EswatiniFlag },
  { name: "Ethiopia", letter: "E", flag: EthiopiaFlag },
  { name: "Fiji", letter: "F", flag: FijiFlag },
  { name: "Finland", letter: "F", flag: FinlandFlag },
  { name: "France", letter: "F", flag: FranceFlag },
  { name: "Gabon", letter: "G", flag: GabonFlag },
  { name: "Gambia", letter: "G", flag: GambiaFlag },
  { name: "Georgia", letter: "G", flag: GeorgiaFlag },
  { name: "Germany", letter: "G", flag: GermanyFlag },
  { name: "Ghana", letter: "G", flag: GhanaFlag },
  { name: "Greece", letter: "G", flag: GreeceFlag },
  { name: "Grenada", letter: "G", flag: GrenadaFlag },
  { name: "Guatemala", letter: "G", flag: GuatemalaFlag },
  { name: "Guinea", letter: "G", flag: GuineaFlag },
  { name: "Guinea-Bissau", letter: "G", flag: GuineaBissauFlag },
  { name: "Guyana", letter: "G", flag: GuyanaFlag },
  { name: "Haiti", letter: "H", flag: HaitiFlag },
  { name: "Honduras", letter: "H", flag: HondurasFlag },
  { name: "Hungary", letter: "H", flag: HungaryFlag },
  { name: "Iceland", letter: "I", flag: IcelandFlag },
  { name: "India", letter: "I", flag: IndiaFlag },
  { name: "Indonesia", letter: "I", flag: IndonesiaFlag },
  { name: "Iran", letter: "I", flag: IranFlag },
  { name: "Iraq", letter: "I", flag: IraqFlag },
  { name: "Ireland", letter: "I", flag: IrelandFlag },
  { name: "Israel", letter: "I", flag: IsraelFlag },
  { name: "Italy", letter: "I", flag: ItalyFlag },
  { name: "Jamaica", letter: "J", flag: JamaicaFlag },
  { name: "Japan", letter: "J", flag: JapanFlag },
  { name: "Jordan", letter: "J", flag: JordanFlag },
  { name: "Kazakhstan", letter: "K", flag: KazakhstanFlag },
  { name: "Kenya", letter: "K", flag: KenyaFlag },
  { name: "Kiribati", letter: "K", flag: KiribatiFlag },
  { name: "Korea, North", letter: "K", flag: KoreaNorthFlag },
  { name: "Korea, South", letter: "K", flag: KoreaSouthFlag },
  { name: "Kosovo", letter: "K", flag: KosovoFlag },
  { name: "Kuwait", letter: "K", flag: KuwaitFlag },
  { name: "Kyrgyzstan", letter: "K", flag: KyrgyzstanFlag },
  { name: "Laos", letter: "L", flag: LaosFlag },
  { name: "Latvia", letter: "L", flag: LatviaFlag },
  { name: "Lebanon", letter: "L", flag: LebanonFlag },
  { name: "Lesotho", letter: "L", flag: LesothoFlag },
  { name: "Liberia", letter: "L", flag: LiberiaFlag },
  { name: "Libya", letter: "L", flag: LibyaFlag },
  { name: "Liechtenstein", letter: "L", flag: LiechtensteinFlag },
  { name: "Lithuania", letter: "L", flag: LithuaniaFlag },
  { name: "Luxembourg", letter: "L", flag: LuxembourgFlag },
  { name: "Madagascar", letter: "M", flag: MadagascarFlag },
  { name: "Malawi", letter: "M", flag: MalawiFlag },
  { name: "Malaysia", letter: "M", flag: MalaysiaFlag },
  { name: "Maldives", letter: "M", flag: MaldivesFlag },
  { name: "Mali", letter: "M", flag: MaliFlag },
  { name: "Malta", letter: "M", flag: MaltaFlag },
  { name: "Marshall Islands", letter: "M", flag: MarshallIslandsFlag },
  { name: "Mauritania", letter: "M", flag: MauritaniaFlag },
  { name: "Mauritius", letter: "M", flag: MauritiusFlag },
  { name: "Mexico", letter: "M", flag: MexicoFlag },
  { name: "Micronesia", letter: "M", flag: MicronesiaFlag },
  { name: "Moldova", letter: "M", flag: MoldovaFlag },
  { name: "Monaco", letter: "M", flag: MonacoFlag },
  { name: "Mongolia", letter: "M", flag: MongoliaFlag },
  { name: "Montenegro", letter: "M", flag: MontenegroFlag },
  { name: "Morocco", letter: "M", flag: MoroccoFlag },
  { name: "Mozambique", letter: "M", flag: MozambiqueFlag },
  { name: "Myanmar", letter: "M", flag: MyanmarFlag },
  { name: "Namibia", letter: "N", flag: NamibiaFlag },
  { name: "Nauru", letter: "N", flag: NauruFlag },
  { name: "Nepal", letter: "N", flag: NepalFlag },
  { name: "Netherlands", letter: "N", flag: NetherlandsFlag },
  { name: "New Zealand", letter: "N", flag: NewZealandFlag },
  { name: "Nicaragua", letter: "N", flag: NicaraguaFlag },
  { name: "Niger", letter: "N", flag: NigerFlag },
  { name: "Nigeria", letter: "N", flag: NigeriaFlag },
  { name: "North Macedonia", letter: "N", flag: NorthMacedoniaFlag },
  { name: "Norway", letter: "N", flag: NorwayFlag },
  { name: "Oman", letter: "O", flag: OmanFlag },
  { name: "Pakistan", letter: "P", flag: PakistanFlag, capital: PakistanCapital, founder: PakistanFounder, founderName: "Muhammad Ali Jinnah" },
  { name: "Palau", letter: "P", flag: PalauFlag },
  { name: "Panama", letter: "P", flag: PanamaFlag },
  { name: "Papua New Guinea", letter: "P", flag: PapuaNewGuineaFlag },
  { name: "Paraguay", letter: "P", flag: ParaguayFlag },
  { name: "Peru", letter: "P", flag: PeruFlag },
  { name: "Philippines", letter: "P", flag: PhilippinesFlag },
  { name: "Poland", letter: "P", flag: PolandFlag },
  { name: "Portugal", letter: "P", flag: PortugalFlag },
  { name: "Qatar", letter: "Q", flag: QatarFlag },
  { name: "Romania", letter: "R", flag: RomaniaFlag },
  { name: "Russia", letter: "R", flag: RussiaFlag },
  { name: "Rwanda", letter: "R", flag: RwandaFlag },
  { name: "Saint Kitts and Nevis", letter: "S", flag: SaintKittsAndNevisFlag },
  { name: "Saint Lucia", letter: "S", flag: SaintLuciaFlag },
  {
    name: "Saint Vincent and the Grenadines",
    letter: "S",
    flag: SaintVincentAndTheGrenadinesFlag,
  },
  { name: "Samoa", letter: "S", flag: SamoaFlag },
  { name: "San Marino", letter: "S", flag: SanMarinoFlag },
  { name: "Sao Tome and Principe", letter: "S", flag: SaoTomeAndPrincipeFlag },
  { name: "Saudi Arabia", letter: "S", flag: SaudiArabiaFlag },
  { name: "Senegal", letter: "S", flag: SenegalFlag },
  { name: "Serbia", letter: "S", flag: SerbiaFlag },
  { name: "Seychelles", letter: "S", flag: SeychellesFlag },
  { name: "Sierra Leone", letter: "S", flag: SierraLeoneFlag },
  { name: "Singapore", letter: "S", flag: SingaporeFlag },
  { name: "Slovakia", letter: "S", flag: SlovakiaFlag },
  { name: "Slovenia", letter: "S", flag: SloveniaFlag },
  { name: "Solomon Islands", letter: "S", flag: SolomonIslandsFlag },
  { name: "Somalia", letter: "S", flag: SomaliaFlag },
  { name: "South Africa", letter: "S", flag: SouthAfricaFlag },
  { name: "South Sudan", letter: "S", flag: SouthSudanFlag },
  { name: "Spain", letter: "S", flag: SpainFlag },
  { name: "Sri Lanka", letter: "S", flag: SriLankaFlag },
  { name: "Sudan", letter: "S", flag: SudanFlag },
  { name: "Suriname", letter: "S", flag: SurinameFlag },
  { name: "Sweden", letter: "S", flag: SwedenFlag },
  { name: "Switzerland", letter: "S", flag: SwitzerlandFlag },
  { name: "Syria", letter: "S", flag: SyriaFlag },
  { name: "Taiwan", letter: "T", flag: TaiwanFlag },
  { name: "Tajikistan", letter: "T", flag: TajikistanFlag },
  { name: "Tanzania", letter: "T", flag: TanzaniaFlag },
  { name: "Thailand", letter: "T", flag: ThailandFlag },
  { name: "Togo", letter: "T", flag: TogoFlag },
  { name: "Tonga", letter: "T", flag: TongaFlag },
  { name: "Trinidad and Tobago", letter: "T", flag: TrinidadAndTobagoFlag },
  { name: "Tunisia", letter: "T", flag: TunisiaFlag },
  { name: "Turkey", letter: "T", flag: TurkeyFlag },
  { name: "Turkmenistan", letter: "T", flag: TurkmenistanFlag },
  { name: "Tuvalu", letter: "T", flag: TuvaluFlag },
  { name: "Uganda", letter: "U", flag: UgandaFlag },
  { name: "Ukraine", letter: "U", flag: UkraineFlag },
  { name: "United Arab Emirates", letter: "U", flag: UnitedArabEmiratesFlag },
  { name: "United Kingdom", letter: "U", flag: UnitedKingdomFlag },
  { name: "United States", letter: "U", flag: UnitedStatesFlag },
  { name: "Uruguay", letter: "U", flag: UruguayFlag },
  { name: "Uzbekistan", letter: "U", flag: UzbekistanFlag },
  { name: "Vanuatu", letter: "V", flag: VanuatuFlag },
  { name: "Vatican City", letter: "V", flag: VaticanCityFlag },
  { name: "Venezuela", letter: "V", flag: VenezuelaFlag },
  { name: "Vietnam", letter: "V", flag: VietnamFlag },
  { name: "Yemen", letter: "Y", flag: YemenFlag },
  { name: "Zambia", letter: "Z", flag: ZambiaFlag },
  { name: "Zimbabwe", letter: "Z", flag: ZimbabweFlag },
];

export default countries;
