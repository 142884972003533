import React from "react";
import ProfileImg from "../../assets/images/CZARPROFILE.jpeg";
import FirstHeading from "../textcomponents/FirstHeading";
import FirstButton from "../textcomponents/FirstButton";
import { Link } from "react-router-dom";
import FormButton from "../textcomponents/FormButton";

const ProfilePage = () => {
  return (
    <>
      <div className="container mt-5">
        <div className="row g-0">
          {/* <FirstHeading
            count="02"
            title="$360,000,000,000"
            tagline='THE "shehrezade"'
          /> */}
        </div>
        <div className="row g-0 mt-5">
          <div className="col-md-5 col-12 profile_div1">
            <img
              src={ProfileImg}
              alt="JasonOppenheim"
              className="profile-image"
              width="100%"
            />
          </div>
          <div className="col-md-7 col-12 profile_div2 pb-4 pb-lg-0 d-flex justify-content-center align-items-center">
            <div className="text-white ps-2 ps-lg-5 pe-2 pe-lg-5">
              <h1 className="text-capitalize profile_h1_1">Lineage…</h1>
              <h1 className="text-uppercase profile_h1_2">
                The Ultra Godfather!
              </h1>
              <div className="mt-4">
                  <FormButton name="Read More" url="/profile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
