import React from "react";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const GuaranteeCompany = () => {
  return (
    <>
      <div className="feature_homepage_back">
        <div className="w-100">
          <div className="feature_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <FirstHeading
              count="01"
              title="Institute of Billionaires Guarantee Ltd."
              tagline="Guaranting the Art of Success"
            />
            <p className="feature_homepage_p pt-4">
              At the Institute of Billionaires, we don’t just teach success—we
              guarantee it. We’ve mastered the art of success so completely that
              we’ve established a dedicated guarantee house, the Institute of
              Billionaires Guarantee Ltd. (IOBGL), to ensure that your triumph
              is not just a possibility but an inevitability. <br />
              <br />
              This isn’t just another promise; it’s a commitment forged in the
              crucible of experience, knowledge, and relentless ambition. When
              you step into our world, you do so with the assurance that we
              stand behind your success with the full weight of our expertise
              and resources. We’ve built this guarantee house because we believe
              that true success doesn’t come with uncertainty—it comes with
              absolute certainty.
              <br />
              <br />
              To further reinforce this commitment, we’ve partnered with the
              Public Companies Institute Guarantee Ltd. Together, we form an
              unbreakable alliance, combining the might of two powerful
              entities, each with a singular focus: your success. Whether you’re
              entering the world of public companies or setting out on a path to
              billion-dollar leadership, our guarantee stands as an ironclad
              promise that your journey with us will lead to the pinnacle of
              success.
              <br />
              <br />
              Institute of Billionaires Guarantee Ltd. – Your Success is Not an
              Option. It’s an Absolute.
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default GuaranteeCompany;
