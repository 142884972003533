import React from "react";
import Accordion from "../../../constant/accordion";
import IMG from "../../../assets/images/LinusPauling_IMG.webp";

const LinusPauling = () => {
  const accordionData = [
    {
      question:
        "What is the focus of the Linus Pauling School of Nutrition & Health?",
      answer:
        "The Linus Pauling School of Nutrition & Health is dedicated to teaching elite nutrition practices, fitness optimization, and biohacking strategies that ensure future billionaires maintain peak physical and mental performance for long-term success.",
    },
    {
      question: "Why is the school named after Linus Pauling?",
      answer:
        "The school is named after Linus Pauling, a two-time Nobel laureate and pioneering figure in nutrition science. Pauling’s work, especially on the benefits of vitamins and micronutrients like vitamin C, aligns with the school’s focus on using nutrition to optimize health and longevity.",
    },
    {
      question:
        "What will students learn at the Linus Pauling School of Nutrition & Health?",
      answer:
        "Students will learn about advanced nutrition, micronutrient science, dietary strategies, and the latest in biohacking. The curriculum covers ways to optimize physical and cognitive health, ensuring students can sustain high levels of performance over the long term.",
    },
    {
      question:
        "How does this school integrate into the overall curriculum of the Institute of Billionaires?",
      answer:
        "The Linus Pauling School supports the mission of the Institute of Billionaires by teaching students how to maintain and optimize their health. Good health is foundational for leadership and wealth-building, and this school provides the tools to ensure students remain at their best physically and mentally.",
    },
    {
      question: "What is biohacking, and how is it taught here?",
      answer:
        "Biohacking refers to using science-based techniques to enhance physical and mental performance. At the Linus Pauling School, students will learn biohacking strategies such as intermittent fasting, supplementation, and other methods to improve longevity and cognitive function.",
    },
    {
      question:
        "Who can benefit from the lessons at the Linus Pauling School of Nutrition & Health?",
      answer:
        "The lessons are designed for future leaders and billionaires who understand that maintaining optimal health is critical to sustaining success. Students will gain knowledge applicable to both personal health management and the leadership of health-focused enterprises.",
    },
    {
      question: "Why is nutrition important for business leaders?",
      answer:
        "Good nutrition improves cognitive function, energy levels, and overall vitality, which are crucial for decision-making, stress management, and leadership. By mastering nutrition, students will ensure that their physical health supports their professional success.",
    },
    {
      question: "Does the school only focus on physical health?",
      answer:
        "No, the school emphasizes both physical and mental health. Along with nutrition and fitness, students will learn techniques to enhance mental resilience, cognitive sharpness, and overall well-being.",
    },
    {
      question:
        "What makes Linus Pauling an ideal figure for a school of nutrition and health?",
      answer:
        "Linus Pauling was one of the greatest scientific minds in modern history, and his pioneering work on nutrition, particularly the role of vitamins, transformed how we approach health and wellness. His name represents both innovation and excellence in scientific approaches to health, making him a fitting inspiration for this school.",
    },
    {
      question:
        "What kind of leaders does the Linus Pauling School aim to produce?",
      answer:
        "The Linus Pauling School aims to produce leaders who understand the importance of health in achieving long-term success. Graduates will leave with the tools to optimize their personal well-being and the knowledge to influence health initiatives in their business ventures.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white text-uppercase p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
              Linus Pauling School of
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                Nutrition & Health
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Linus Pauling School of Nutrition & Health
            </h1>

            <p className="feature_homepage_p pt-4">
            <img
                src={IMG}
                alt="img"
                className="rounded-3"
                style={{
                  float: "right",
                  marginLeft: "15px",
                  width: "500px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              The Linus Pauling School of Nutrition & Health at the Institute of
              Billionaires is named after one of the most influential figures in
              the history of nutrition and science, Linus Pauling. As a two-time
              Nobel laureate, Pauling revolutionized the understanding of
              vitamins and micronutrients, particularly with his groundbreaking
              research on the benefits of vitamin C for immune function and
              overall health. His work laid the foundation for much of today’s
              understanding of how nutrition can optimize health and longevity.
              <br />
              <br />
              At the Linus Pauling School, we focus on elite nutrition and
              health practices that enhance both physical and mental
              performance. Future billionaires are taught not only how to
              maintain their health but also how to biohack their bodies for
              longevity and peak performance, ensuring that they can sustain
              success and leadership over the long term.
              <br />
              <br />
              The curriculum covers advanced nutrition science, fitness
              optimization, and the latest developments in biohacking. Students
              will explore topics such as micronutrient balance, the role of
              supplements, intermittent fasting, and dietary strategies that
              maximize energy and cognitive function. By integrating Pauling’s
              philosophy on the connection between nutrition and health, the
              school equips students with the knowledge to stay at the top of
              their game, both physically and mentally.
            </p>

            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinusPauling;
