// import React from "react";
// import { useNavigate } from "react-router-dom";
// import BoxImage1 from "../../assets/images/BoxImage1.jpg";
// import "./BoxPage.css";
// import { type } from "@testing-library/user-event/dist/type";

// function BoxesPage() {
//   const navigate = useNavigate();

//   const mainCards = [
//     { title: "TRAINING", type: "TRAINING" },
//     { title: "GRADUATING", type: "TRAINING" },
//     { title: "APPRENTICESHIP", type: "TRAINING" },
//     { title: "OWN YOUR COMPANY", type: "COMPANY" },
//     { title: "APPLY THE SECRET", type: "SECRET" },
//     { title: "UNICORNING", type: "UNICORNING" },
//   ];

//   const handleCardClick = (type) => {
//     navigate(`/detail/${type}`);
//   };

//   return (
//     <div className="container">
//       <div className="row">
//         {mainCards.map((item, index) => (
//           <div className="col-md-4 col-12" key={index}>
//             <div
//               className="interactive-map-card"
//               onClick={() => handleCardClick(type)}
//             >
//               <img src={BoxImage1} className="card-img" alt="Background" />
//               <div className="card-img-overlay d-flex justify-content-center align-items-center">
//                 <h2 className="card-title">{item.title}</h2>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default BoxesPage;

import React from "react";
import { useNavigate } from "react-router-dom";
import BoxImage1 from "../../assets/images/BoxImage1.jpg";
import "./BoxPage.css";

function BoxesPage() {
  const navigate = useNavigate();

  const mainCards = [
    { title: "HUNGER-ING", type: "hungering" },
    { title: "No PLAN B-ING", type: "planing" },
    { title: "UNICORN-ING", type: "unicorning" },
    { title: "BILLIONAIRE-ING", type: "billioaireing" },
    { title: "WAR-ING", type: "warning" },
    { title: "FIN TECH-ING", type: "teching" },
  ];

  const handleCardClick = (type) => {
    navigate(`/detail/${type}`);
  };

  return (
    <div className="boxes-page">
      <div className="container-fluid mt-5">
        <div className="row g-0">
          {mainCards.map((card, index) => (
            <div className="col-md-4 col-12 pt-4" key={index}>
              <div
                className={`card ${
                  index % 2 === 0 ? "list-with-us-card" : "interactive-map-card"
                } cursor-pointer`}
                onClick={() => handleCardClick(card.type)}
              >
                <img src={BoxImage1} className="card-img" alt="Background" />
                <div className="card-img-overlay d-flex justify-content-center align-items-center">
                  <h2 className="card-title">{card.title}</h2>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BoxesPage;
