import IMG from "../../assets/images/img2-5.jpg";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "lucide-react";
import FormButton from "../../components/textcomponents/FormButton";
import FirstHeading from "../../components/textcomponents/FirstHeading";

function Contact() {
  return (
    <>
      <div className="contact_homepage_back">
        <div className="w-100">
          <div className="contact_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="heading_h1 text-uppercase mb-4 p-0">Contact Us</h1>
            <FirstHeading count="01" />
            <h2 className="heading_h2 text-uppercase">mission</h2>
            <p className="feature_homepage_p mt-3">
              At the core of the Institute of Billionaires is the Public
              Companies Command Office—the unrivaled R&D and marketing
              intelligence regulatory arm that drives our unstoppable momentum.
              This is not just another department; it’s the command center of
              groundbreaking innovation, powered by cutting-edge AI and
              relentless strategic vision. Here, the future isn’t just
              anticipated—it’s engineered with precision and purpose. There’s no
              second place in our world—only the future we command.
            </p>

            <FirstHeading count="02" />
            <h2 className="heading_h2 text-uppercase">Vission</h2>
            <p className="feature_homepage_p mt-3">
              At the core of the Institute of Billionaires is the Public
              Companies Command Office—the unrivaled R&D and marketing
              intelligence regulatory arm that drives our unstoppable momentum.
              This is not just another department; it’s the command center of
              groundbreaking innovation, powered by cutting-edge AI and
              relentless strategic vision. Here, the future isn’t just
              anticipated—it’s engineered with precision and purpose. There’s no
              second place in our world—only the future we command.
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>

      <div
        className="container pt-5 mt-5 pb-5 mb-5"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row d-flex justify-content-center">
          <div className="col-md-5 col-12 d-flex align-items-center">
            <div className="ps-4">
              <h2 className="fellowship text-uppercase">get in touch</h2>
              <div className="mt-4">
                <h2 className="feature_homepage_h2 mt-5">Phone Number</h2>
                <p className="p-0 m-0" style={{color: "#666666"}}>
                0321-9700700
                </p>
              </div>
              <div className="mt-4">
                <h2 className="feature_homepage_h2">Company Email</h2>
                <p className="p-0 m-0" style={{color: "#666666"}}>
                contact@institueofbillioaire.com
                </p>
              </div>
              <div className="mt-4">
                <h2 className="feature_homepage_h2">Address</h2>
                <p className="p-0 m-0" style={{color: "#666666"}}>

                  32 A Lawrence Road, Jinnah Town, Lahore
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-12 pt-4 d-flex align-items-center">
            <div>
              <div className="w-100">
                <h2 className="fellowship text-uppercase">contact form</h2>
                <input
                  className="form-control mt-3"
                  type="text"
                  placeholder="Name"
                />
                <input
                  className="form-control mt-3"
                  type="text"
                  placeholder="Email Address"
                />
                <input
                  className="form-control mt-3"
                  type="number"
                  placeholder="Phone Number"
                />
                <textarea
                  className="form-control mt-3"
                  id="exampleFormControlTextarea1"
                  placeholder="Message"
                  rows="3"
                ></textarea>
                <div className="mt-5 text-center">
                  <FormButton name="Submit" />
                </div>
              </div>
              {/* <h5
              className="text-center mt-4 me-2"
              style={{ color: "var(--main-red-color)" }}
            >
              Share This Program
            </h5>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div
                className="d-inline-flex align-items-center gap-3 p-2 rounded-2"
                style={{ background: "#002F6C" }}
              >
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Facebook color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Twitter color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Youtube color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Linkedin color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Instagram color="#1B1B1B" />
                  </div>
                </a>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5" />
      </div>
    </>
  );
}

export default Contact;
