import React from "react";

const SecondButton = ({ name, onClick }) => {
  return (
    <>
      <div className="ps-3 pe-3 mt-2 mb-2">
        <button className="secondBtn" onClick={onClick}>
          {name}
        </button>
      </div>
    </>
  );
};

export default SecondButton;
