import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="container">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        {items.map((item, index) => (
          <div className="accordion-item rounded-3 border-0 shadow mb-2" key={index}>
            <h1 className="accordion-header">
              <button
                className="accordion-button border-bottom collapsed fw-semibold"
                type="button"
                data-bs-toggle="collapse"
                aria-expanded={activeIndex === index}
                onClick={() => toggleAccordion(index)}
              >
                {item.question}
                <span className="ms-auto">
                  {activeIndex === index ? <Minus /> : <Plus />}
                </span>
              </button>
            </h1>
            <div
              id={`flush-collapse${index}`}
              className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                <p>{item.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
