import React from "react";
import CImage1 from "../../assets/images/CImg1.jpg";
import CImage2 from "../../assets/images/CImg2.jpg";
import FirstHeading from "../textcomponents/FirstHeading";
import SecondButton from "../textcomponents/SecondButton";
import { Link } from "react-router-dom";

function Concierge() {
  return (
    <>
      <div className="container mt-5 mb-5">
        <div className="row g-0">
          <div className="col-md-4 col-12 m-0 p-0 d-flex align-items-center">
            <div>
              <FirstHeading count="03" />
              <h1 className="conceige_heading_h1 text-uppercase m-0 p-0">
                $100 Million Dollars
              </h1>
              <h2 className="text-uppercase conceige_heading_h1_2">
                CEO's Net worth Rewards Concierge
              </h2>

              <div className="mt-4 pt-2">
                <Link to="/concierge-homepage" className="text-decoration-none">
                  <SecondButton name="Read More" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12 m-0 p-0">
            <div className="d-flex justify-content-end">
              <img
                src={CImage1}
                alt="Concierge 1"
                className="p-0 m-0 border-2 border border-white conciergeImg"
              />
              <img
                src={CImage2}
                alt="Concierge 2"
                className="p-0 m-0 border-2 border border-white conciergeImg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Concierge;
