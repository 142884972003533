import React from "react";
import IMGFirst from "../../assets/images/img3-1.jpg";
import IMGSecond from "../../assets/images/img3-2.jpg";
import { Link } from "react-router-dom";

const Chamber = () => {
  return (
    <>
      <div
        className="conatiner-fluid mt-5 mb-5"
        style={{ backgroundColor: "var(--main-black-color)" }}
      >
        <div className="row g-0">
          <div className="col-12">
            <div className="container">
              <div className="row g-0">
                <div className="col-md-6 col-12">
                  <Link
                    className="text-decoration-none"
                    to="/unicorn-chamber-Commerce"
                  >
                    <div className="text-center p-4">
                      <img src={IMGFirst} alt="img" className="img-fluid" style={{boxShadow: "white 0px 5px 15px"}} />
                      <h3
                        className="text-white pt-5 chamber_h3"
                      >
                        Unicorns Chamber of <br/> Commerce & Industry
                      </h3>
                    </div>
                  </Link>
                </div>
                <div className="col-md-6 col-12">
                  <Link
                    className="text-decoration-none"
                    to="/billionaires-chamber-Commerce"
                  >
                    <div className="text-center p-4">
                      <img src={IMGSecond} alt="img" className="img-fluid" style={{boxShadow: "white 0px 5px 15px"}} />
                      <h3
                        className="text-white pt-5 chamber_h3"
                      >
                        Billionaires Chamber of <br/> Commerce & Industry
                      </h3>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chamber;
