import React from "react";
import Accordion from "../../constant/accordion";

const InstituteofMillionaires = () => {
  const accordionData = [
    {
      question: "What is the main purpose of the Institute of Millionaires?",
      answer:
        "The IoM is designed to help students scale businesses between $1 million and $10 million, preparing them for the next step at the Institute of Billionaires.",
    },
    {
      question: "How are students assigned businesses to scale?",
      answer:
        "Students are matched with businesses based on their skills and potential to scale the business within the revenue target.",
    },
    {
      question: "What is the duration of the IoM program?",
      answer:
        "The program typically lasts between 12 to 18 months, depending on how quickly the student can scale the assigned business.",
    },
    {
      question: "What happens after I scale the business to $10 million?",
      answer:
        "Once the student successfully scales the business to the target, they are eligible for admission to the Institute of Billionaires (IoB).",
    },
    {
      question: "Is mentorship provided during the program?",
      answer:
        "Yes, each student receives guidance from successful entrepreneurs and industry experts throughout their time at IoM.",
    },
    {
      question: "What kind of businesses will I be assigned?",
      answer:
        "Students may be assigned to startups or existing businesses across various industries, depending on market demand and student skills.",
    },
    {
      question: "What happens if I don't reach the $1 million target?",
      answer:
        "Students who do not meet the target will receive additional coaching and support to help them overcome challenges.",
    },
    {
      question: "Do I need to have business experience to join IoM?",
      answer:
        "While previous business experience is beneficial, IoM is designed to train and support students from diverse backgrounds.",
    },
    {
      question: "What is the final goal of the IoM program?",
      answer:
        "The ultimate goal is to equip students with the skills and knowledge necessary to lead successful businesses and prepare them for the Institute of Billionaires.",
    },
    {
      question: "Can I apply to IoM without intending to join the IoB?",
      answer:
        "No, IoM is the first step in the broader journey toward wealth creation, culminating in training at the Institute of Billionaires.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Millionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">
              Institute of Millionaires (IoM)
            </h1>
            <p className="feature_homepage_p pt-4">
              The Institute of Millionaires (IoM) is the first critical step in
              a student’s journey toward elite leadership and wealth creation.
              As the initial institution within the School of Admissions of the
              Institute of Billionaires, IoM is designed to prepare students to
              scale businesses between $1 million and $10 million. Admission to
              IoM represents a commitment to rigorous training, practical
              business experience, and an opportunity to work on real-world
              business ventures.
              <br />
              <br />
              At IoM, each student is assigned a business or startup, where they
              will apply their knowledge and strategic thinking to achieve
              significant growth. The goal is to scale the assigned company to
              $1 million to $10 million in revenue, demonstrating their
              capability to handle complex business challenges. Once students
              successfully scale their businesses, they qualify for admission to
              the prestigious Institute of Billionaires, where they will be
              trained to build billion-dollar enterprises.
              <br />
              <br />
              The IoM provides students with the mentorship, resources, and
              tools necessary to accelerate their business growth and become
              influential leaders in the global market.
            </p>

            <hr className="mt-3 mb-3" />

            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteofMillionaires;
