import React from "react";

const OurCompanies = () => {
  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Welcome to the 0.00003%—No Plan B.
            </h1>
            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              "
              <span
                style={{ fontSize: "50px", color: "var(--main-red-color)" }}
              >
                Do you even belong here?{" "}
              </span>
              This isn't just another website, nor is its intent ordinary."
            </h2>
            <p className="feature_homepage_p pt-4">
              This is the birthplace of billion-dollar companies, and where
              billionaires are created—and that’s our hard-core business. If you
              think becoming a billionaire is about having a unique idea, hard
              work, resilience, risk-taking, financial management… think
              again—and leave.
            </p>
            <p className="feature_homepage_p">
              But, for those who seek to conquer the cutthroat world of
              billion-dollar empires, prepare yourself: you need the attitude of
              a gangster, the brilliance of a warlord, the hypnotic influence of
              a cult leader, and the secrecy of a cartel and syndicate. There is
              a covert mentality, and the only one that works. It’s the raw
              material from which billionaires are forged. The rest? That’s just
              how your parents raised you, and material for another story.
            </p>
            <p className="feature_homepage_p">
              The real power lies in the minds of those who can keep it all a
              secret. If you can’t command your own mouth, this life and the
              world doesn’t belong to you.
            </p>
            <h4
              className="text-dark p-0 pt-2 pb-2 ps-0 ps-lg-0 m-0 navbar_h4"
              style={{ fontSize: "40px" }}
            >
              INSTITUTE <span style={{ fontStyle: "italic" }}>of</span>{" "}
              BILLIONAIRES
            </h4>
            <h2 className="BackgroundSlideshow_homepage_h2">
              The World’s Most Secretive Institute!
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCompanies
