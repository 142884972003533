import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, AlignRight, X } from "lucide-react";
import "./Navbar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      setScrolled(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Function to toggle submenu visibility
  const toggleSubmenu = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the parent dropdown from closing
    setOpenSubmenu(!openSubmenu);
  };

  const mainCards = [
    { title: "HUNGER-ING", type: "hungering" },
    { title: "NO PLAN B-ING", type: "planing" },
    { title: "UNICORN-ING", type: "unicorning" },
    { title: "BILLIONAIRE-ING", type: "billioaireing" },
    { title: "WAR-ING", type: "warning" },
    { title: "FIN TECH-ING", type: "teching" },
  ];

  return (
    <>
      <div className="main_navbar m-0 p-0">
        <nav
          className={`navbar navbar-expand-xl sticky-top pb-2 pb-md-0 ${
            scrolled ? "scrolled" : ""
          }`}
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              <h4 className="text-white p-0 pt-2 pb-2 ps-0 ps-lg-4 m-0 navbar_h4">
                INSTITUTE
                <span style={{ fontStyle: "italic" }}>&nbsp;of&nbsp;</span>
                BILLIONAIRES
              </h4>
            </Link>
            <button
              className="navbar-toggler border border-white bg-secondary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <AlignRight color="white" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ms-auto">
                {/* Home */}
                <li className="nav-item">
                  <Link className="nav-link d-flex align-items-center" to="/">
                    HOME
                    <div className="vr-line3 ms-2"></div>
                  </Link>
                </li>

                {/* TECHNOLOGY Dropdown */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle d-flex align-items-center"
                    to="#"
                    id="navbarDropdownTechnology"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    TECHNOLOGY <ChevronDown size={16} />
                    <div className="vr-line3 ms-2"></div>
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownTechnology"
                  >
                    <li>
                      <Link className="dropdown-item" to="/saasprojects">
                        SAAS Projects
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/software">
                        Software
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/aiuniversity">
                        AI University
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* OUR PORTALS Dropdown */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle d-flex align-items-center"
                    to="#"
                    id="navbarDropdownPortals"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    OUR PORTALS <ChevronDown size={16} />
                    <div className="vr-line3 ms-2"></div>
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownPortals"
                  >
                    <li>
                      <Link className="dropdown-item" to="/investment-portal">
                        Investment Portal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/advertising-portal">
                        Advertising Portal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admissions-portal">
                        Admission Portal
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ecommerce-portal">
                        Ecommerce Portal
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* CONTACT */}
                <li className="nav-item">
                  <Link
                    className="nav-link d-flex align-items-center"
                    to="/contact"
                  >
                    CONTACT
                    <div className="vr-line3 ms-2"></div>
                  </Link>
                </li>

                {/* REGISTRATION Dropdown */}
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle d-flex align-items-center"
                    to="#"
                    id="navbarDropdownRegistration"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    REGISTRATION <ChevronDown size={16} />
                    <div className="vr-line3 ms-2"></div>
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownRegistration"
                  >
                    <li>
                      <Link className="dropdown-item" to="/login">
                        Login
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/signup">
                        Sign Up
                      </Link>
                    </li>
                  </ul>
                </li>

                <button
                  class="btn btn-danger"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
                >
                  MENU <AlignRight />
                </button>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <div
        class="offcanvas offcanvas-end custom-offcanvas"
        tabindex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
      >
        <div class="offcanvas-header d-flex justify-content-end">
          <button
            type="button"
            class="text-dark border-2 border-white rounded-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <X />
          </button>
        </div>
        <div class="offcanvas-body text-wrap">
          <div class="container">
            <div class="row">
              <div class="col-12 col-md-6 text-white">
                <h5>OUR ECO SYSTEM</h5>
                <ul>
                  <li>
                    <Link className="dropdown-item" to="/schoolAdmission">
                      School of Admissions
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/instituteofmillionaires">
                      Institute of Millionaires
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/ourprograms">
                      Our Programs, The MBA
                    </Link>
                  </li>

                  <li>
                    <Link className="dropdown-item" to="/syndicatedcurriculum">
                      Syndicated Curriculum
                    </Link>
                  </li>
                </ul>

                <h5 className="mt-4">OUR COMPANIES</h5>
                <ul>
                  <li>
                    <Link className="dropdown-item" to="/guaranteecompany">
                      Institute Of Billionaires Guarantee LTD.
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/unicorn-stock-exchange"
                    >
                      Unicorn Stock Exchange
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/unicorn-chamber-Commerce"
                    >
                      Unicorns Chamber of Commerce & Industry
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/billionaires-chamber-Commerce"
                    >
                      Billionaires Chamber of Commerce & Industry
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      Do Intel
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/">
                      HRD Authority
                    </Link>
                  </li>
                </ul>
                <h5 className="mt-4"> BILLIONAIRES UNIVERSITY</h5>
                <ul>
                  <li>
                    <a
                      className="dropdown-item"
                      href="https://thefirstinstitute.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      The First Institute
                    </a>
                  </li>

                  <li>
                    <Link
                      className="dropdown-item"
                      to="/instituteofopportunity"
                    >
                      Institute Of Opportunity
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/instituteofopublic">
                      Institute Of Public Companies
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/instituteofobillionheirs"
                    >
                      Institute Of Billionheirs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/cartel-syndicated-business-school"
                    >
                      Cartel & Syndicate Business School
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/aristocracybusinessschool"
                    >
                      Aristocracy, Business & Command School
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/supplychaininstitute">
                      Supply Chain Institute
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/foundingfatherinstitute"
                    >
                      Institute of Founding Fathers
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/environmentprotectioninstitute"
                    >
                      Institute of Environment Protection & Conservation
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="col-12 col-md-6 text-white">
                <h5>PARTNER INSTITUTIONS</h5>
                <ul style={{ textTransform: "capitalize" }}>
                  <li>
                    <Link className="dropdown-item" to="/yvessaint">
                      Yves Saint Laurent School of Fashion and Style
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/LeonardoDa">
                      Leonardo Da Vinci School of Arts Culture
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/OprahWinfrey">
                      Oprah Winfrey School of Media Public Relations
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/SahibzadaYaqub">
                      Sahibzada Yaqub Khan School of Diplomacy International
                      Relations
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/LinusPauling">
                      Linus Pauling School of Nutrition Health, Institute of
                      Billionaires
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/CesarRitz">
                      Cesar Ritz Butlers School of the Institute of Billionaires
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/ArtificialIntelligence"
                    >
                      Sam Altman AI School of Business
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/GianniAngelli">
                      Gianni Agnelli Finishing School of Institute of
                      Billionaires
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/GatesBuffett">
                      Gates & Buffett School of Philanthropy
                    </Link>
                  </li>
                </ul>

                <h5 className="mt-3">OUR PROGRAMS</h5>
                <ul>
                  {mainCards.map((card, index) => (
                    <li key={index}>
                      <Link
                        className="dropdown-item"
                        to={`/detail/${card.type}`}
                      >
                        {card.title}
                      </Link>
                    </li>
                  ))}
                </ul>

                <h5 className="mt-3">SERVICES CONSULTANCY</h5>
                <ul>
                  <li>
                    <Link className="dropdown-item" to="/revenueenhancement">
                      Revenue Enhancement
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/elemi-nation">
                      Debt Elimination
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/familytraining">
                      Family Trainings
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/economicengineering">
                      Economics Engineering
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
