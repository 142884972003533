import React from "react";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const UnicornChamber = () => {
  return (
    <>
      <div className="chamber_homepage_back">
        <div className="w-100">
          <div className="chamber_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <FirstHeading count="01" />
            <h1 className="heading_h1 text-uppercase m-0 p-0">
              Unicorn Chamber of Commerce and Industry
            </h1>
            <h2 className="heading_h2 text-uppercase">Command the Future</h2>
            <p className="mt-5 feature_homepage_p">
              Unicorn Chamber of Commerce and Industry isn’t a place for
              dreamers—it's a battlefield for disruptors, innovators, and
              relentless visionaries ready to conquer industries and command the
              future. This is where billion-dollar ideas are forged into
              reality, where only the bold survive, and where mediocrity is not
              tolerated. <br />
              <br />
              Positioned as the power hub for innovation, entrepreneurship, and
              high-growth startups, its focus is laser sharp on supporting,
              networking, and scaling companies with the potential to become
              unicorns. This chamber is the go-to place for ambitious
              entrepreneurs and investors looking to create the next
              billion-dollar company.
            </p>
            <p className="feature_homepage_p">
              <h2 className="feature_homepage_h2 mt-5">
                Dominate or Disappear
              </h2>
              In the Unicorn Chamber, you don’t just participate; you dominate.
              This is not about slow, steady growth—it’s about explosive,
              industry-shaking impact. We don’t nurture businesses; we launch
              empires. If you’re not here to lead, to innovate, to redefine
              what’s possible, then you’re in the wrong place.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Empowerment for the Elite
              </h2>
              We empower those who are ready to take what’s theirs, to rise
              above the noise and become the new titans of industry. You’ll get
              access to the networks, the resources, and the strategies needed
              to obliterate your competition and leave your mark on the world.
              This isn’t about joining a chamber; it’s about joining a
              revolution.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Only the Fearless Need Apply
              </h2>
              If you’re ready to stop dreaming and start dominating, the Unicorn
              Chamber of Commerce and Industry is your proving ground. We don’t
              follow trends—we set them. Here, only the fearless thrive.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Welcome to the Unicorn Chamber—command the future, or step
                aside.
              </h2>
              To become a member, please sign in and fill the membership form.
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default UnicornChamber;
