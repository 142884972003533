import { useLocation, useParams } from "react-router-dom";
import BACK from "../../assets/images/investment_portal.webp";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "lucide-react";
import PortalForm from "../../forms/PortalForm";

function OpportunityCountryPage() {
  const { portalType, countryName } = useParams();
  const location = useLocation();
  const flag = location.state?.flag || "";

  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-12">
            <img width="100%" height={500} src={BACK} alt="background" />
          </div>
        </div>
      </div>
      <div
        className="container pb-5 mb-5"
        style={{
          marginTop: "-14%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-md-6 col-12 bg-white">
            <div className="d-flex align-items-center ps-4 py-3">
              <h1 className="portal_homepage_h1 text-uppercase">
                investment portal
              </h1>
            </div>
            <div className="ps-4">
              <h2 className="fellowship text-uppercase">{countryName}</h2>
              <div className="mt-4">
                <p className="feature_homepage_p mt-4">
                  Welcome to the Investment Portal, and is the breeding ground
                  for unicorns and industry dominators. You can opt to becoming
                  an investor, (small, medium or large) or choose being a
                  franchisee, investor or a partner with our students on their
                  ventures, under guarantee. We’re offering you access to
                  high-growth companies, world-changing ventures, and the chance
                  to back the billion-dollar success stories of tomorrow. Don’t
                  watch from the sidelines while others make history. Click
                  below and claim your stake in the next wave of billion-dollar
                  companies.
                  <h2 className="feature_homepage_h2 mt-5">
                    A great investment opportunity guaranteeing highly
                    sustainable and scalable income running into millions of
                    dollars annually.
                  </h2>
                  Ideal for business visionaries, medical professionals,
                  educational institutions, private funds, investment banks,
                  technology companies, foundations, and governments.
                </p>
                <h2 className="heading_h2 text-uppercase">
                  Our Investors’ profile
                </h2>
                <p className="feature_homepage_p">
                  We have divided our investors into the following three types:
                  <h2 className="feature_homepage_h2 mt-3">Investors:</h2>
                  <span className="fw-bold">Small:</span>
                  &nbsp;&nbsp;&nbsp;$1,000-$50,000 for small investors <br />
                  <span className="fw-bold">Medium:</span> $ 50,000-1,000,000
                  for medium sized investors, and
                  <br />
                  <span className="fw-bold">Large:</span> &nbsp;&nbsp;$
                  1,000,000 to $ 100,000,000 dollars and above <br />
                  <h2 className="feature_homepage_h2 mt-3">Partners:</h2>
                  Long-term partnerships with both voting and non-voting rights
                  <h2 className="feature_homepage_h2 mt-3">Franchises:</h2>No
                  capital injection required for franchise opportunities.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 bg-white pt-4">
            <div className="d-flex justify-content-center">
              {flag && (
                <img
                  src={flag}
                  alt={countryName}
                  width="60%"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                />
              )}
            </div>
            <h5
              className="text-center mt-4 me-2"
              style={{ color: "var(--main-red-color)" }}
            >
              Share This Program
            </h5>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div
                className="d-inline-flex align-items-center gap-3 p-2 rounded-2"
                style={{ background: "#002F6C" }}
              >
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Facebook color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Twitter color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Youtube color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Linkedin color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Instagram color="#1B1B1B" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5" />
        <div className="row mt-5 pt-4 d-flex justify-content-center">
          <div className="col-md-6 col-12">
          <h2 className="fellowship text-uppercase">investor form</h2>
            <PortalForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default OpportunityCountryPage;
