import React from "react";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const InstituteofPublicCompanies = () => {
  return (
    <>
      <div className="feature_homepage_back">
        <div className="w-100">
          <div className="feature_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <FirstHeading count="01" />
            <h1 className="heading_h1 text-uppercase m-0 p-0">42/14/730</h1>
            <h2 className="heading_h2 text-uppercase">
              The War Office of the Institute of Public Companies
            </h2>
            <p className="mt-5 feature_homepage_p">
              This is not your typical training program. 42/14/730 of
              24/7/365—that’s what it takes to prepare the leaders of tomorrow’s
              unicorns. Welcome to the Institute of Public Companies, the War
              Office of the Institute of Billionaires, where CEOs aren’t just
              trained—they’re forged in the crucible of relentless, war-like
              intensity. <br />
              <br />
              This is where the battle for the future begins. We don’t just
              teach strategy; we teach survival. Every moment here is a test,
              every challenge a battle, every decision a matter of winning or
              losing on the global stage. Our mission? To mold you into the kind
              of leader who doesn’t just manage a company—who commands it, who
              drives it to dominate markets and define industries.
            </p>
            <p className="feature_homepage_p">
              <h2 className="feature_homepage_h2 mt-5 mt-4">
                The Most Hardcore Training on the Planet
              </h2>
              If you think leadership is about sitting in a corner office, think
              again. This is the real deal—the most brutal, unyielding, hardcore
              training in the world. We don’t prepare you to fit in; we prepare
              you to take over. Our training is a nonstop, all-consuming
              experience that breaks you down and builds you back up stronger,
              sharper, and ready to take on anything.
              <h2 className="feature_homepage_h2 mt-5 mt-4">
                War-Like Intensity – Not for the Faint of Heart
              </h2>
              This isn’t for the faint-hearted or the weak-willed. Our training
              simulates the ruthless realities of leading a public company in a
              world where only the strongest survive. You’ll face relentless
              challenges designed to push you to your limits—and beyond. Every
              day here is a battle, every night a test of your endurance,
              resolve, and relentless drive to win.
              <h2 className="feature_homepage_h2 mt-5 mt-4">
                The Ultimate CEO Forge
              </h2>
              At the Institute of Public Companies, we don’t just create CEOs—we
              create warriors, leaders who thrive under pressure, who turn
              obstacles into opportunities, and who are destined to lead
              billion-dollar companies. This is the place where the future
              titans of industry are born, where ordinary stops and
              extraordinary begins.
              <br />
              If you have the guts to endure the most intense, no-holds-barred
              training on the planet, then step into the War Office. Here, we
              don’t just prepare you for the battlefield—we send you out to
              conquer it.
              <br />
              <br />
              This is not just training. This is war. Are you ready to win?
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default InstituteofPublicCompanies;
