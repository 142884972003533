import React from 'react'
import "./heading.css"

const SecondHeading = (props) => {
    return (
        <>
            <div className="fw-bold d-flex align-items-center gap-2 p-0 m-0">
                {props.count} <div className="heading_hr_line"></div>
            </div>
            <h1 className="d-flex align-items-center heading_h1 gap-3 text-uppercase m-0 p-0">
                {props.title}<div className="heading_hr_line2 w-50"></div>
            </h1>
            <h2 className="gap-2 heading_h2_1 text-uppercase pt-2">{props.tagline}</h2>
        </>
    )
}

export default SecondHeading
