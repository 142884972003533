import React from "react";
import Accordion from "../../../constant/accordion";
import IMG from "../../../assets/images/GatesBuffett_IMG.webp";

const GatesBuffett = () => {
  const accordionData = [
    {
      question:
        "What is the focus of the Gates-Buffett School of Philanthropy & Legacy Building?",
      answer:
        "The school focuses on teaching billionaires how to use their wealth to create long-lasting, positive social impact through strategic philanthropy and legacy building.",
    },
    {
      question: "Why is the school named after Bill Gates and Warren Buffett?",
      answer:
        "Bill Gates and Warren Buffett are two of the world’s most renowned philanthropists, known for their global contributions through initiatives like The Giving Pledge and the Gates Foundation. Their names symbolize impactful, large-scale philanthropy.",
    },
    {
      question: "What will students learn at the Gates-Buffett School?",
      answer:
        "Students will learn how to develop philanthropic strategies, manage charitable foundations, and create legacy-driven programs that address social, educational, and health-related issues on a global scale.",
    },
    {
      question:
        "How does this school fit into the overall mission of the Institute of Billionaires?",
      answer:
        "The Gates-Buffett School aligns with the Institute’s mission by teaching future billionaires how to build a legacy of giving, ensuring their wealth is used to benefit society in meaningful ways.",
    },
    {
      question: "What is strategic philanthropy?",
      answer:
        "Strategic philanthropy involves making thoughtful, well-planned donations that target specific societal issues, with the goal of creating long-term impact rather than just short-term relief.",
    },
    {
      question: "Will students learn about managing philanthropic foundations?",
      answer:
        "Yes, the curriculum covers the management of foundations, including how to establish and run effective philanthropic organizations that operate sustainably and efficiently.",
    },
    {
      question: "What role does legacy building play in philanthropy?",
      answer:
        "Legacy building in philanthropy involves creating long-term programs and initiatives that continue to have a positive impact on society well beyond the philanthropist’s lifetime.",
    },
    {
      question: "Who teaches at the Gates-Buffett School?",
      answer:
        "The school features industry experts in philanthropy, legacy building, and social change, offering students insights from real-world experiences and case studies.",
    },
    {
      question: "How can philanthropy enhance a billionaire’s global standing?",
      answer:
        "Philanthropy helps billionaires strengthen their public image, forge global partnerships, and contribute to solving some of the world’s most pressing issues, which can elevate their influence on a global scale.",
    },
    {
      question:
        "What is The Giving Pledge, and how is it relevant to this school?",
      answer:
        "The Giving Pledge is an initiative co-founded by Gates and Buffett, encouraging billionaires to commit a significant portion of their wealth to philanthropy. The school’s teachings reflect the principles behind this movement, promoting a culture of giving among the ultra-wealthy.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 text-uppercase d-flex BackgroundSlideshow_homepage_h1_1">
              Gates & Buffett School of
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                Philanthropy
                </h1>
                {/* <p className="text-white BackgroundSlideshow_homepage_TM">TM</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Gates & Buffett School of Philanthropy
            </h1>

            <p className="feature_homepage_p pt-4">
            <img
                src={IMG}
                alt="img"
                className="rounded-3"
                style={{
                  float: "right",
                  marginLeft: "15px",
                  width: "500px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              The Gates & Buffett School of Philanthropy at the Institute of
              Billionaires is dedicated to educating future billionaires on how
              to use their wealth to create lasting, positive change in the
              world. Named after two of the most prominent modern
              philanthropists, Bill Gates and Warren Buffett, this school
              focuses on teaching the principles of strategic giving, legacy
              building, and impact-driven philanthropy.
              <br />
              <br />
              Both Gates and Buffett have set a powerful example for
              billionaires, demonstrating how personal wealth can be harnessed
              to tackle the world's most pressing challenges. Through
              initiatives like the Bill & Melinda Gates Foundation and The
              Giving Pledge, they have shown the importance of using wealth to
              improve global health, reduce poverty, and drive educational
              initiatives. At the Gates-Buffett School, students will learn how
              to craft and manage philanthropic foundations, make strategic
              donations, and ensure their efforts create sustainable, long-term
              impact.
              <br />
              <br />
              The curriculum emphasizes ethical responsibility, teaching future
              billionaires how to balance their wealth with societal impact.
              Students will explore real-world case studies of effective
              philanthropy, learn from experts in the field, and develop the
              skills needed to shape their own philanthropic vision. By the time
              they graduate, they will be prepared to build legacies that
              transcend wealth and shape a better world.
            </p>

            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GatesBuffett;
