import React from "react";
import Logo from "../assets/images/logo.png";
import ProfileImg from "../assets/images/list-with-us-image-real.jpg";
import "./list.css";
import { ChevronLeft, ChevronRight } from "lucide-react";

const List = () => {
  return (
    <>
      <div className="list_back">
        <img alt="logo" src={Logo} className="img-fluid" />
      </div>
      <div className="container mt-5">
        <div
          className="row g-0 d-flex justify-content-center pt-4 pb-4"
          style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
        >
          <div className="col-2 p-0 m-0">
            <h6 className="list_hover m-0 p-0">Our Numbers</h6>
          </div>
          <div className="col-2 p-0 m-0">
            <h6 className="list_hover m-0 p-0">Local Exposure</h6>
          </div>
          <div className="col-2 p-0 m-0">
            <h6 className="list_hover m-0 p-0">Press Presence</h6>
          </div>
          <div className="col-2 p-0 m-0">
            <h6 className="list_hover m-0 p-0">Global Exposure</h6>
          </div>
          <div className="col-2 p-0 m-0">
            <h6 className="list_hover m-0 p-0">Industry Recognition</h6>
          </div>
        </div>
        <div className="row g-0 mt-5">
          <div>
            <div className="fw-bold d-flex align-items-center gap-2">
              02 <div className="hr_line3"></div>
            </div>
            <h1
              className="profile_h1 fw-bold d-flex align-items-center gap-2"
              style={{ marginTop: "-1%" }}
            >
              LIST<div className="hr_line4"></div>
            </h1>
            <h2 className="fw-light text-danger ps-5 profile_h2">WITH US</h2>
          </div>
        </div>
        <div className="row g-0 mt-5">
          <div
            className="col-md-7 col-12 bg-white pb-4 pb-lg-0 d-flex justify-content-center align-items-center"
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          >
            <div className="text-white ps-2 ps-lg-5 pe-2 pe-lg-5">
              <p className="text-dark mt-4 mt-lg-0 pt-5">
                This extraordinary concept and education innovation is the
                brainchild of Dr. Zaar who is also the Founder and President of
                The HGR Group’s Education Initiative. An astute economist,
                businessman and a medical doctor with specializations in
                biochemistry and endocrinology, he is also the Avant-Garde of
                education as a reformist. Non-conventional, non-conformist and
                fearless, his extraordinarily imaginative capability has earned
                him the accolades of ‘Albert Einstein of Economics’, both by
                critics and contemporaries alike, merging physics and economics
                into a new area of study he called Econo-Physics and ultimately,
                Quantum Economics. Equally powerful in performing and visual
                arts, he is a stylish Tabla player and one of the world’s top
                calligraphers in English, Arabic and ambidextrous.
              </p>
              <div></div>
            </div>
          </div>
          <div className="col-md-5 col-12 profile_div1">
            <img
              src={ProfileImg}
              alt="JasonOppenheim"
              className="profile-image"
              width="100%"
            />
          </div>
          <div
            className="col-12 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
          >
            <p className="text-dark mt-4 mt-lg-0">
              Dr. Zaar established the Environment and Medical Scientist
              Training Program in 2007, eliminating the redundant information
              from the curriculum. Leading a team of eminent educationists, he
              indoctrinated a ‘skill-based’ education program and brought in the
              environment to be adopted as the most crucial part of the
              curriculum. In 2010, the program was approved by the US Department
              of Education and in the same year, the organization started to
              present it to regulators and governments for its adoption under
              global copyrights. In the same year, his organization, the
              Environment & Health Sciences Education Administration Corporation
              was given the status of an independent Higher Education Commission
              for regulating the Environment & Medical Scientists Training
              Program and of which he serves as the Chairman of the Board. Dr.
              Zaar established the Environment and Medical Scientist Training
              Program in 2007, eliminating the redundant information from the
              curriculum. Leading a team of eminent educationists, he
              indoctrinated a ‘skill-based’ education program and brought in the
              environment to be adopted as the most crucial part of the
              curriculum. In 2010, the program was approved by the US Department
              of Education and in the same year, the organization started to
              present it to regulators and governments for its adoption under
              global copyrights. In the same year, his organization, the
              Environment & Health Sciences Education Administration Corporation
              was given the status of an independent Higher Education Commission
              for regulating the Environment & Medical Scientists Training
              Program and of which he serves as the Chairman of the Board.
            </p>
            <div className="ps-0 ps-lg-3 pe-0 pe-lg-3 pt-5 profile_btns w-25">
              <button className="FEATURED mt-4">LOS ANGELES</button>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ marginTop: "10%" }}>
        <div className="d-flex justify-content-center">
          <div>
            <h1
              className="profile_h1 fw-bold text-center"
              style={{ marginTop: "-1%" }}
            >
              What Our
            </h1>
            <br />
            <h2 className="fw-light text-danger ps-5 profile_h2 d-flex align-items-center gap-2">
              <div className="list_hr"></div>Clients Are Saying
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div
              id="textSlider"
              className="carousel slide text-slider"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="text-slide row">
                    <div className="col-md-6 col-12 border-2 border-secondary border-end">
                      <div class="testimonial text-start">
                        <p class="quote">
                          “Jason’s aggressive advertising and diplomatic
                          communication skills make him the perfect agent. Most
                          of all, we are grateful for his guidance and patience
                          as we navigated such a personal decision. He …”
                        </p>
                        <p class="author">
                          — <strong>Nadira and Chuck Apelian</strong>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div class="testimonial text-start ms-3">
                        <p class="quote">
                          “Jason’s aggressive advertising and diplomatic
                          communication skills make him the perfect agent. Most
                          of all, we are grateful for his guidance and patience
                          as we navigated such a personal decision. He …”
                        </p>
                        <p class="author">
                          — <strong>Nadira and Chuck Apelian</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="text-slide row">
                    <div className="col-md-6 col-12 border-2 border-secondary border-end">
                      <div class="testimonial text-start">
                        <p class="quote">
                          “Jason’s aggressive advertising and diplomatic
                          communication skills make him the perfect agent. Most
                          of all, we are grateful for his guidance and patience
                          as we navigated such a personal decision. He …”
                        </p>
                        <p class="author">
                          — <strong>Nadira and Chuck Apelian</strong>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div class="testimonial text-start ms-3">
                        <p class="quote">
                          “Jason’s aggressive advertising and diplomatic
                          communication skills make him the perfect agent. Most
                          of all, we are grateful for his guidance and patience
                          as we navigated such a personal decision. He …”
                        </p>
                        <p class="author">
                          — <strong>Nadira and Chuck Apelian</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="text-slide row">
                    <div className="col-md-6 col-12 border-2 border-secondary border-end">
                      <div class="testimonial text-start">
                        <p class="quote">
                          “Jason’s aggressive advertising and diplomatic
                          communication skills make him the perfect agent. Most
                          of all, we are grateful for his guidance and patience
                          as we navigated such a personal decision. He …”
                        </p>
                        <p class="author">
                          — <strong>Nadira and Chuck Apelian</strong>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div class="testimonial text-start ms-3">
                        <p class="quote">
                          “Jason’s aggressive advertising and diplomatic
                          communication skills make him the perfect agent. Most
                          of all, we are grateful for his guidance and patience
                          as we navigated such a personal decision. He …”
                        </p>
                        <p class="author">
                          — <strong>Nadira and Chuck Apelian</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-controls pt-5">
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#textSlider"
                  data-bs-slide="prev"
                >
                  <ChevronLeft size={24} />
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#textSlider"
                  data-bs-slide="next"
                >
                  <ChevronRight size={24} />
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default List;
