import { useLocation, useParams } from "react-router-dom";
import BACK from "../../assets/images/uni_back.webp";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "lucide-react";
import FormButton from "../textcomponents/FormButton";

function TraningCountryPage() {
  const { countryName } = useParams();
  const location = useLocation();
  const flag = location.state?.flag || "";

  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-12">
            <img width="100%" height={500} src={BACK} alt="founderimg" />
          </div>
        </div>
      </div>
      <div
        className="container pb-5 mb-5"
        style={{
          marginTop: "-14%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-md-6 col-12 bg-white">
            <div className="jinnah-fellowship d-flex align-items-center ps-4 py-3">
              <span className="m-letter text-capitalize">T</span>
              <span className="text_block text-uppercase">
                raining portal
                <br />
                institute of iob
              </span>
            </div>
            <div className="ps-4">
              <h2 className="fellowship text-uppercase">{countryName}</h2>
              <div className="mt-4">
                <p className="p-0 m-0">
                  <strong className="founder_heading p-0 m-0">
                    Program Name
                  </strong>
                </p>
                <p
                  className="p-0 m-0"
                  style={{ color: "var(--main-red-color)" }}
                >
                  CTO Training
                </p>
              </div>
              <div className="mt-2">
                <p className="p-0 m-0">
                  <strong className="founder_heading p-0 m-0">
                    Time Duration
                  </strong>
                </p>
                <p className="p-0 m-0">
                  <span
                    className="p-0 m-0"
                    style={{ color: "var(--main-red-color)" }}
                  >
                    6 Months
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 bg-white pt-4">
            <div className="d-flex justify-content-center">
              {flag && (
                <img
                  src={flag}
                  alt={countryName}
                  width="60%"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                />
              )}
            </div>
            <h5
              className="text-center mt-4 me-2"
              style={{ color: "var(--main-red-color)" }}
            >
              Share This Program
            </h5>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div
                className="d-inline-flex align-items-center gap-3 p-2 rounded-2"
                style={{ background: "#002F6C" }}
              >
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Facebook color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Twitter color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Youtube color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Linkedin color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Instagram color="#1B1B1B" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5" />
        <div className="row mt-5 pt-4 d-flex justify-content-center">
          <div className="col-md-6 col-12">
            <h2 className="fellowship text-uppercase">admission form</h2>
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Name"
            />
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Email Address"
            />
            <input
              className="form-control mt-3"
              type="number"
              placeholder="Phone Number"
            />
            <div className="mt-5 text-center">
              <FormButton name="Click Now" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TraningCountryPage;
