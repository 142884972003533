import React from "react";
import Layout from "./Layout";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ChatBot from "./components/chatbot/Chatbot";
const App = () => {
  return (
    <>
      <Navbar />
      <Layout />
      <Footer />
      <ChatBot messages={[]} getMessage={(msg) => console.log(msg)} />
    </>
  );
};

export default App;
