import React from "react";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const BillionairesChamber = () => {
  return (
    <>
      <div className="chamber_homepage_back">
        <div className="w-100">
          <div className="chamber_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <FirstHeading count="01" />
            <h1 className="heading_h1 text-uppercase m-0 p-0">
              Billionaires Chamber of Commerce and Industry
            </h1>
            <h2 className="heading_h2 text-uppercase">Command the Future</h2>
            <p className="mt-5 feature_homepage_p">
              The Billionaires Chamber of Commerce and Industry is not for the
              faint-hearted either—it’s for those who have already conquered the
              world and are hungry for more. This is the inner sanctum for those
              who don’t just want to maintain their wealth, but to expand their
              dominion, to leave an indelible mark on history. <br />
              <br />
              Positioned to serve as the elite network for established
              billionaires and high-net-worth individuals with focus on
              legacy-building, wealth management, and influencing global
              industries, this chamber would cater to those who have already
              achieved significant success and are looking to expand their
              influence or invest in new ventures. It is the world’s most
              exclusive chamber with built-in networking events, private
              investment opportunities, think tanks on global economic trends,
              and luxury lifestyle experiences.
            </p>
            <p className="feature_homepage_p">
              <h2 className="feature_homepage_h2 mt-5">
                Absolute Power, Unmatched Influence
              </h2>
              In the Billionaires Chamber, you’re not just powerful—you’re
              untouchable. This is where the world’s elite converge to shape the
              future, to influence global markets, and to ensure their legacy is
              unchallenged. We don’t talk about success here—it’s the bare
              minimum. We’re focused on immortality through impact.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Leave No Stone Unturned
              </h2>
              This chamber isn’t about staying the course—it’s about rewriting
              the rules. We provide the platform, the connections, and the
              opportunities to not just grow your empire but to change the game
              entirely. Here, you’ll find like-minded titans ready to
              collaborate, to strategize, and to dominate every arena they
              enter.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                For Those Who Demand More
              </h2>
              If you’re content with mere success, look elsewhere. The
              Billionaires Chamber of Commerce and Industry is for those who
              refuse to settle, who demand more from life and from themselves.
              Here, you don’t just protect your legacy—you expand it,
              relentlessly.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Welcome to the Billionaires Chamber—where legends aren’t born,
                they’re made.
              </h2>
              To become a member, please sign in and fill the membership form.
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillionairesChamber;
