import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const InstituteOfBillionheirs = () => {
  const accordionData = [
    {
      question: "What is the Institute of BillionHeirs?",
      answer:
        "The Institute of BillionHeirs is an elite institution dedicated to preparing the next generation of billionaires to inherit, manage, and expand their family’s legacy. It focuses on instilling the strategic acumen and leadership skills necessary to command and grow vast fortunes.",
    },
    {
      question: "Who should apply to the Institute of BillionHeirs?",
      answer:
        "This institute is designed for heirs and heiresses who are poised to inherit significant wealth and influence. It is for those who seek to not only preserve their family’s legacy but to elevate it to new heights.",
    },
    {
      question: "What is the primary focus of the curriculum?",
      answer:
        "The curriculum focuses on wealth management, succession planning, strategic leadership, and the integration of modern business practices with aristocratic traditions. The goal is to ensure that heirs are fully prepared to lead their family empires.",
    },
    {
      question:
        "How does the Institute of BillionHeirs differ from other leadership programs?",
      answer:
        "Unlike conventional leadership programs, the Institute of BillionHeirs is tailored specifically for those who will inherit significant wealth. It combines the principles of aristocracy with modern business strategies, ensuring that heirs are equipped to not just maintain but expand their legacy.",
    },
    {
      question: "What role does family play in the Institute of BillionHeirs?",
      answer:
        "Family is at the core of the Institute’s philosophy. We teach that a family that sticks together, hunts together, and operates with a cartel-like mentality is one that will dominate in the business world. The collective power of the family unit is emphasized throughout the program.",
    },
    {
      question: "What kind of support does the Institute provide?",
      answer:
        "The Institute offers mentorship from seasoned experts in wealth management, family governance, and business leadership. This guidance is designed to help heirs navigate the complexities of inheriting and expanding significant wealth.",
    },
    {
      question:
        "What can I expect to achieve at the Institute of BillionHeirs?",
      answer:
        "Graduates will leave the Institute fully prepared to lead their family’s empire with confidence and strategic vision. They will be equipped with the tools and knowledge to ensure their family’s legacy not only survives but thrives for generations to come.",
    },
    {
      question: "How selective is the Institute of BillionHeirs?",
      answer:
        "The Institute is highly selective, admitting only those who demonstrate the potential to be strong leaders and strategic thinkers. Admission is based on a thorough evaluation of an heir’s current capabilities and future potential.",
    },
    {
      question: "What is the Institute’s philosophy on wealth and power?",
      answer:
        "The Institute believes that wealth and power are not merely inherited but must be actively managed and expanded. Our philosophy is that every heir must be a conqueror in their own right, capable of driving their family’s success to new levels.",
    },
    {
      question: "How do I apply to the Institute of BillionHeirs?",
      answer:
        "Admission is by invitation only. Prospective heirs who are ready to take on the responsibility of their family’s legacy may express their interest through our School of Admissions, where their potential will be rigorously assessed.",
    },
  ];

  return (
    <div>
      <div className="Univeristy_back">
        <div className="w-100">
          <div className="Univeristy_back_opacity p-4">
            <div>
              <h1 className="text-white text-uppercase p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  BILLIONHEIRS
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div class="row">
          <div class="col-12">
            <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              INSTITUTE OF BILLIONHEIRS ™
            </h1>
            <h2 class="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              Hunting as a Pack
            </h2>

            <p class="feature_homepage_p pt-4">
              <div class="p-3">
                <img
                  src={IMG}
                  alt="img"
                  class="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $1,185
              <br />
              <strong>Duration:</strong> 2 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> Institute of Public Companies
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 class="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Institute of BillionHeirs, where the legacy of
              wealth and power is not just preserved, but meticulously crafted
              and amplified. This is not merely an institution—it is a sanctum
              for the chosen few, where the next generation of billionaires is
              forged with the precision of a master artisan.
              <br />
              <br />
              At the Institute of BillionHeirs, we understand that inheriting a
              fortune is only the beginning. True heirs must be equipped with
              the vision, discipline, and strategic acumen to not only maintain
              but expand their empires. This is where aristocracy meets
              modernity, where time-honored traditions are infused with
              cutting-edge strategies to ensure that your legacy does not just
              survive—it thrives.
              <br />
              <br />
              Here, we operate with the mindset of a cartel, a syndicate where
              the family bond is as strong as iron, and the collective power of
              the clan is unmatched. The Institute of BillionHeirs is built on
              the principle that a family that sticks together, hunts together,
              and commands the business landscape with unrivaled unity.
              <br />
              <br />
              Our curriculum is designed to instill a deep understanding of
              wealth management, succession planning, and strategic leadership,
              ensuring that each heir is not just a successor but a conqueror in
              their own right. The baton of leadership is passed with the
              confidence that the next generation will not only carry the torch
              but will blaze new trails in the world of global finance and
              industry.
              <br />
              <br />
              Welcome to the Institute of BillionHeirs—where legacies are not
              just inherited but engineered for greatness.
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>

            <hr class="mt-3 mb-3" />
            <h1 class="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div class="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstituteOfBillionheirs;
