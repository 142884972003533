import React from "react";
import { Link } from "react-router-dom";

const FormButton = (props) => {
  return (
    <>
      <div className="pe-3 mt-2 mb-2">
        <Link className="text-decoration-none" to={props.url}>
          <button className="formBtn">{props.name}</button>
        </Link>
      </div>
    </>
  );
};

export default FormButton;
