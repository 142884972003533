import React from "react";
import "./heading.css";

const FirstHeading = (props) => {
  return (
    <>
      <div className="fw-bold d-flex align-items-center gap-2 p-0 m-0">
        {props.count} <div className="heading_hr_line"></div>
      </div>
      <h1 className="heading_h1 text-uppercase m-0 p-0">{props.title}</h1>
      <h2 className="heading_h2 text-uppercase mt-4 mt-lg-0">
        {props.tagline}
      </h2>
    </>
  );
};

export default FirstHeading;
