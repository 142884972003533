export const CountryContent = {
  Afghanistan: {
    officialName: "The IOB-Ahmad Shah Durrani Scholarship for Afghanistan",
    completionTitle:
      "Durrani Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kabul",
    dedicatedTo: "Office of the President of Afghanistan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Albania: {
    officialName: "The IOB-Ismail Qemali Scholarship for Albania",
    completionTitle:
      "Qemali Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tirana",
    dedicatedTo: "Office of the Prime Minister of Albania",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Algeria: {
    officialName: "The IOB-Abdelkader Scholarship for Algeria",
    completionTitle:
      "Abdelkader Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Algiers",
    dedicatedTo: "Office of the President of Algeria",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Andorra: {
    officialName: "The IOB-Charlemagne Scholarship for Andorra",
    completionTitle:
      "Charlemagne Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Andorra la Vella",
    dedicatedTo: "Office of the President of Andorra",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Angola: {
    officialName: "The IOB-Agostinho Neto Scholarship for Angola",
    completionTitle:
      "Neto Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Luanda",
    dedicatedTo: "Office of the President of Angola",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  "Antigua and Barbuda": {
    officialName:
      "The IOB-Vere Cornwall Bird Scholarship for Antigua and Barbuda",
    completionTitle:
      "Bird Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "St. John's",
    dedicatedTo: "Office of the Prime Minister of Antigua and Barbuda",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Argentina: {
    officialName: "The IOB-José de San Martín Scholarship for Argentina",
    completionTitle:
      "San Martín Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Buenos Aires",
    dedicatedTo: "Office of the President of Argentina",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Armenia: {
    officialName: "The IOB-Tigranes the Great Scholarship for Armenia",
    completionTitle:
      "Tigranes Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Yerevan",
    dedicatedTo: "Office of the Prime Minister of Armenia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Australia: {
    officialName: "The IOB-John Curtin Scholarship for Australia",
    completionTitle:
      "Curtin Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Canberra",
    dedicatedTo: "Office of the Prime Minister of Australia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Austria: {
    officialName: "The IOB-Franz Joseph I Scholarship for Austria",
    completionTitle:
      "Joseph Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Vienna",
    dedicatedTo: "Office of the President of Austria",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Azerbaijan: {
    officialName: "The IOB-Heydar Aliyev Scholarship for Azerbaijan",
    completionTitle:
      "Aliyev Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Baku",
    dedicatedTo: "Office of the President of Azerbaijan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Bahamas: {
    officialName: "The IOB-Lynden Pindling Scholarship for the Bahamas",
    completionTitle:
      "Pindling Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Nassau",
    dedicatedTo: "Office of the Prime Minister of the Bahamas",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Bahrain: {
    officialName: "The IOB-Hamad bin Isa Al Khalifa Scholarship for Bahrain",
    completionTitle:
      "Al Khalifa Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Manama",
    dedicatedTo: "Office of the King of Bahrain",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Bangladesh: {
    officialName: "The IOB-Sheikh Mujibur Rahman Scholarship for Bangladesh",
    completionTitle:
      "Rahman Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Dhaka",
    dedicatedTo: "Office of the Prime Minister of Bangladesh",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Barbados: {
    officialName: "The IOB-Errol Barrow Scholarship for Barbados",
    completionTitle:
      "Barrow Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bridgetown",
    dedicatedTo: "Office of the Prime Minister of Barbados",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Belarus: {
    officialName: "The IOB-Minsk Fellowship Scholarship for Belarus",
    completionTitle:
      "Minsk Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Minsk",
    dedicatedTo: "Office of the President of Belarus",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Belgium: {
    officialName: "The IOB-Leopold I Scholarship for Belgium",
    completionTitle:
      "Leopold Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Brussels",
    dedicatedTo: "Office of the King of Belgium",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Belize: {
    officialName: "The IOB-George Cadle Price Scholarship for Belize",
    completionTitle:
      "Price Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Belmopan",
    dedicatedTo: "Office of the Prime Minister of Belize",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Benin: {
    officialName: "The IOB-Mathieu Kérékou Scholarship for Benin",
    completionTitle:
      "Kérékou Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Porto-Novo",
    dedicatedTo: "Office of the President of Benin",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Bhutan: {
    officialName: "The IOB-Jigme Dorji Wangchuck Scholarship for Bhutan",
    completionTitle:
      "Wangchuck Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Thimphu",
    dedicatedTo: "Office of the King of Bhutan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Bolivia: {
    officialName: "The IOB-Simón Bolívar Scholarship for Bolivia",
    completionTitle:
      "Bolívar Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Sucre",
    dedicatedTo: "Office of the President of Bolivia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  BosniaHerzegovina: {
    officialName:
      "The IOB-Alija Izetbegović Scholarship for Bosnia and Herzegovina",
    completionTitle:
      "Izetbegović Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Sarajevo",
    dedicatedTo: "Office of the Presidency of Bosnia and Herzegovina",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Botswana: {
    officialName: "The IOB-Seretse Khama Scholarship for Botswana",
    completionTitle:
      "Khama Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Gaborone",
    dedicatedTo: "Office of the President of Botswana",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Brazil: {
    officialName: "The IOB-Pedro I Scholarship for Brazil",
    completionTitle:
      "Pedro Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Brasília",
    dedicatedTo: "Office of the President of Brazil",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Brunei: {
    officialName: "The IOB-Hassanal Bolkiah Scholarship for Brunei",
    completionTitle:
      "Bolkiah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bandar Seri Begawan",
    dedicatedTo: "Office of the Sultan of Brunei",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Bulgaria: {
    officialName: "The IOB-Ivan Asen II Scholarship for Bulgaria",
    completionTitle:
      "Asen Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Sofia",
    dedicatedTo: "Office of the President of Bulgaria",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  BurkinaFaso: {
    officialName: "The IOB-Thomas Sankara Scholarship for Burkina Faso",
    completionTitle:
      "Sankara Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ouagadougou",
    dedicatedTo: "Office of the President of Burkina Faso",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Burundi: {
    officialName: "The IOB-Prince Louis Rwagasore Scholarship for Burundi",
    completionTitle:
      "Rwagasore Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bujumbura",
    dedicatedTo: "Office of the President of Burundi",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  CaboVerde: {
    officialName: "The IOB-Amílcar Cabral Scholarship for Cabo Verde",
    completionTitle:
      "Cabral Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Praia",
    dedicatedTo: "Office of the President of Cabo Verde",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Cambodia: {
    officialName: "The IOB-Norodom Sihanouk Scholarship for Cambodia",
    completionTitle:
      "Sihanouk Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Phnom Penh",
    dedicatedTo: "Office of the King of Cambodia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Cameroon: {
    officialName: "The IOB-Ahmadou Ahidjo Scholarship for Cameroon",
    completionTitle:
      "Ahidjo Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Yaoundé",
    dedicatedTo: "Office of the President of Cameroon",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Canada: {
    officialName: "The IOB-John A. Macdonald Scholarship for Canada",
    completionTitle:
      "Macdonald Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ottawa",
    dedicatedTo: "Office of the Prime Minister of Canada",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  CentralAfricanRepublic: {
    officialName:
      "The IOB-Barthélemy Boganda Scholarship for Central African Republic",
    completionTitle:
      "Boganda Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bangui",
    dedicatedTo: "Office of the President of the Central African Republic",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Chad: {
    officialName: "The IOB-Idriss Déby Scholarship for Chad",
    completionTitle:
      "Déby Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "N'Djamena",
    dedicatedTo: "Office of the President of Chad",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Chile: {
    officialName: "The IOB-Bernardo O'Higgins Scholarship for Chile",
    completionTitle:
      "O'Higgins Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Santiago",
    dedicatedTo: "Office of the President of Chile",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  China: {
    officialName: "The IOB-Mao Zedong Scholarship for China",
    completionTitle:
      "Mao Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Beijing",
    dedicatedTo: "Office of the President of China",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Colombia: {
    officialName: "The IOB-Simón Bolívar Scholarship for Colombia",
    completionTitle:
      "Bolívar Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bogotá",
    dedicatedTo: "Office of the President of Colombia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Comoros: {
    officialName: "The IOB-Ali Soilih Scholarship for Comoros",
    completionTitle:
      "Soilih Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Moroni",
    dedicatedTo: "Office of the President of Comoros",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Congo: {
    officialName: "The IOB-Denis Sassou Nguesso Scholarship for Congo",
    completionTitle:
      "Nguesso Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Brazzaville",
    dedicatedTo: "Office of the President of Congo",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  CostaRica: {
    officialName: "The IOB-José Figueres Ferrer Scholarship for Costa Rica",
    completionTitle:
      "Ferrer Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "San José",
    dedicatedTo: "Office of the President of Costa Rica",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Croatia: {
    officialName: "The IOB-Franjo Tuđman Scholarship for Croatia",
    completionTitle:
      "Tuđman Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Zagreb",
    dedicatedTo: "Office of the President of Croatia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Cuba: {
    officialName: "The IOB-José Martí Scholarship for Cuba",
    completionTitle:
      "Martí Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Havana",
    dedicatedTo: "Office of the President of Cuba",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Cyprus: {
    officialName: "The IOB-Makarios III Scholarship for Cyprus",
    completionTitle:
      "Makarios Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Nicosia",
    dedicatedTo: "Office of the President of Cyprus",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  CzechRepublic: {
    officialName: "The IOB-Tomáš Masaryk Scholarship for Czech Republic",
    completionTitle:
      "Masaryk Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Prague",
    dedicatedTo: "Office of the President of the Czech Republic",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Djibouti: {
    officialName: "The IOB-Hassan Gouled Aptidon Scholarship for Djibouti",
    completionTitle:
      "Aptidon Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Djibouti City",
    dedicatedTo: "Office of the President of Djibouti",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Dominica: {
    officialName: "The IOB-Roosevelt Douglas Scholarship for Dominica",
    completionTitle:
      "Douglas Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Roseau",
    dedicatedTo: "Office of the Prime Minister of Dominica",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  DominicanRepublic: {
    officialName:
      "The IOB-Juan Pablo Duarte Scholarship for the Dominican Republic",
    completionTitle:
      "Duarte Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Santo Domingo",
    dedicatedTo: "Office of the President of the Dominican Republic",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  EastTimor: {
    officialName: "The IOB-Xanana Gusmão Scholarship for East Timor",
    completionTitle:
      "Gusmão Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Dili",
    dedicatedTo: "Office of the President of East Timor",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Ecuador: {
    officialName: "The IOB-Simón Bolívar Scholarship for Ecuador",
    completionTitle:
      "Bolívar Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Quito",
    dedicatedTo: "Office of the President of Ecuador",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Egypt: {
    officialName: "The IOB-Naguib Mahfouz Scholarship for Egypt",
    completionTitle:
      "Mahfouz Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Cairo",
    dedicatedTo: "Office of the President of Egypt",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  ElSalvador: {
    officialName: "The IOB-José Matías Delgado Scholarship for El Salvador",
    completionTitle:
      "Delgado Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "San Salvador",
    dedicatedTo: "Office of the President of El Salvador",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  EquatorialGuinea: {
    officialName:
      "The IOB-Teodoro Obiang Nguema Mbasogo Scholarship for Equatorial Guinea",
    completionTitle:
      "Nguema Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Malabo",
    dedicatedTo: "Office of the President of Equatorial Guinea",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Eritrea: {
    officialName: "The IOB-Isaias Afwerki Scholarship for Eritrea",
    completionTitle:
      "Afwerki Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Asmara",
    dedicatedTo: "Office of the President of Eritrea",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Estonia: {
    officialName: "The IOB-Lennart Meri Scholarship for Estonia",
    completionTitle:
      "Meri Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tallinn",
    dedicatedTo: "Office of the President of Estonia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Eswatini: {
    officialName: "The IOB-King Sobhuza II Scholarship for Eswatini",
    completionTitle:
      "Sobhuza Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Mbabane",
    dedicatedTo: "Office of the King of Eswatini",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Ethiopia: {
    officialName: "The IOB-Haile Selassie Scholarship for Ethiopia",
    completionTitle:
      "Selassie Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Addis Ababa",
    dedicatedTo: "Office of the Prime Minister of Ethiopia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Fiji: {
    officialName: "The IOB-Ratu Sir Lala Sukuna Scholarship for Fiji",
    completionTitle:
      "Sukuna Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Suva",
    dedicatedTo: "Office of the Prime Minister of Fiji",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Finland: {
    officialName: "The IOB-Carl Gustaf Emil Mannerheim Scholarship for Finland",
    completionTitle:
      "Mannerheim Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Helsinki",
    dedicatedTo: "Office of the Prime Minister of Finland",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  France: {
    officialName: "The IOB-Charles de Gaulle Scholarship for France",
    completionTitle:
      "De Gaulle Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Paris",
    dedicatedTo: "Office of the President of France",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Gabon: {
    officialName: "The IOB-Léon M'ba Scholarship for Gabon",
    completionTitle:
      "M'ba Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Libreville",
    dedicatedTo: "Office of the President of Gabon",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Gambia: {
    officialName: "The IOB-Dawda Jawara Scholarship for Gambia",
    completionTitle:
      "Jawara Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Banjul",
    dedicatedTo: "Office of the President of Gambia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Georgia: {
    officialName: "The IOB-George V of Georgia Scholarship for Georgia",
    completionTitle:
      "George Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tbilisi",
    dedicatedTo: "Office of the President of Georgia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Germany: {
    officialName: "The IOB-Konrad Adenauer Scholarship for Germany",
    completionTitle:
      "Adenauer Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Berlin",
    dedicatedTo: "Office of the Chancellor of Germany",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Ghana: {
    officialName: "The IOB-Kwame Nkrumah Scholarship for Ghana",
    completionTitle:
      "Nkrumah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Accra",
    dedicatedTo: "Office of the President of Ghana",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Greece: {
    officialName: "The IOB-Eleftherios Venizelos Scholarship for Greece",
    completionTitle:
      "Venizelos Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Athens",
    dedicatedTo: "Office of the Prime Minister of Greece",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Grenada: {
    officialName: "The IOB-Sir Eric Gairy Scholarship for Grenada",
    completionTitle:
      "Gairy Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "St. George's",
    dedicatedTo: "Office of the Prime Minister of Grenada",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Guatemala: {
    officialName: "The IOB-Justo Rufino Barrios Scholarship for Guatemala",
    completionTitle:
      "Barrios Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Guatemala City",
    dedicatedTo: "Office of the President of Guatemala",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Guinea: {
    officialName: "The IOB-Ahmed Sékou Touré Scholarship for Guinea",
    completionTitle:
      "Touré Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Conakry",
    dedicatedTo: "Office of the President of Guinea",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  GuineaBissau: {
    officialName: "The IOB-Amílcar Cabral Scholarship for Guinea-Bissau",
    completionTitle:
      "Cabral Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bissau",
    dedicatedTo: "Office of the President of Guinea-Bissau",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Guyana: {
    officialName: "The IOB-Cheddi Jagan Scholarship for Guyana",
    completionTitle:
      "Jagan Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Georgetown",
    dedicatedTo: "Office of the President of Guyana",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Haiti: {
    officialName: "The IOB-Jean-Jacques Dessalines Scholarship for Haiti",
    completionTitle:
      "Dessalines Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Port-au-Prince",
    dedicatedTo: "Office of the President of Haiti",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Honduras: {
    officialName: "The IOB-Francisco Morazán Scholarship for Honduras",
    completionTitle:
      "Morazán Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tegucigalpa",
    dedicatedTo: "Office of the President of Honduras",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Hungary: {
    officialName: "The IOB-Lajos Kossuth Scholarship for Hungary",
    completionTitle:
      "Kossuth Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Budapest",
    dedicatedTo: "Office of the Prime Minister of Hungary",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Iceland: {
    officialName: "The IOB-Jón Sigurðsson Scholarship for Iceland",
    completionTitle:
      "Sigurðsson Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Reykjavík",
    dedicatedTo: "Office of the Prime Minister of Iceland",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  India: {
    officialName: "The IOB-Mahatma Gandhi Scholarship for India",
    completionTitle:
      "Gandhi Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "New Delhi",
    dedicatedTo: "Office of the Prime Minister of India",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Indonesia: {
    officialName: "The IOB-Sukarno Scholarship for Indonesia",
    completionTitle:
      "Sukarno Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Jakarta",
    dedicatedTo: "Office of the President of Indonesia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Iran: {
    officialName: "The IOB-Mohammad Mossadegh Scholarship for Iran",
    completionTitle:
      "Mossadegh Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tehran",
    dedicatedTo: "Office of the President of Iran",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Iraq: {
    officialName: "The IOB-King Faisal I Scholarship for Iraq",
    completionTitle:
      "Faisal Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Baghdad",
    dedicatedTo: "Office of the President of Iraq",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Ireland: {
    officialName: "The IOB-Michael Collins Scholarship for Ireland",
    completionTitle:
      "Collins Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Dublin",
    dedicatedTo: "Office of the Prime Minister of Ireland",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Israel: {
    officialName: "The IOB-David Ben-Gurion Scholarship for Israel",
    completionTitle:
      "Ben-Gurion Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Jerusalem",
    dedicatedTo: "Office of the Prime Minister of Israel",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Italy: {
    officialName: "The IOB-Giuseppe Garibaldi Scholarship for Italy",
    completionTitle:
      "Garibaldi Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Rome",
    dedicatedTo: "Office of the Prime Minister of Italy",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Jamaica: {
    officialName: "The IOB-Alexander Bustamante Scholarship for Jamaica",
    completionTitle:
      "Bustamante Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kingston",
    dedicatedTo: "Office of the Prime Minister of Jamaica",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Japan: {
    officialName: "The IOB-Emperor Meiji Scholarship for Japan",
    completionTitle:
      "Meiji Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tokyo",
    dedicatedTo: "Office of the Prime Minister of Japan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Jordan: {
    officialName: "The IOB-King Abdullah I Scholarship for Jordan",
    completionTitle:
      "Abdullah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Amman",
    dedicatedTo: "Office of the King of Jordan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Kazakhstan: {
    officialName: "The IOB-Nursultan Nazarbayev Scholarship for Kazakhstan",
    completionTitle:
      "Nazarbayev Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Nur-Sultan",
    dedicatedTo: "Office of the President of Kazakhstan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Kenya: {
    officialName: "The IOB-Jomo Kenyatta Scholarship for Kenya",
    completionTitle:
      "Kenyatta Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Nairobi",
    dedicatedTo: "Office of the President of Kenya",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Kiribati: {
    officialName: "The IOB-Ieremia Tabai Scholarship for Kiribati",
    completionTitle:
      "Tabai Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "South Tarawa",
    dedicatedTo: "Office of the President of Kiribati",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  NorthKorea: {
    officialName: "The IOB-Kim Il-sung Scholarship for North Korea",
    completionTitle:
      "Kim Il-sung Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Pyongyang",
    dedicatedTo: "Office of the Supreme Leader of North Korea",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SouthKorea: {
    officialName: "The IOB-Syngman Rhee Scholarship for South Korea",
    completionTitle:
      "Rhee Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Seoul",
    dedicatedTo: "Office of the President of South Korea",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Kosovo: {
    officialName: "The IOB-Ibrahim Rugova Scholarship for Kosovo",
    completionTitle:
      "Rugova Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Pristina",
    dedicatedTo: "Office of the President of Kosovo",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Kuwait: {
    officialName:
      "The IOB-Sheikh Abdullah Al-Salim Al-Sabah Scholarship for Kuwait",
    completionTitle:
      "Al-Sabah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kuwait City",
    dedicatedTo: "Office of the Emir of Kuwait",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Kyrgyzstan: {
    officialName: "The IOB-Askar Akayev Scholarship for Kyrgyzstan",
    completionTitle:
      "Akayev Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bishkek",
    dedicatedTo: "Office of the President of Kyrgyzstan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Laos: {
    officialName: "The IOB-Kaysone Phomvihane Scholarship for Laos",
    completionTitle:
      "Phomvihane Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Vientiane",
    dedicatedTo: "Office of the President of Laos",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Latvia: {
    officialName: "The IOB-Jānis Čakste Scholarship for Latvia",
    completionTitle:
      "Čakste Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Riga",
    dedicatedTo: "Office of the President of Latvia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Lebanon: {
    officialName: "The IOB-Riad Al Solh Scholarship for Lebanon",
    completionTitle:
      "Al Solh Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Beirut",
    dedicatedTo: "Office of the Prime Minister of Lebanon",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Lesotho: {
    officialName: "The IOB-King Moshoeshoe I Scholarship for Lesotho",
    completionTitle:
      "Moshoeshoe Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Maseru",
    dedicatedTo: "Office of the Prime Minister of Lesotho",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Liberia: {
    officialName: "The IOB-Joseph Jenkins Roberts Scholarship for Liberia",
    completionTitle:
      "Roberts Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Monrovia",
    dedicatedTo: "Office of the President of Liberia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Libya: {
    officialName: "The IOB-King Idris Scholarship for Libya",
    completionTitle:
      "Idris Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tripoli",
    dedicatedTo: "Office of the Prime Minister of Libya",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Liechtenstein: {
    officialName:
      "The IOB-Prince Johann I Joseph Scholarship for Liechtenstein",
    completionTitle:
      "Johann Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Vaduz",
    dedicatedTo: "Office of the Prince of Liechtenstein",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Lithuania: {
    officialName: "The IOB-Jonas Basanavičius Scholarship for Lithuania",
    completionTitle:
      "Basanavičius Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Vilnius",
    dedicatedTo: "Office of the President of Lithuania",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Luxembourg: {
    officialName: "The IOB-Grand Duke Jean Scholarship for Luxembourg",
    completionTitle:
      "Jean Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Luxembourg City",
    dedicatedTo: "Office of the Prime Minister of Luxembourg",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Madagascar: {
    officialName: "The IOB-Philippe Tsiranana Scholarship for Madagascar",
    completionTitle:
      "Tsiranana Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Antananarivo",
    dedicatedTo: "Office of the President of Madagascar",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Malawi: {
    officialName: "The IOB-Hastings Kamuzu Banda Scholarship for Malawi",
    completionTitle:
      "Banda Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Lilongwe",
    dedicatedTo: "Office of the President of Malawi",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Malaysia: {
    officialName: "The IOB-Tunku Abdul Rahman Scholarship for Malaysia",
    completionTitle:
      "Abdul Rahman Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kuala Lumpur",
    dedicatedTo: "Office of the Prime Minister of Malaysia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Maldives: {
    officialName: "The IOB-Ibrahim Nasir Scholarship for Maldives",
    completionTitle:
      "Nasir Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Malé",
    dedicatedTo: "Office of the President of Maldives",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Mali: {
    officialName: "The IOB-Modibo Keïta Scholarship for Mali",
    completionTitle:
      "Keïta Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bamako",
    dedicatedTo: "Office of the President of Mali",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Malta: {
    officialName: "The IOB-George Borg Olivier Scholarship for Malta",
    completionTitle:
      "Borg Olivier Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Valletta",
    dedicatedTo: "Office of the Prime Minister of Malta",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  MarshallIslands: {
    officialName: "The IOB-Amata Kabua Scholarship for Marshall Islands",
    completionTitle:
      "Kabua Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Majuro",
    dedicatedTo: "Office of the President of Marshall Islands",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Mauritania: {
    officialName: "The IOB-Moktar Ould Daddah Scholarship for Mauritania",
    completionTitle:
      "Ould Daddah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Nouakchott",
    dedicatedTo: "Office of the President of Mauritania",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Mauritius: {
    officialName: "The IOB-Sir Seewoosagur Ramgoolam Scholarship for Mauritius",
    completionTitle:
      "Ramgoolam Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Port Louis",
    dedicatedTo: "Office of the Prime Minister of Mauritius",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Mexico: {
    officialName: "The IOB-Benito Juárez Scholarship for Mexico",
    completionTitle:
      "Juárez Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Mexico City",
    dedicatedTo: "Office of the President of Mexico",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Micronesia: {
    officialName: "The IOB-Tosiwo Nakayama Scholarship for Micronesia",
    completionTitle:
      "Nakayama Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Palikir",
    dedicatedTo: "Office of the President of Micronesia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Moldova: {
    officialName: "The IOB-Miron Costin Scholarship for Moldova",
    completionTitle:
      "Costin Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Chișinău",
    dedicatedTo: "Office of the President of Moldova",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Monaco: {
    officialName: "The IOB-Prince Rainier III Scholarship for Monaco",
    completionTitle:
      "Rainier Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Monaco",
    dedicatedTo: "Office of the Prince of Monaco",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Mongolia: {
    officialName: "The IOB-Damdin Sükhbaatar Scholarship for Mongolia",
    completionTitle:
      "Sükhbaatar Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ulaanbaatar",
    dedicatedTo: "Office of the President of Mongolia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Montenegro: {
    officialName: "The IOB-King Nikola I Scholarship for Montenegro",
    completionTitle:
      "Nikola Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Podgorica",
    dedicatedTo: "Office of the President of Montenegro",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Morocco: {
    officialName: "The IOB-King Mohammed V Scholarship for Morocco",
    completionTitle:
      "Mohammed Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Rabat",
    dedicatedTo: "Office of the King of Morocco",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Mozambique: {
    officialName: "The IOB-Samora Machel Scholarship for Mozambique",
    completionTitle:
      "Machel Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Maputo",
    dedicatedTo: "Office of the President of Mozambique",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Myanmar: {
    officialName: "The IOB-General Aung San Scholarship for Myanmar",
    completionTitle:
      "Aung San Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Naypyidaw",
    dedicatedTo: "Office of the President of Myanmar",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Namibia: {
    officialName: "The IOB-Sam Nujoma Scholarship for Namibia",
    completionTitle:
      "Nujoma Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Windhoek",
    dedicatedTo: "Office of the President of Namibia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Nauru: {
    officialName: "The IOB-Hammer DeRoburt Scholarship for Nauru",
    completionTitle:
      "DeRoburt Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Yaren",
    dedicatedTo: "Office of the President of Nauru",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Nepal: {
    officialName: "The IOB-Prithvi Narayan Shah Scholarship for Nepal",
    completionTitle:
      "Shah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kathmandu",
    dedicatedTo: "Office of the Prime Minister of Nepal",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Netherlands: {
    officialName: "The IOB-William of Orange Scholarship for Netherlands",
    completionTitle:
      "William Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Amsterdam",
    dedicatedTo: "Office of the Prime Minister of the Netherlands",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  NewZealand: {
    officialName: "The IOB-Michael Joseph Savage Scholarship for New Zealand",
    completionTitle:
      "Savage Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Wellington",
    dedicatedTo: "Office of the Prime Minister of New Zealand",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Nicaragua: {
    officialName: "The IOB-Augusto César Sandino Scholarship for Nicaragua",
    completionTitle:
      "Sandino Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Managua",
    dedicatedTo: "Office of the President of Nicaragua",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Niger: {
    officialName: "The IOB-Hamani Diori Scholarship for Niger",
    completionTitle:
      "Diori Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Niamey",
    dedicatedTo: "Office of the President of Niger",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Nigeria: {
    officialName: "The IOB-Nnamdi Azikiwe Scholarship for Nigeria",
    completionTitle:
      "Azikiwe Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Abuja",
    dedicatedTo: "Office of the President of Nigeria",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  NorthMacedonia: {
    officialName:
      "The IOB-Gligorije 'Gligor' Čemerski Scholarship for North Macedonia",
    completionTitle:
      "Čemerski Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Skopje",
    dedicatedTo: "Office of the Prime Minister of North Macedonia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Norway: {
    officialName: "The IOB-Haakon VII Scholarship for Norway",
    completionTitle:
      "Haakon Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Oslo",
    dedicatedTo: "Office of the Prime Minister of Norway",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Oman: {
    officialName: "The IOB-Sultan Qaboos bin Said Scholarship for Oman",
    completionTitle:
      "Qaboos Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Muscat",
    dedicatedTo: "Office of the Sultan of Oman",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Pakistan: {
    officialName: "The IOB-Muhammad Ali Jinnah Scholarship for Pakistan",
    completionTitle:
      "Jinnah Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Islamabad",
    dedicatedTo: "Office of the Prime Minister of Pakistan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Palau: {
    officialName: "The IOB-Thomas Remengesau Scholarship for Palau",
    completionTitle:
      "Remengesau Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ngerulmud",
    dedicatedTo: "Office of the President of Palau",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Panama: {
    officialName: "The IOB-Manuel Amador Guerrero Scholarship for Panama",
    completionTitle:
      "Guerrero Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Panama City",
    dedicatedTo: "Office of the President of Panama",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  PapuaNewGuinea: {
    officialName: "The IOB-Michael Somare Scholarship for Papua New Guinea",
    completionTitle:
      "Somare Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Port Moresby",
    dedicatedTo: "Office of the Prime Minister of Papua New Guinea",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Paraguay: {
    officialName:
      "The IOB-José Gaspar Rodríguez de Francia Scholarship for Paraguay",
    completionTitle:
      "Rodríguez de Francia Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Asunción",
    dedicatedTo: "Office of the President of Paraguay",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Peru: {
    officialName: "The IOB-José de San Martín Scholarship for Peru",
    completionTitle:
      "San Martín Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Lima",
    dedicatedTo: "Office of the President of Peru",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Philippines: {
    officialName: "The IOB-José Rizal Scholarship for the Philippines",
    completionTitle:
      "Rizal Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Manila",
    dedicatedTo: "Office of the President of the Philippines",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Poland: {
    officialName: "The IOB-Józef Piłsudski Scholarship for Poland",
    completionTitle:
      "Piłsudski Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Warsaw",
    dedicatedTo: "Office of the Prime Minister of Poland",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Portugal: {
    officialName: "The IOB-Afonso I Scholarship for Portugal",
    completionTitle:
      "Afonso Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Lisbon",
    dedicatedTo: "Office of the Prime Minister of Portugal",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Qatar: {
    officialName:
      "The IOB-Sheikh Jassim bin Mohammed Al Thani Scholarship for Qatar",
    completionTitle:
      "Al Thani Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Doha",
    dedicatedTo: "Office of the Emir of Qatar",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Romania: {
    officialName: "The IOB-King Carol I Scholarship for Romania",
    completionTitle:
      "Carol Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bucharest",
    dedicatedTo: "Office of the President of Romania",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Russia: {
    officialName: "The IOB-Vladimir Lenin Scholarship for Russia",
    completionTitle:
      "Lenin Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Moscow",
    dedicatedTo: "Office of the President of Russia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Rwanda: {
    officialName: "The IOB-Paul Kagame Scholarship for Rwanda",
    completionTitle:
      "Kagame Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kigali",
    dedicatedTo: "Office of the President of Rwanda",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SaintKittsAndNevis: {
    officialName:
      "The IOB-Robert Llewellyn Bradshaw Scholarship for Saint Kitts and Nevis",
    completionTitle:
      "Bradshaw Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Basseterre",
    dedicatedTo: "Office of the Prime Minister of Saint Kitts and Nevis",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SaintLucia: {
    officialName: "The IOB-Sir John Compton Scholarship for Saint Lucia",
    completionTitle:
      "Compton Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Castries",
    dedicatedTo: "Office of the Prime Minister of Saint Lucia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SaintVincentAndGrenadines: {
    officialName:
      "The IOB-Ebenezer Theodore Joshua Scholarship for Saint Vincent and the Grenadines",
    completionTitle:
      "Joshua Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kingstown",
    dedicatedTo:
      "Office of the Prime Minister of Saint Vincent and the Grenadines",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Samoa: {
    officialName: "The IOB-Malietoa Tanumafili II Scholarship for Samoa",
    completionTitle:
      "Tanumafili Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Apia",
    dedicatedTo: "Office of the Prime Minister of Samoa",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SanMarino: {
    officialName:
      "The IOB-Giovanni Battista Belluzzi Scholarship for San Marino",
    completionTitle:
      "Belluzzi Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "San Marino",
    dedicatedTo: "Office of the Captains Regent of San Marino",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SaoTomeAndPrincipe: {
    officialName:
      "The IOB-Manuel Pinto da Costa Scholarship for Sao Tome and Principe",
    completionTitle:
      "Pinto da Costa Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "São Tomé",
    dedicatedTo: "Office of the President of Sao Tome and Principe",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SaudiArabia: {
    officialName: "The IOB-King Abdulaziz Al Saud Scholarship for Saudi Arabia",
    completionTitle:
      "Abdulaziz Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Riyadh",
    dedicatedTo: "Office of the King of Saudi Arabia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Senegal: {
    officialName: "The IOB-Léopold Sédar Senghor Scholarship for Senegal",
    completionTitle:
      "Senghor Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Dakar",
    dedicatedTo: "Office of the President of Senegal",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Serbia: {
    officialName: "The IOB-Aleksandar Karađorđević Scholarship for Serbia",
    completionTitle:
      "Karađorđević Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Belgrade",
    dedicatedTo: "Office of the Prime Minister of Serbia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Seychelles: {
    officialName: "The IOB-James Mancham Scholarship for Seychelles",
    completionTitle:
      "Mancham Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Victoria",
    dedicatedTo: "Office of the President of Seychelles",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SierraLeone: {
    officialName: "The IOB-Milton Margai Scholarship for Sierra Leone",
    completionTitle:
      "Margai Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Freetown",
    dedicatedTo: "Office of the President of Sierra Leone",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Singapore: {
    officialName: "The IOB-Lee Kuan Yew Scholarship for Singapore",
    completionTitle:
      "Lee Kuan Yew Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Singapore",
    dedicatedTo: "Office of the Prime Minister of Singapore",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Slovakia: {
    officialName: "The IOB-Milan Rastislav Štefánik Scholarship for Slovakia",
    completionTitle:
      "Štefánik Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bratislava",
    dedicatedTo: "Office of the Prime Minister of Slovakia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Slovenia: {
    officialName: "The IOB-Josip Vidmar Scholarship for Slovenia",
    completionTitle:
      "Vidmar Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ljubljana",
    dedicatedTo: "Office of the Prime Minister of Slovenia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SolomonIslands: {
    officialName: "The IOB-Peter Kenilorea Scholarship for Solomon Islands",
    completionTitle:
      "Kenilorea Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Honiara",
    dedicatedTo: "Office of the Prime Minister of Solomon Islands",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Somalia: {
    officialName: "The IOB-Siad Barre Scholarship for Somalia",
    completionTitle:
      "Barre Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Mogadishu",
    dedicatedTo: "Office of the President of Somalia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SouthAfrica: {
    officialName: "The IOB-Nelson Mandela Scholarship for South Africa",
    completionTitle:
      "Mandela Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Pretoria",
    dedicatedTo: "Office of the President of South Africa",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SouthSudan: {
    officialName: "The IOB-John Garang Scholarship for South Sudan",
    completionTitle:
      "Garang Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Juba",
    dedicatedTo: "Office of the President of South Sudan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Spain: {
    officialName: "The IOB-King Juan Carlos I Scholarship for Spain",
    completionTitle:
      "Juan Carlos Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Madrid",
    dedicatedTo: "Office of the Prime Minister of Spain",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  SriLanka: {
    officialName: "The IOB-S.W.R.D. Bandaranaike Scholarship for Sri Lanka",
    completionTitle:
      "Bandaranaike Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Colombo",
    dedicatedTo: "Office of the President of Sri Lanka",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Sudan: {
    officialName: "The IOB-Ismail al-Azhari Scholarship for Sudan",
    completionTitle:
      "al-Azhari Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Khartoum",
    dedicatedTo: "Office of the President of Sudan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Suriname: {
    officialName: "The IOB-Johan Adolf Pengel Scholarship for Suriname",
    completionTitle:
      "Pengel Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Paramaribo",
    dedicatedTo: "Office of the President of Suriname",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Sweden: {
    officialName: "The IOB-King Gustav V Scholarship for Sweden",
    completionTitle:
      "Gustav Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Stockholm",
    dedicatedTo: "Office of the Prime Minister of Sweden",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Switzerland: {
    officialName: "The IOB-Henri Dufour Scholarship for Switzerland",
    completionTitle:
      "Dufour Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bern",
    dedicatedTo: "Office of the President of Switzerland",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Syria: {
    officialName: "The IOB-Shukri al-Quwatli Scholarship for Syria",
    completionTitle:
      "al-Quwatli Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Damascus",
    dedicatedTo: "Office of the President of Syria",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Taiwan: {
    officialName: "The IOB-Chiang Kai-shek Scholarship for Taiwan",
    completionTitle:
      "Chiang Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Taipei",
    dedicatedTo: "Office of the President of Taiwan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Tajikistan: {
    officialName: "The IOB-Emomali Rahmon Scholarship for Tajikistan",
    completionTitle:
      "Rahmon Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Dushanbe",
    dedicatedTo: "Office of the President of Tajikistan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Tanzania: {
    officialName: "The IOB-Julius Nyerere Scholarship for Tanzania",
    completionTitle:
      "Nyerere Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Dodoma",
    dedicatedTo: "Office of the President of Tanzania",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Thailand: {
    officialName: "The IOB-King Rama IX Scholarship for Thailand",
    completionTitle:
      "Rama IX Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Bangkok",
    dedicatedTo: "Office of the Prime Minister of Thailand",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Togo: {
    officialName: "The IOB-Sylvanus Olympio Scholarship for Togo",
    completionTitle:
      "Olympio Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Lomé",
    dedicatedTo: "Office of the President of Togo",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Tonga: {
    officialName: "The IOB-King George Tupou I Scholarship for Tonga",
    completionTitle:
      "Tupou Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Nukuʻalofa",
    dedicatedTo: "Office of the Prime Minister of Tonga",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  TrinidadAndTobago: {
    officialName: "The IOB-Eric Williams Scholarship for Trinidad and Tobago",
    completionTitle:
      "Williams Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Port of Spain",
    dedicatedTo: "Office of the Prime Minister of Trinidad and Tobago",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Tunisia: {
    officialName: "The IOB-Habib Bourguiba Scholarship for Tunisia",
    completionTitle:
      "Bourguiba Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tunis",
    dedicatedTo: "Office of the President of Tunisia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Turkey: {
    officialName: "The IOB-Mustafa Kemal Atatürk Scholarship for Turkey",
    completionTitle:
      "Atatürk Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ankara",
    dedicatedTo: "Office of the President of Turkey",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Turkmenistan: {
    officialName: "The IOB-Saparmurat Niyazov Scholarship for Turkmenistan",
    completionTitle:
      "Niyazov Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Ashgabat",
    dedicatedTo: "Office of the President of Turkmenistan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Tuvalu: {
    officialName: "The IOB-Toaripi Lauti Scholarship for Tuvalu",
    completionTitle:
      "Lauti Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Funafuti",
    dedicatedTo: "Office of the Prime Minister of Tuvalu",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Uganda: {
    officialName: "The IOB-Milton Obote Scholarship for Uganda",
    completionTitle:
      "Obote Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kampala",
    dedicatedTo: "Office of the President of Uganda",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Ukraine: {
    officialName: "The IOB-Volodymyr Zelensky Scholarship for Ukraine",
    completionTitle:
      "Zelensky Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Kyiv",
    dedicatedTo: "Office of the President of Ukraine",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  UnitedArabEmirates: {
    officialName:
      "The IOB-Sheikh Zayed bin Sultan Al Nahyan Scholarship for the United Arab Emirates",
    completionTitle:
      "Zayed Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Abu Dhabi",
    dedicatedTo: "Office of the President of the United Arab Emirates",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  UnitedKingdom: {
    officialName:
      "The IOB-Winston Churchill Scholarship for the United Kingdom",
    completionTitle:
      "Churchill Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "London",
    dedicatedTo: "Office of the Prime Minister of the United Kingdom",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  UnitedStates: {
    officialName: "The IOB-George Washington Scholarship for the United States",
    completionTitle:
      "Washington Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Washington, D.C.",
    dedicatedTo: "Office of the President of the United States",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Uruguay: {
    officialName: "The IOB-José Gervasio Artigas Scholarship for Uruguay",
    completionTitle:
      "Artigas Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Montevideo",
    dedicatedTo: "Office of the President of Uruguay",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Uzbekistan: {
    officialName: "The IOB-Islam Karimov Scholarship for Uzbekistan",
    completionTitle:
      "Karimov Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Tashkent",
    dedicatedTo: "Office of the President of Uzbekistan",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Vanuatu: {
    officialName: "The IOB-Walter Lini Scholarship for Vanuatu",
    completionTitle:
      "Lini Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Port Vila",
    dedicatedTo: "Office of the Prime Minister of Vanuatu",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  VaticanCity: {
    officialName: "The IOB-Pope John Paul II Scholarship for Vatican City",
    completionTitle:
      "John Paul Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Vatican City",
    dedicatedTo: "Office of the Pope",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Venezuela: {
    officialName: "The IOB-Simón Bolívar Scholarship for Venezuela",
    completionTitle:
      "Bolívar Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Caracas",
    dedicatedTo: "Office of the President of Venezuela",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Vietnam: {
    officialName: "The IOB-Ho Chi Minh Scholarship for Vietnam",
    completionTitle:
      "Ho Chi Minh Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Hanoi",
    dedicatedTo: "Office of the President of Vietnam",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Yemen: {
    officialName: "The IOB-Imam Yahya Scholarship for Yemen",
    completionTitle:
      "Yahya Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Sana'a",
    dedicatedTo: "Office of the President of Yemen",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Zambia: {
    officialName: "The IOB-Kenneth Kaunda Scholarship for Zambia",
    completionTitle:
      "Kaunda Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Lusaka",
    dedicatedTo: "Office of the President of Zambia",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
  Zimbabwe: {
    officialName: "The IOB-Robert Mugabe Scholarship for Zimbabwe",
    completionTitle:
      "Mugabe Fellow of The New Civilization Order & Visionary Billionaire",
    criteria: "Creation of a billion-dollar company",
    businessDesk: "Harare",
    dedicatedTo: "Office of the President of Zimbabwe",
    graduatingInstitutions:
      "Institute of Billionaires & the Institute of Public Companies",
    trainingEducationRegulators: "Unicorns Higher Education Commission",
  },
};
