import React from "react";
import Feature from "../components/Feature/Feature";
import Profile from "../components/Profile/Profile";
import BoxPage from "../components/BoxPage/BoxPage";
import Concierge from "../components/Concierge/Concierge";
import WhatClient from "../components/WhatClient/WhatClient";
import HomeFounder from "../components/allfounder/HomeFounder";
import MediaSection from "../components/MediaSection/MediaSection";
import WhyWorkWithUs from "../components/WhyWorkWithUs/WhyWorkWithUs";
import BackgroundSlideshow from "../components/BackgroundSlideshow/BackgroundSlideshow";

import "../App.css";
import Chamber from "../components/chambers/Chamber";
import GuideService from "../components/guideservices/GuideService";
import CardSlider from "../components/cardslider/CardSlider";
import MainFounder from "../components/allfounder/MainFounder";

const HomePage = () => {
  return (
    <div>
      <BackgroundSlideshow />
      <MainFounder />
      <Feature />
      <Chamber />
      <Profile />
      <Concierge />
      <BoxPage />
      {/* <CardSlider /> */}
      {/* <HomeFounder /> */}
      {/* <GuideService /> */}
      {/* <MediaSection />*/}
      {/* <WhatClient /> */}
      {/* <WhyWorkWithUs /> */}
    </div>
  );
};

export default HomePage;
