import { useParams } from 'react-router-dom';

function GraduatingCountryPage() {
  const { countryName } = useParams();  // Correct parameter name from the route

  return (
    <div>
      <h1>Country Details</h1>
      <p>GraduatingCountryPage {countryName}</p>
    </div>
  );
}

export default GraduatingCountryPage
