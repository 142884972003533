import React from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormButton from '../components/textcomponents/FormButton';

const PortalForm = () => {
  return (
    <>
     <form>
     <input
              className="form-control mt-3"
              type="text"
              placeholder="Name"
            />
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Nationality"
            />
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Profession"
            />
            <input
              className="form-control mt-3"
              type="text"
              placeholder="City Of Residence"
            />
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Email Address"
            />
            <div className="mt-3 w-100">
              <PhoneInput
                country={"us"}
                inputProps={{
                  name: "phoneNumber",
                  required: true,
                  autoFocus: true,
                }}
                inputClass="form-control"
                containerClass="phone-input-container"
                inputStyle={{ width: "100%" }}
              />
            </div>
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Portal Name"
            />
            <input
              className="form-control mt-3"
              type="text"
              placeholder="Country Name"
            />
            <textarea
              className="form-control mt-3"
              id="comments"
              rows="3"
              placeholder="Your comments"
            ></textarea>
            <div className="mt-5 text-center">
              <FormButton name="Buy Now" />
            </div>
     </form> 
    </>
  )
}

export default PortalForm
