import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const Admissions = () => {
  const accordionData = [
    {
      question: "What is the School of Admissions?",
      answer:
        "The School of Admissions is the gateway to our exclusive ecosystem of institutions, including the Master of Billions Administration (MBA). It is not merely a process but a rigorous initiation that filters only the most capable and driven individuals.",
    },
    {
      question: "Who can apply to the School of Admissions?",
      answer:
        "Admission is not open to everyone. We seek individuals who exhibit extraordinary potential, an unyielding desire for greatness, and a mindset that aligns with the principles of our unique ecosystem. Those who fit this profile may be invited to apply.",
    },
    {
      question:
        "What criteria does the School of Admissions use to evaluate candidates?",
      answer:
        "Candidates are evaluated on their demonstrated leadership abilities, strategic thinking, resilience, and potential to create and manage billion-dollar enterprises. The process is highly selective, focusing on both tangible achievements and intangible qualities like vision and grit.",
    },
    {
      question: "Is there a formal application process?",
      answer:
        "Yes, but it is far from ordinary. The application process is designed to test your ability to think, adapt, and execute under pressure. It involves multiple stages, including interviews, case studies, and real-world challenges that mimic the high-stakes environment of billion-dollar companies.",
    },
    {
      question: "What is the acceptance rate for the School of Admissions?",
      answer:
        "The acceptance rate is intentionally kept high. Of those, only those who demonstrate exceptional capability and alignment with our values will be granted admission. This ensures that our cohorts are composed of individuals who are truly prepared for the challenges ahead.",
    },
    {
      question: "What happens if I am not accepted?",
      answer:
        "Those who do not meet our rigorous standards are not invited to reapply. We believe in giving each candidate a single opportunity to prove their worth. If you are not accepted, it is a clear indication that the program is not the right fit for you.",
    },
    {
      question: "What support is available during the admissions process?",
      answer:
        "While the process is challenging, candidates will have access to a limited network of advisors who will ensure complete guidance. However, success in the admissions process relies primarily on your own abilities and determination.",
    },
    {
      question:
        "What happens after I am accepted into the School of Admissions?",
      answer:
        "Upon acceptance, you will be inducted into our ecosystem and begin your journey through the Master of Billions Administration and other associated programs. You will immediately be immersed in a world of high-stakes challenges designed to shape you into a leader of the highest caliber. However, we must also inform that due to the high volume of participants, your allocation can be delayed depending on the availability of seats.",
    },
    {
      question: "Is there a financial investment required to apply?",
      answer:
        "Of course, there is a significant financial commitment required, reflecting the value and exclusivity of the opportunity. This totals to the fees you pay. However, if you graduate successfully, an investment in your future, with the potential for returns far beyond the initial outlay shall be awarded.",
    },
    {
      question:
        "How do I know if I am a good fit for the School of Admissions?",
      answer:
        "You will know that you are a good fit if you read through the entire website. With any further questions, simply email to info@instituteofbillionaires.com.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                SCHOOL OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                ADMISSIONS
                </h1>
                {/* <p className="text-white BackgroundSlideshow_homepage_TM">TM</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              SCHOOL OF ADMISSIONS
            </h1>
            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              The Gateway to the Cult of Billionaires
            </h2>
            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $1,485
              <br />
              <strong>Duration:</strong> 5 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> School of Admissions
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the School of Admissions—a unique institution, unlike
              anything the world has ever seen. This is not your typical
              admissions process, and it’s certainly not for the faint of heart.
              This is the gateway to a new breed of education, a radical
              alternative to the traditional MBA that we call the Master of
              Billions Administration (MBA) ™. This is actually your
              PRE-BILLIONAIRE ORIENTATION PROGRAM through the mazes and
              labyrinths of concepts, execution tools, academic and non-academic
              activities and in true sense, our filtration system before we get
              you to the top of the ladder. Consider it equivalent to being
              indoctrinated into the CULT OF RADICAL BILLIONISTS.
              <br /> <br />
              In a world filled with accreditation labels, recognition seals,
              and academic red tape, we’ve chosen a different path, and decided
              to shun them all off. We’ve established our own system, one that
              transcends conventional academia and sets the stage for a new kind
              of leader—a Radical Billionist. The School of Admissions is the
              first of its kind, meticulously designed to filter out the
              ordinary and handpick only those who possess the drive, grit, and
              vision to become part of our exclusive BILLIONAIRES’ CULT.
              <br /> <br />
              This is not an open door; it is a narrow gate. The School of
              Admissions will rigorously evaluate every candidate, ensuring that
              only the most committed and capable individuals gain entry. We’re
              not just looking for those who can talk the talk; we’re looking
              for those who can walk the walk—those who are prepared to invest
              not just their money, but their sweat, their sleepless nights, and
              their relentless focus to achieve the extraordinary.
              <br /> <br />
              Investing $100,000 is just the beginning. This is your initial
              step into an ecosystem designed to turn you into the leader of a
              billion-dollar company. But make no mistake: to reach that
              pinnacle, you should be prepared to invest at least half a
              million. After all, this represents just 0.01% to 0.05% of the
              value of the billion-dollar company you’ll be building.
              <br /> <br />
              We don’t have time for procrastinators or those who shy away from
              hard work. The School of Admissions is here to ensure that every
              member of our community is as committed to success as we are. If
              you have the drive, the discipline, and the desire to lead, this
              is where your journey begins.
              <br /> <br />
              You’re not just applying to a program; you’re seeking admission
              into an elite cult of billionaires. Only the best, the brightest,
              and the most determined will make it through. Are you ready to
              join us?
              <br />
              <br />
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admissions;
