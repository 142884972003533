import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import CHATICON from "../../assets/images/chatboxIcon1.png";
import { X, Send } from "lucide-react";
import "bootstrap/dist/css/bootstrap.min.css";

export const ChatBot = (props) => {
  const textRef = useRef();
  const chatBoxRef = useRef();
  const [chatOpen, setChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hi, I am Eva, I can assist you regarding your queries about IOB!",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [typingMessage, setTypingMessage] = useState("");

  const toggleChat = () => {
    setChatOpen(!chatOpen);
    if (!chatOpen) {
      scrollToBottom();
    }
  };

  const handleSend = async () => {
    const userMessage = textRef.current.value;
    if (!userMessage.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: userMessage },
    ]);
    textRef.current.value = "";
    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4-turbo",
          messages: [
            { role: "system", content: "You are a helpful AI." },
            { role: "user", content: userMessage },
          ],
          temperature: 0,
        },
        {
          headers: {
            Authorization: `Bearer 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`, // Replace with your actual API key
            "Content-Type": "application/json",
          },
        }
      );
      const botMessage = response.data.choices[0].message.content;
      typeWriterEffect(botMessage);
    } catch (error) {
      console.error("Error sending message to API:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const typeWriterEffect = (message) => {
    let i = -1;
    setTypingMessage("");
    const interval = setInterval(() => {
      if (i < message.length) {
        setTypingMessage((prev) => prev + message.charAt(i));
        i++;
      } else {
        clearInterval(interval);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: "bot", text: message },
        ]);
        setTypingMessage("");
      }
    }, 50);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (chatOpen) {
      scrollToBottom();
    }
  }, [chatOpen]);

  return (
    <>
      <div id="chatCon" className="d-flex flex-column">
        <div
          className={`chat-box ${chatOpen ? "d-flex" : "d-none"} flex-column`}
        >
          <div className="chatbot_header d-flex justify-content-between align-items-center p-2 p-lg-3">
            <div className="d-flex gap-2 align-items-center">
              <img src={CHATICON} alt="CHATBOT" width={50} />
              <h5 className="text-white fw-bold chatbox_h5">Chat AI</h5>
            </div>
            <div>
              <X color="white" size={20} onClick={toggleChat} />
            </div>
          </div>
          <div
            className="chatbox_body flex-grow-1 overflow-auto p-2"
            ref={chatBoxRef}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`d-flex mt-3 ${
                  msg.sender === "user"
                    ? "me-3 justify-content-end"
                    : "ms-3 gap-3"
                } align-items-end`}
              >
                {msg.sender === "bot" && (
                  <img src={CHATICON} alt="CHATICON" width={30} />
                )}
                <div
                  className={`chatbot_${
                    msg.sender === "user" ? "recieve" : "send"
                  } w-75 p-0 m-0`}
                >
                  <p
                    className={`chatbt_${
                      msg.sender === "user" ? "recieve" : "send"
                    }_p pt-2 pb-2 ps-3 pe-3 m-0`}
                  >
                    {msg.text}
                  </p>
                </div>
              </div>
            ))}
            {typingMessage && (
              <div className="d-flex mt-3 ms-3 gap-3 align-items-end">
                <div>
                  <img src={CHATICON} alt="CHATICON" width={30} />
                </div>
                <div className="chatbot_send w-75 p-0 m-0">
                  <p className="chatbt_send_p pt-2 pb-2 ps-3 pe-3 m-0">
                    {typingMessage}
                  </p>
                </div>
              </div>
            )}
            {loading && (
              <div className="d-flex mt-3 ms-3 gap-3 align-items-end">
                <div>
                  <img src={CHATICON} alt="CHATICON" width={30} />
                </div>
                <div className="chatbot_loading p-1 ps-2 pe-2 m-0">
                  <div className="loader"></div>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex align-items-center">
            <div className="chatbot_footer w-100">
              <input
                className="chatbox_input border rounded-2"
                name="Chatbot"
                placeholder="Type your message..."
                ref={textRef}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="border rounded-2 me-3 p-2" onClick={handleSend}>
              <Send size={24} color="#002f6c" />
            </div>
          </div>
        </div>
      </div>

      <div className="chatbot_icon" onClick={toggleChat}>
        <img src={CHATICON} alt="CHATBOT" className="CHATBOTIMG" width={60} />
      </div>
    </>
  );
};

export default ChatBot;
