import React from "react";
import Accordion from "../../constant/accordion";
import IMG from "../../assets/images/BoxImage3.jpg";
import FormButton from "../../components/textcomponents/FormButton";
const FoundingFathersInstitute = () => {
  const accordionData = [
    {
      question: "What is the Institute of the Founding Fathers of the World?",
      answer:
        "The Institute of the Founding Fathers of the World is our flagship institute dedicated to cultivating the next generation of global leaders. We focus on transforming individuals into visionary leaders who are equipped to shape the future through the principles of cartels, syndicates, and cult-like unity.",
    },
    {
      question:
        "What makes this institute different from other leadership programs?",
      answer:
        "Unlike traditional leadership programs, our institute draws inspiration from the most powerful structures in history—cartels, syndicates, and cults. We focus on strategic collaboration, collective will, and unwavering belief to forge leaders who can command and reshape the world.",
    },
    {
      question:
        "Who should apply to the Institute of the Founding Fathers of the World?",
      answer:
        "This institute is designed for individuals who possess a relentless drive for power, influence, and global impact. We seek those who are not just looking to lead, but to command, control, and create new paradigms in leadership.",
    },
    {
      question: "What can I expect to learn at the Institute?",
      answer:
        "At the Institute, you will learn how to lead with precision, command loyalty, and wield power through strategic alliances. You will be trained to think and act as part of a collective force, mastering the art of leadership that transcends individual ambition.",
    },
    {
      question: "How is the curriculum structured?",
      answer:
        "The curriculum is structured around three core principles: the Cartel (strategic collaboration), the Syndicate (collective will), and the Cult (unwavering belief). Each module is designed to immerse you in these concepts, transforming you into a leader who can command entire organizations and industries.",
    },
    {
      question: "What are the admission criteria for the Institute?",
      answer:
        "Admission is highly selective, with a focus on individuals who demonstrate exceptional vision, discipline, and the potential to become global leaders. We seek those who are ready to commit fully to the rigorous training and transformative experience the Institute offers.",
    },
    {
      question: "What is the duration of the program?",
      answer:
        "The program duration varies depending on the specific track you choose, but it is designed to be an intensive, immersive experience that can range from several months to a full year of continuous development and training.",
    },
    {
      question:
        "What kind of support can I expect after completing the program?",
      answer:
        "Graduates of the Institute become part of an exclusive network of leaders, providing lifelong access to mentorship, strategic alliances, and opportunities to collaborate with fellow Founding Fathers on global initiatives.",
    },
    {
      question: "How does the Institute prepare me for real-world leadership?",
      answer:
        "The Institute prepares you not only through theoretical knowledge but through real-world simulations, strategic exercises, and direct mentorship from experienced leaders. You will leave with the tools, mindset, and connections needed to command influence and lead at the highest levels.",
    },
    {
      question:
        "What is the ultimate goal of the Institute of the Founding Fathers of the World?",
      answer:
        "Our ultimate goal is to forge a new generation of leaders who are capable of shaping the world according to their vision. We aim to create a legacy of leadership that transcends generations, establishing our graduates as the Founding Fathers of a new global order.",
    },
  ];

  return (
    <div>
      <div className="Univeristy_back">
        <div className="w-100">
          <div className="Univeristy_back_opacity p-4">
            <div>
              <h1 className="text-white text-uppercase p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF THE
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  FOUNDING FATHERS
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              INSTITUTE OF THE FOUNDING FATHERS OF THE WORLD
            </h1>

            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              Leadership Learned Like Never Before
            </h2>

            <p className="feature_homepage_p pt-4">
              <div className="p-3">
                <img
                  src={IMG}
                  alt="img"
                  className="rounded"
                  style={{ float: "right" }}
                />
              </div>
              <strong>Fees:</strong> $3,185
              <br />
              <strong>Duration:</strong> 5 days
              <br />
              <strong>Credit Hours:</strong> 2<br />
              <strong>Certification:</strong> Institute of The Founding Fathers
              of the World
              <br />
              <strong>Qualification:</strong> Pre-Billionaire Graduate, MBA
              <br />
              <br />
              <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
                ABOUT
              </h1>
              <br />
              Welcome to the Institute of the Founding Fathers of the World,
              where the very essence of leadership is redefined and forged in
              the crucible of vision, power, and unity. This is not merely an
              institute; it is the birthplace of a new breed of leaders—those
              destined to reshape the world. Here, we do not just teach
              leadership; we cultivate the architects of empires, the
              visionaries who will stand as the pillars of a new global order.
              <br /> <br />
              Our philosophy is rooted in the timeless power structures that
              have governed the rise and fall of civilizations: the Cartel, the
              Syndicate, and the Cult. These are not mere organizations; they
              are the embodiment of coalescence—a unity of purpose that
              transcends individual ambition and becomes an unstoppable force.
              At the Institute of the Founding Fathers, we immerse our disciples
              in this ethos, molding them into leaders who do not just influence
              the world—they command it.
              <br /> <br />
              The Cartel: In the tradition of the great alliances of history, we
              teach our disciples the art of strategic collaboration. Here,
              leadership is not a solitary endeavor but a symbiotic relationship
              where power is shared among the elite, creating a formidable
              coalition that controls markets, shapes policies, and dictates the
              future.
              <br /> <br />
              The Syndicate: Our leaders are trained to think as one, acting
              with a collective will that is greater than any individual. The
              Syndicate mentality is about precision, discipline, and unyielding
              loyalty to the cause. Every decision is made with the
              understanding that it serves the greater good of the whole,
              ensuring the survival and dominance of the group.
              <br /> <br />
              The Cult: True leadership requires unwavering belief—not just in
              oneself, but in the mission. At the Institute, we instill a sense
              of purpose so profound that it becomes a creed. Our leaders are
              not just followers of a path; they are the creators of it, leading
              with a conviction that inspires absolute devotion from those who
              stand with them.
              <br /> <br />
              The Institute of the Founding Fathers of the World is not for the
              ordinary or the faint-hearted. It is for those who are ready to
              transcend the mundane, to become part of something greater,
              something eternal. Here, you do not just learn to lead—you become
              the embodiment of leadership itself, a founding father of a world
              yet to be shaped.
              <br /> <br />
              Join us, and take your place among the immortals who will write
              the next chapter of human history.
            </p>
            <div className="mt-4 mb-4">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
            <hr className="mt-3 mb-3" />

            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>

            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
            <div className="mt-4 ms-2">
              <FormButton name="Register Now" url="/registration-form" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoundingFathersInstitute;
