import { useLocation, useParams } from "react-router-dom";
import BACK from "../../assets/images/advertising_portal.jpg";
import { Facebook, Twitter, Linkedin, Instagram, Youtube } from "lucide-react";
import PortalForm from "../../forms/PortalForm";

function AddvertisingCountryPage() {
  const { portalType, countryName } = useParams();
  const location = useLocation();
  const flag = location.state?.flag || "";

  return (
    <>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-12">
            <img width="100%" height={500} src={BACK} alt="background" />
          </div>
        </div>
      </div>
      <div
        className="container pb-5 mb-5"
        style={{
          marginTop: "-14%",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <div className="row">
          <div className="col-md-6 col-12 bg-white">
            <div className="d-flex align-items-center ps-4 py-3">
              <h1 className="portal_homepage_h1 text-uppercase">
                advertising portal
              </h1>
            </div>
            <div className="ps-4">
              <h2 className="fellowship text-uppercase">{countryName}</h2>
              <div className="mt-4">
                <p className="feature_homepage_p mt-4">
                  You can now directly place your ad or can become our ad agency
                  in your country and make a substantial 30% earning on every ad
                  that you book.
                </p>
                <h2 className="feature_homepage_h2 mt-3">
                  Benefits and Advantages:
                </h2>
                <ol>
                  <li className="feature_homepage_p">
                    We have restricted and maintain ad agencies according to the
                    performance based on meeting weekly and monthly targets.
                  </li>
                  <li className="feature_homepage_p">
                    If you are a client who wishes to advertise directly on this
                    portal, you can do so till such time that we have appointed
                    an ad agency.
                  </li>
                  <li className="feature_homepage_p">
                    Ads will only appear on your country’s assigned pages only.
                  </li>
                  <li className="feature_homepage_p">
                    For multiple countries, please choose from the packages
                    shown below on the page.
                  </li>
                  <li className="feature_homepage_p">
                    All ads are placed in the order they are received. The
                    oldest remain on the top. To keep your ad on the top, simply
                    opt for a premium placement and maintain the top slot on
                    monthly basis.
                  </li>
                  <li className="feature_homepage_p">
                    Ads can be only posted according to the sizes provided.
                  </li>
                  <li className="feature_homepage_p">
                    We provide 24/7 support. If you have any questions or want
                    to place your ads, please contact our team and send us an
                    email at advertise@cscps.org.
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 bg-white pt-4">
            <div className="d-flex justify-content-center">
              {flag && (
                <img
                  src={flag}
                  alt={countryName}
                  width="60%"
                  style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
                />
              )}
            </div>
            <h5
              className="text-center mt-4 me-2"
              style={{ color: "var(--main-red-color)" }}
            >
              Share This Program
            </h5>
            <div className="d-flex justify-content-center align-items-center mt-4">
              <div
                className="d-inline-flex align-items-center gap-3 p-2 rounded-2"
                style={{ background: "#002F6C" }}
              >
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Facebook color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Twitter color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Youtube color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Linkedin color="#1B1B1B" />
                  </div>
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="social-icon p-0 m-0">
                    <Instagram color="#1B1B1B" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5 mb-5" />
        <div className="row mt-5 pt-4 d-flex justify-content-center">
          <div className="col-md-6 col-12">
            <h2 className="fellowship text-uppercase">Addvertising form</h2>
            <PortalForm />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddvertisingCountryPage;
