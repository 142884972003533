import React from "react";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const VenusSyndicate = () => {
  return (
    <>
      <div className="feature_homepage_back">
        <div className="w-100">
          <div className="feature_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <FirstHeading count="01" />
            <h1 className="heading_h1 text-uppercase m-0 p-0">
              The Venus Syndicate
            </h1>
            <h2 className="feature_homepage_h2 mt-5">
              It’s the Amazon Mafia – The Fiercest Force in Business
            </h2>
            <p className="mt-2 feature_homepage_p">
              Welcome to the Amazon Mafia—a powerhouse alliance of the most
              formidable women in business. This is not just a network; it’s an
              unbreakable bond between leaders who wield their influence with
              the precision of a mafia and the strength of the legendary
              Amazons. Here, we don’t just compete—we conquer.
            </p>
            <p className="feature_homepage_p">
              <h2 className="feature_homepage_h2 mt-5">
                A Sisterhood of Power
              </h2>
              In the Venus Syndicate, women lead with unparalleled strength and
              strategy. This is a sisterhood forged in the fires of ambition and
              resilience, where each member is as unstoppable as the next. We
              stand united, not just to face challenges, but to obliterate them.
              Here, you’re not just part of a group—you’re part of a legacy.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Strategic Domination – The Mafia Way
              </h2>
              The Venus Syndicate operates with the calculated precision of a
              mafia, but with the heart and soul of warriors. We strategize, we
              outmaneuver, and we overpower the competition. Our moves are
              swift, decisive, and leave no room for retaliation. In this force,
              there is no second place—only victory.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Empowerment Through Unity
              </h2>
              This isn’t just about individual success; it’s about collective
              domination. The Venus Syndicate ensures that every woman in our
              ranks rises to her fullest potential. We lift each other up,
              strategize together, and move as one unstoppable unit. In this
              alliance, your success is our success, and together, we shape the
              future.
              <br />
              <h2 className="feature_homepage_h2 mt-5">
                Join the Most Powerful Female Force in Business
              </h2>
              If you’re ready to join a group of women who don’t just aim high
              but dominate every field they enter, then the Venus Syndicate is
              your calling. This is where strength meets strategy, where
              empowerment meets execution, and where women don’t just break the
              glass ceiling—they shatter it completely.
              <br />
              <br />
              Welcome to the Venus Syndicate —where women lead, conquer, and
              command the future.
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default VenusSyndicate;
