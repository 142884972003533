import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import countries from "../../constant/countries";
import FirstHeading from "../../components/textcomponents/FirstHeading";
import SecondHeading from "../../components/textcomponents/SecondHeading";

function PortalDetails() {
  const { type } = useParams();
  const [selectedLetter, setSelectedLetter] = useState("A");
  const navigate = useNavigate();

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
  };

  const filteredCountries = countries.filter(
    (country) => country.letter === selectedLetter
  );

  const letters = Array.from(
    new Set(countries.map((country) => country.letter))
  ).sort();

  const handleCountryClick = (countryName) => {
    const country = countries.find((c) => c.name === countryName);
    if (country) {
      navigate(`/${type.toLowerCase()}/${country.name}`, {
        state: { flag: country.flag },
      });
    } else {
      console.error("Country not found:", countryName);
    }
  };

  return (
    <div className="detail-page">
      <div className="portal_homepage_back">
        <div className="w-100">
          <div className="portal_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Opportunity Portal Content */}
      {type === "investment-portal" && (
        <>
          <div className="container mt-5">
            <SecondHeading
              count="01"
              title="investment portal"
              tagline="The Birthplace of Billion-Dollar Companies "
            />
            <div className="row g-1">
              <p className="feature_homepage_p mt-4">
                Welcome to the Investment Portal, and is the breeding ground for
                unicorns and industry dominators. You can opt to becoming an
                investor, (small, medium or large) or choose being a franchisee,
                investor or a partner with our students on their ventures, under
                guarantee. We’re offering you access to high-growth companies,
                world-changing ventures, and the chance to back the
                billion-dollar success stories of tomorrow. Don’t watch from the
                sidelines while others make history. Click below and claim your
                stake in the next wave of billion-dollar companies.
              </p>
            </div>
            <div className="mt-3">
              <FirstHeading
                count="02"
                title="Choose Your Country"
                tagline="Find By Country Name"
              />
            </div>
            <div
              className="d-flex flex-wrap mt-5 ps-2 pt-2 pb-2"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              {letters.map((letter) => (
                <h6
                  key={letter}
                  className={`list_hover m-0 p-2 cursor-pointer ${
                    letter === selectedLetter ? "active" : ""
                  }`}
                  onClick={() => handleLetterClick(letter)}
                >
                  {letter} |
                </h6>
              ))}
            </div>
            {selectedLetter && (
              <div>
                <h6
                  className="mt-4 p-3 text-white"
                  style={{ backgroundColor: "var(--main-red-color)" }}
                >
                  Countries starting with {selectedLetter}:
                </h6>
                <div className="row g-1">
                  {filteredCountries.map((country, index) => (
                    <div className="col-md-4 col-12" key={index}>
                      <p
                        className="country_name p-2 m-0 cursor-pointer"
                        onClick={() => handleCountryClick(country.name)}
                      >
                        {country.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* Addvertising Portal Content */}
      {type === "addvertising-portal" && (
        <>
          <div className="container mt-5">
            <SecondHeading
              count="01"
              title="addvertising Portal"
              tagline="Elevate Your Brand to Billionaire Heights"
            />
            <p className="feature_homepage_p mt-4 mb-5">
              Welcome to the Advertising Portal, where brands are placed
              directly in front of the world’s future billionaires, industry
              leaders, and high-net-worth individuals. This is more than just
              advertising—it’s a chance to align your brand with success, power,
              and innovation. We offer unmatched visibility to those who shape
              industries and define markets. Whether you’re looking to elevate
              your brand, partner with key players, or reach an audience with
              serious buying power, this is the platform where your brand
              ascends to new heights. <br />
              <br />
              Stop settling for ordinary reach. Get your brand in front of the
              most powerful and influential minds. Click below to secure your
              advertising space and be seen where success is born.
            </p>
            <FirstHeading
              count="02"
              title="Choose Your Country"
              tagline="Find By Country Name"
            />
            <div
              className="d-flex flex-wrap mt-5 ps-2 pt-2 pb-2"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              {letters.map((letter) => (
                <h6
                  key={letter}
                  className={`list_hover m-0 p-2 cursor-pointer ${
                    letter === selectedLetter ? "active" : ""
                  }`}
                  onClick={() => handleLetterClick(letter)}
                >
                  {letter} |
                </h6>
              ))}
            </div>
            {selectedLetter && (
              <div>
                <h6
                  className="mt-4 p-3 text-white"
                  style={{ backgroundColor: "var(--main-red-color)" }}
                >
                  Countries starting with {selectedLetter}:
                </h6>
                <div className="row g-1">
                  {filteredCountries.map((country, index) => (
                    <div className="col-md-4 col-12" key={index}>
                      <p
                        className="country_name p-2 m-0 cursor-pointer"
                        onClick={() => handleCountryClick(country.name)}
                      >
                        {country.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* Admissions Portal Content */}
      {type === "admissions-portal" && (
        <>
          <div className="container mt-5">
            <SecondHeading count="01" title="admissions portal" tagline="" />
            <p className="feature_homepage_p mt-4 mb-5">
              Become a part of the elite 0.00003%. Our Admission Portal is the
              gateway to the Institute of Billionaires, where the world’s most
              ambitious individuals come to unlock their potential and create
              legacies. We don’t accept everyone—only those ready to seize the
              world’s most extraordinary opportunities. Here, education is not
              just about learning; it's about becoming the next billionaire. Do
              you have what it takes to join the elite? Click below to apply and
              begin your application toward a billion-dollar future.
            </p>
            <FirstHeading
              count="02"
              title="Choose Your Country"
              tagline="Find By Country Name"
            />
            <div
              className="d-flex flex-wrap mt-5 ps-2 pt-2 pb-2"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              {letters.map((letter) => (
                <h6
                  key={letter}
                  className={`list_hover m-0 p-2 cursor-pointer ${
                    letter === selectedLetter ? "active" : ""
                  }`}
                  onClick={() => handleLetterClick(letter)}
                >
                  {letter} |
                </h6>
              ))}
            </div>
            {selectedLetter && (
              <div>
                <h6
                  className="mt-4 p-3 text-white"
                  style={{ backgroundColor: "var(--main-red-color)" }}
                >
                  Countries starting with {selectedLetter}:
                </h6>
                <div className="row g-1">
                  {filteredCountries.map((country, index) => (
                    <div className="col-md-4 col-12" key={index}>
                      <p
                        className="country_name p-2 m-0 cursor-pointer"
                        onClick={() => handleCountryClick(country.name)}
                      >
                        {country.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {/* E-commerce Portal Content */}
      {type === "ecommerce-portal" && (
        <>
          <div className="container mt-5">
            <SecondHeading
              count="01"
              title="ecommerce portal"
              tagline="The Marketplace for Billionaires and Visionaries"
            />
            <p className="feature_homepage_p mt-4 mb-5">
              Welcome to the Ecommerce Portal, where exclusivity meets
              opportunity. This is not your typical marketplace—this is where
              billionaires and visionaries shop, invest, and sell. Whether
              you're looking to purchase luxury products, invest in world-class
              services, or offer your own brand to an elite customer base, this
              is the platform where billion-dollar transactions take place. For
              vendors, this is your gateway to reach the most powerful buyers in
              the world. For buyers, this is where your investments turn into
              opportunities. <br />
              <br />
              Ready to access or sell to the most elite clientele? Click below
              to explore our exclusive marketplace and join the ranks of
              billion-dollar commerce.
            </p>
            <FirstHeading
              count="02"
              title="Choose Your Country"
              tagline="Find By Country Name"
            />
            <div
              className="d-flex flex-wrap mt-5 ps-2 pt-2 pb-2"
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              {letters.map((letter) => (
                <h6
                  key={letter}
                  className={`list_hover m-0 p-2 cursor-pointer ${
                    letter === selectedLetter ? "active" : ""
                  }`}
                  onClick={() => handleLetterClick(letter)}
                >
                  {letter} |
                </h6>
              ))}
            </div>
            {selectedLetter && (
              <div>
                <h6
                  className="mt-4 p-3 text-white"
                  style={{ backgroundColor: "var(--main-red-color)" }}
                >
                  Countries starting with {selectedLetter}:
                </h6>
                <div className="row g-1">
                  {filteredCountries.map((country, index) => (
                    <div className="col-md-4 col-12" key={index}>
                      <p
                        className="country_name p-2 m-0 cursor-pointer"
                        onClick={() => handleCountryClick(country.name)}
                      >
                        {country.name}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default PortalDetails;
