import React from "react";
import {Mail, Smartphone, MapPin, Phone } from 'lucide-react';

const Footer = () => {
  return (
    <>
      <footer className="text-center text-lg-start bg-dark text-white pt-3 mt-4">
        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                 Institute Of Billionaires 
                </h6>
                <p>
                  Here you can use rows and columns to organize your footer
                  content. Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit.
                </p>
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Our Portals</h6>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Investor Portal
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Admission Portal
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Advertising Portal
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Ecommerce Portal
                  </a>
                </p>
              </div>

              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Home
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    About
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset text-decoration-none">
                    Contact
                  </a>
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                <MapPin /> &nbsp;&nbsp;32 A Lawrence Road, Jinnah Town, Lhr
                </p>
                <p>
                <Mail /> &nbsp;&nbsp;contact@institueofbillioaire.com
                </p>
                <p>
                <Phone /> &nbsp;&nbsp;0321-9700700
                </p>
              </div>
            </div>
          </div>
        </section>
        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
        <span className="fw-bold">
        Institute Of Billionaires
        </span>  © 2024. All rights reserved. &nbsp;
        </div>
      </footer>
    </>
  );
};

export default Footer;
