import React from "react";
import "./Cultperoid.css";
import Accordion from "../constant/accordion";
const CultPeroid = () => {
  const accordionData1 = [
    {
      question: "Who We Are",
      answer:
        "The Institute of Billionaires (IOB) was created with one mission: to shape the future titans of industry—those who refuse to be ordinary and are determined to lead the global economy. We don’t simply teach; we train, we mold, and we equip individuals to build billion-dollar companies. Success isn’t just a goal here—it’s a guarantee.",
    },
    {
      question: "A Founder’s Legacy",
      answer:
        "As the founder of the Institute, I established this platform with a singular vision—to prepare my sons, Riyan and Isaac, to build their own empires. This institution was born out of my desire to ensure that my children—and those like them—would be given the skills, tools, and the relentless mindset to lead global businesses. Riyan and Isaac were not admitted easily. They faced the same rigorous standards that every candidate must meet. Here, there are no exceptions, even for my own.",
    },
    {
      question: "A Curriculum Like No Other",
      answer:
        "The training at IOB is not a simple journey of theory and lectures; it is a war zone designed to push candidates to their limits. The curriculum starts with a massive list of things to do—tasks that demand commitment, focus, and execution. From the very first day, candidates are not just learning—they are building and executing real-world strategies to create billion-dollar companies. Our training is not for the faint of heart. It’s for the relentless, the fearless, and the committed. Those who pass through our doors are required to have the six core traits of a true leader, including resilience, creativity, adaptability, and an unwavering drive. Without these, you don’t even get recruited.",
    },
    {
      question: "No Exceptions to Excellence",
      answer:
        "Riyan and Isaac had to meet these exacting standards, and only after proving themselves were they admitted into the Institute of Billionaires. Here, it doesn't matter who you are—success is earned, not given. Everyone who steps into the program must display the six fundamental traits we demand: vision, grit, discipline, adaptability, creativity, and courage. These qualities are non-negotiable.",
    },
    {
      question: "Our Mission",
      answer:
        "At IOB, our mission is simple but revolutionary: to train individuals to lead billion-dollar enterprises. We don’t settle for graduates; we create CEOs, equipped with the knowledge, skills, and mindset to run publicly traded companies and scale them to unicorn status. You don’t leave IOB with a degree—you leave with a company, a team, and the ability to dominate global markets.",
    },
    {
      question: "Join Us",
      answer:
        "The Institute of Billionaires is not for everyone—it’s for the select few who are ready to take on the world, build their empire, and shape the future. If you believe you have what it takes, we’ll give you the tools, the network, and the opportunities to make it happen.",
    },
  ];

  return (
    <>
      <div className="abouti_homepage_back">
        <div className="w-100">
          <div className="abouti_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12 ps-lg-4 pe-lg-4">
            <h1 className="fw-bold text-uppercase d-flex align-items-center text-dark about_h1_1">
              We are a Cult. Period.
            </h1>
            <p className="feature_homepage_p mt-4">
              If "cult" is the only word that describes us, then so be it. There
              is no better term to define the unbreakable loyalty, unwavering
              belief, and shared mission to dominate. Once you’re in, there is
              no way out. You succeed or you die. It’s a simple philosophy.
              <br />
              <br />
              We are not just a place of learning—we are a movement. A movement
              that shapes the future by building billionaires who don’t just
              participate in market dominance—they control it. Respect the
              genius of a warlord and the secrecy of a cartel. Think long-term.
              <br />
              <br />
              If you’re here, you already know the truth: success at this level
              isn’t taught; it’s indoctrinated. Only those who can handle the
              relentless pursuit of dominance—those who can live by our
              creed—will rise.
            </p>
          </div>

          <div className="col-12 mt-4">
            <Accordion items={accordionData1} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CultPeroid;
