import React from "react";
import FirstHeading from "../../components/textcomponents/FirstHeading";

const ConciergeHomepage = () => {
  return (
    <div>
      <div className="concierge_homepage_back">
        <div className="w-100">
          <div className="concierge_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  Billionaires
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-0 mt-5">
          <div className="col-12">
            <FirstHeading count="01" />
            <h2 className="heading_h2 text-uppercase">$100 Million Dollars</h2>
            <h1 className="heading_h1 text-uppercase m-0 p-0">
              CEO's Net worth Rewards Concierge
            </h1>
            <p className="mt-5 feature_homepage_p">
              This is as direct and straightforward as we can be. Claim your
              destiny now!
              <br />
              <br />
              In this realm, power is not just earned—it’s engineered. The
              $100,000,000 CEO's Net Worth Rewards Concierge stands as your
              gateway to a new echelon of influence and wealth, designed for
              those who can turn potential into prosperity.
              <br />
              <br />
              Upon entering, you are not merely an executive; you are the
              architect of your destiny, equipped with the tools, training, and
              tenacity to lead a company to unprecedented heights. The path laid
              before you is one of calculated risk and strategic mastery, where
              every decision ripples through the corridors of your growing
              empire.
              <br />
              <br />
              The reward for your success is not just wealth—it is validation, a
              testament to your capability to generate extraordinary value. As
              you guide your company through the labyrinth of market forces, the
              $100,000,000 CEO's Net Worth Rewards Concierge awaits those who
              can drive their enterprise to exceptional profitability. The full
              extent of the rewards remains locked, revealed only to those who
              prove themselves worthy of such an honor.
              <br />
              <br />
              Understand this: what you stand to gain is more than financial. It
              is a seat at the table of the world’s most powerful, a stake in a
              legacy that few ever achieve. The true details are whispered only
              to those who cross the threshold and demonstrate their capacity to
              multiply value and command success.
              <br />
              <br />
              Do you have what it takes to claim your place among the elite? The
              $100,000,000 CEO's Net Worth Rewards Concierge is your first step
              into a world where the stakes are immense, and the
              rewards...infinite.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConciergeHomepage;
