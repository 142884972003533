import React from "react";

const IntituteOfOppurtunity = () => {
  return (
    <>
      <div className="institueOppurtunity_back">
        <div className="w-100">
          <div className="hero_back_opacity p-4">
            <div>
              <h1 className="text-white p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
                INSTITUTE OF
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                  BILLIONAIRES
                </h1>
                <p className="text-white BackgroundSlideshow_homepage_TM">TM</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              WELCOME!
            </h1>
            {/* <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-0">
              Institute of Billionaires is also The Institute of Opportunity!
            </h2> */}
            <p className="BackgroundSlideshow_homepage_p pt-4">
              The entire concept, from its inception to its realization as an
              ecosystem where billionaires are made, is mine. Though I am a
              perfectionist—without any compulsive behavior for perfectionism—I
              initially wanted this website to be presented without any images,
              as a case in the court of the masses and public opinion.
              <br />
              But thanks to the brilliance of OpenAI's ChatGPT, which fought
              desperately and didn’t agree, insisting on a visual execution of
              my vision as well, we’ve achieved this balance. (By the way,
              ChatGPT remains the smartest entity I’ve encountered, second only
              to my own brain.)
            </p>
            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-0">
              ADDENDUM WITH NO BULL!
            </h2>
            <p className="BackgroundSlideshow_homepage_p">
              Thrice, I have swayed from hero to zero and back to
              hero—transforming from a VVHNWI to a billionaire and back
              again—but never in my life did I even frown. Otherwise, I wouldn’t
              be writing this note. Despite being hard-hit by the Gulf War of
              1990, the 9/11 incident of 2001, and an extremely disastrous
              personal catastrophe where I lost my family, I have never stopped
              at anything. This welcome note should be read as an addendum and a
              testament to the battles you will fight to reclaim your lost glory
              or to create new ones, learning to hit harder each time than
              before. Crafting this mega-project into a living, breathing entity
              is the omnipotent, singular summation of my life.
              <br />
              As the founder of every institute, concept, and system presented
              here, this website is meant to resonate with your life to its
              fullest. If I could endure the most tragic personal, professional,
              family, and financial disasters—why can’t you? I have suffered
              from loneliness as the top lone wolf survivor against all such
              adversities, and hence created a support system that will never
              end, leaving no one behind, ever!
              <br />
              Being a polymath isn’t easy, particularly when you’re striving to
              build something truly extraordinary. People don’t expect bull from
              you, and hence, no bull!
            </p>
            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-3">
              The Motivation
            </h2>
            <p className="BackgroundSlideshow_homepage_p pt-2">
              I laid the foundation of this institute for my children back in
              1993, in the wake of the first major setback in 1990.
              <br />
              My eldest, who remains my first and youngest student, was a
              brilliant chatterbox with an exceptional memory. Even as she
              mispronounced “economy” as “ECOMUNY” and “SWOT Analysis” as “SAT
              anal-isis,” at that age, she was already on the path to
              leadership, earning her accolades from the US Congress and
              California State Assembly a few years later. My second, aged three
              at the time always had a very powerful mindset. Today, she is seen
              as having the potential to become the governor of California, a
              testament to her resilience in the face of incredible adversity.
              She too has earned recognition for her leadership from the US
              Congress and the California State Assembly.
              <br />
              My third student, a born Samurai with an unmatched focus, began
              his journey with me at age seven, after a separation of four
              years, overcoming significant challenges to become a disciplined
              and determined individual, and the next in line to run this cult.
              His younger brothers, at just 12 and 10, have already made history
              as the youngest Chairman and CEO of a public company after a
              vigorous training , as well as accomplished musicians.
              <br />
              These stories highlight that talent and opportunity do not always
              align, but through the bonds of a cult-knit community—a true gang
              of brothers and sisters—we ensure that everyone survives and
              thrives.
            </p>
            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-0" style={{fontSize: "50px"}}>
              MY PLEDGE
            </h2>
            <p className="BackgroundSlideshow_homepage_p">
              “I pledge to create RADICAL & FUNDAMENTAL BILLIONISTS and to train
              them as part of a BILLIONAIRES CULT. I also pledge that if anyone
              fails to demonstrate the ethos of hard work, he will be dropped
              out instantly with no hesitation towards a second chances. ”
            </p>
            <h4 className="BackgroundSlideshow_homepage_h4 mt-4 pt-3">
              THE SHEHREZAD
            </h4>
            <p className="BackgroundSlideshow_homepage_p">Chairman & CEO</p>
            <h2 className="BackgroundSlideshow_homepage_h2 text-uppercase mt-2 mt-lg-5" style={{fontSize: "60px"}}>
              THE NEXT STEP
            </h2>
            <p className="BackgroundSlideshow_homepage_p">
              Please, now go through the website in detail, and if this
              opportunity is the one you have been waiting for, register
              yourself here.
            </p>
            <hr className="mt-5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntituteOfOppurtunity;
