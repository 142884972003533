import React from "react";
import Accordion from "../../../constant/accordion";
import IMG from "../../../assets/images/LeonardoDa.jpeg";

const LeonardoDa = () => {
  const accordionData = [
    {
      question:
        "What is the focus of the Leonardo da Vinci School of Fine Arts & Culture?",
      answer:
        "The school focuses on teaching future billionaires the value of fine arts, cultural heritage, and art patronage. Students learn how to leverage art as a tool for global influence, networking, and legacy building.",
    },
    {
      question: "Why is the school named after Leonardo da Vinci?",
      answer:
        "Leonardo da Vinci symbolizes mastery in both art and intellect, making him the perfect figure to represent a school that teaches the significance of fine arts in shaping influence and legacy.",
    },
    {
      question: "What skills will students learn at this school?",
      answer:
        "Students will learn how to build and curate valuable art collections, engage in cultural diplomacy, and understand the deeper significance of art in global networking and influence.",
    },
    {
      question:
        "How does this school fit into the overall curriculum of the Institute of Billionaires?",
      answer:
        "The Leonardo da Vinci School enhances the broader mission of the Institute by integrating art and culture as essential tools for leadership, influence, and wealth-building.",
    },
    {
      question:
        "Is the focus only on art collection, or does it include other cultural elements?",
      answer:
        "While art collection is a key focus, the school also covers cultural diplomacy, museum curation, and broader aspects of cultural heritage and influence.",
    },
    {
      question: "How does understanding fine arts help in building influence?",
      answer:
        "Fine arts have long been a tool for building international relations and cultural influence. Mastering the world of art helps billionaires forge powerful connections and create a lasting impact on society.",
    },
    {
      question:
        "Who will teach at the Leonardo da Vinci School of Fine Arts & Cultural Masters?",
      answer:
        "The school will feature leading experts in art collection, curation, and cultural diplomacy, offering students real-world insights and connections to the elite art world.",
    },
    {
      question: "Why is art patronage important for future billionaires?",
      answer:
        "Art patronage allows billionaires to preserve cultural heritage, gain influence in global cultural circles, and build a legacy that will be remembered for generations.",
    },
    {
      question:
        "Can the skills learned in this school apply to other aspects of life?",
      answer:
        "Yes, the principles of art collection, cultural diplomacy, and legacy building can be applied to business, networking, and leadership, making graduates more well-rounded leaders.",
    },
    {
      question:
        "What is the ultimate goal of the Leonardo da Vinci School of Fine Arts & Cultural Masters?",
      answer:
        "The goal is to equip students with the knowledge and tools to use art and culture as means for global influence, social impact, and personal legacy.",
    },
  ];

  return (
    <div>
      <div className="SecretiveInstitute_back">
        <div className="w-100">
          <div className="SecretiveInstitute_back_opacity p-4">
            <div>
              <h1 className="text-white text-uppercase p-0 m-0 d-flex BackgroundSlideshow_homepage_h1_1">
              Leonardo da Vinci School of
              </h1>
              <div className="BackgroundSlideshow_h1_div">
                <h1 className="text-white text-uppercase p-0 m-0 BackgroundSlideshow_homepage_h1_2">
                Arts & Culture
                </h1>
                {/* <p className="text-white BackgroundSlideshow_homepage_TM">TM</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container mt-5 p-3 p-lg-5 mb-5"
        style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
      >
        <div className="row">
          <div className="col-12">
            <h1 className="BackgroundSlideshow_homepage_h1 text-uppercase m-0 p-0">
              Leonardo da Vinci School of Arts & Culture
            </h1>

            <p className="feature_homepage_p pt-4">
                <img
                src={IMG}
                alt="img"
                className="rounded-3"
                style={{
                  float: "right",
                  marginLeft: "15px",
                  width: "500px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
              The Leonardo da Vinci School of Arts & Cultural at the Institute
              of Billionaires is designed to teach future billionaires the power
              of art and culture in shaping global influence. Named after the
              legendary artist and thinker, Leonardo da Vinci, the school
              emphasizes the importance of understanding and mastering fine
              arts, cultural heritage, and art patronage as tools for building
              wealth, prestige, and legacy.
              <br />
              <br />
              Billionaires are often influential patrons of the arts, using
              their collections and cultural investments to make a lasting
              impact on society. At this school, students will learn the art of
              collecting, curating, and preserving important pieces of art and
              cultural history. By understanding the deeper value behind fine
              arts and how to navigate elite cultural circles, graduates will be
              equipped to leverage art as a means to strengthen international
              relations, expand their global networks, and leave a cultural
              legacy that transcends generations.
              <br />
              <br />
              The curriculum is designed to provide a deep understanding of the
              art world, from museum curation to art collection and cultural
              diplomacy. Through direct engagement with leading experts in the
              fine arts, students will acquire the knowledge and skills to
              become key players in the global cultural arena.
              <br />
              <br />
              Graduates of the Leonardo da Vinci School of Arts & Culture will
              not only appreciate the world of art, but they will also master
              the ability to use it strategically for global influence,
              networking, and legacy building.
            </p>

            <hr className="mt-3 mb-3" />
            <h1 className="BackgroundSlideshow_homepage_h1 m-0 p-0">FAQs</h1>
            <div className="col-12 mt-4">
              <Accordion items={accordionData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeonardoDa;
